import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Card } from '../../components/Card';
import { Logo } from '../../components/Logo';
import { PasswordResetForm } from '../../components/PasswordResetForm';
import { FullPageBackgroundLayout } from '../../layouts/FullPageBackgroundLayout';
import '../AccountLoginPage/AccountLoginPage.scss';

interface RouterParams {
  resetToken: string;
}

interface Props extends RouteComponentProps<RouterParams> {}

export const ResetPasswordPage: React.FunctionComponent<Props> = (props) => (
  <FullPageBackgroundLayout
    backgroundImage={require('../../assets-shared/desk-1140699-1600x960.jpg').default}
    className="account-login-page"
  >
    <Card className="auth-card">
      <div className="auth-card__header">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="auth-card__body">
        <PasswordResetForm resetToken={props.match.params.resetToken} />
      </div>
    </Card>
  </FullPageBackgroundLayout>
);
