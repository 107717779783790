import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { FullPageBackgroundLayout } from '../../layouts/FullPageBackgroundLayout';
import { TestResultStore } from '../../stores/TestResultStore';
import './AgendaPage.scss';

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {
  testResultStore: TestResultStore;
}

class AgendaPagePresenter extends React.Component<Props> {
  componentDidMount() {
    when(
      () =>
        this.props.location.pathname === '/welcome' && this.props.testResultStore.lastCompleteTest.get() !== undefined,
      () => {
        const test = this.props.testResultStore.lastCompleteTest.get();
        this.props.history.push(`/development-guides/${test!.id}`);
      }
    );
  }

  render() {
    const navigateToTestPage = () => this.props.history.push('/tests/role-selection');
    return (
      <FullPageBackgroundLayout
        backgroundImage={require('../../assets-shared/desk-1140699-blurred-1600x960.jpg').default}
        className="agenda-page"
      >
        <Card className="agenda-card">
          <h1 className="agenda__title">Self-assessment test</h1>
          <div className="agenda__desc">During the self-assessment you will be guided through the following steps:</div>
          <div className="agenda">
            <div className="agenda__item">
              <div className="agenda__bullet">1</div>
              <div className="agenda__itemTitle">Role cluster selection</div>
              <div className="agenda__itemDesc">First you will select your current role.</div>
            </div>
            <div className="agenda__item">
              <div className="agenda__bullet">2</div>
              <div className="agenda__itemTitle">Discovering the key competencies to focus on</div>
              <div className="agenda__itemDesc">Learn and understand the key competencies related to your role.</div>
            </div>
            <div className="agenda__item">
              <div className="agenda__bullet">3</div>
              <div className="agenda__itemTitle">Assess yourself</div>
              <div className="agenda__itemDesc">
                You will assess your current skills within each of the relevant competencies.
              </div>
            </div>
            <div className="agenda__item">
              <div className="agenda__bullet">4</div>
              <div className="agenda__itemTitle">Get a personalized training package proposal</div>
              <div className="agenda__itemDesc">All you need to focus on to grow and thrive.</div>
            </div>
          </div>
          <Button size="lg" variant="primary" className="block" onClick={navigateToTestPage}>
            CONTINUE
          </Button>
        </Card>
      </FullPageBackgroundLayout>
    );
  }
}

export const AgendaPage = inject('testResultStore')(observer(AgendaPagePresenter));
