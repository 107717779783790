import { Provider } from 'mobx-react';
import * as React from 'react';
import { AppRouter } from './AppRouter';
import { AppGateway } from './components/AppGateway';
import * as stores from './stores';

interface Props {}

export const App: React.FunctionComponent<Props> = () => {
  return (
    <Provider {...stores}>
      <AppGateway>
        <AppRouter />
      </AppGateway>
    </Provider>
  );
};
