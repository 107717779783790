import { computed, observable } from 'mobx';
import {
  BehavioralRoleCompetencyID,
  BEHAVIORAL_COMPETENCIES_LIST,
  FunctionalRoleCompetencyID,
  FUNCTIONAL_COMPETENCIES_LIST,
  RoleCompetency
} from '../const/competencies';
import { RemoteTestInstance } from '../services/TestResultsService';

interface Template {
  functional: RoleCompetency<FunctionalRoleCompetencyID>[];
  behavioral: RoleCompetency<BehavioralRoleCompetencyID>[];
}

export class TestTemplateStore {
  @observable
  getTemplate(testResult: RemoteTestInstance) {
    return computed(() => {
      return {
        functional: testResult.competencies.functional.map((id) =>
          FUNCTIONAL_COMPETENCIES_LIST.find((c) => c.id === id)
        ) as RoleCompetency<FunctionalRoleCompetencyID>[],
        behavioral: testResult.competencies.behavioral.map((id) =>
          BEHAVIORAL_COMPETENCIES_LIST.find((c) => c.id === id)
        ) as RoleCompetency<BehavioralRoleCompetencyID>[]
      } as Template;
    });
  }

  @observable
  shouldOpenExitDialog = false;

  @observable
  redirectToTest?: string;

  setSholdOpenExitDialog(should: boolean) {
    this.shouldOpenExitDialog = should;
  }
  setRedirectToTest(test?: string) {
    this.redirectToTest = test;
  }
}
