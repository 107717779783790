import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SectionHeader } from '../../components/SectionHeader';
import './TutorialsPage.scss';
import { TUTORIALS_LIST } from '../../const/tutorials';
import { TabLayout } from '../../layouts/TabLayout';

interface Props extends RouteComponentProps<{}> {}

export const TutorialsPage: React.FunctionComponent<Props> = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  return (
    <TabLayout selectedIndex="4" isDropdownVisible={isDropdownVisible} callback={setIsDropdownVisible}>
      <SectionHeader
        title={'Videos & tutorials'}
        history={props.history}
        location={props.location}
        match={props.match}
      />
      <div className="tutorial">
        {TUTORIALS_LIST.map((tutorial, index) => (
          <div className="tutorial__video">
            <iframe
              className="tutorial__video__iframe"
              src={tutorial.redirect}
              allow="autoplay; encrypted-media; fullscreen"
              title="video"
            />
          </div>
        ))}
      </div>
    </TabLayout>
  );
};
