import { AttemptLoginResponse, GetAccountResponse } from './AccountService.types';
import { Http, HttpResponse } from './HttpService';

export interface Credentials {
  username: string;
  password: string;
}

export interface RegistrationCredentials {
  first_name: string;
  last_name: string;
  email: string;
}

export interface ResetPasswordCredentials {
  password: string;
  resetToken: string;
}

export const AccountService = {
  loginWithCredentials: (credentials: Credentials) =>
    Http.post('/api/v1/oauth/token', {
      ...credentials,
      grant_type: 'password',
      client_id: 'Q463MPSLOQX7SSXCKF6P',
      client_secret: 'asASDA12d1QDD21D1dsa'
    }) as HttpResponse<AttemptLoginResponse>,

  logout: () => Http.post('/api/v1/oauth/revoke', {}),

  requestRegistrationAccess: (credentials: RegistrationCredentials) =>
    Http.post('/api/v1/user_activate', credentials) as HttpResponse<AttemptLoginResponse, { error: string }>,

  requestNewPassword: (email: string) => Http.post('/api/v1/reset_password', { email }) as HttpResponse<{}>,

  getAccount: () => Http.get('/api/v1/user') as HttpResponse<GetAccountResponse>
};
