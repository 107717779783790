import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { VersionService } from '../services/VersionService';

export class AppStore {
  @observable
  ready: boolean = false;

  @persist
  @observable
  version?: string;

  @action
  setReady() {
    this.ready = true;
  }

  checkVersion = async () => {
    const response = await VersionService.check();
    runInAction(() => {
      if (response.ok) {
        if (this.version !== response.data) {
          this.version = response.data;
          window.location.reload(true);
        }

        this.version = response.data;
      }
    });
  };
}
