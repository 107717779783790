import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import { DevelopmentGuidePage } from './pages/DevelopmentGuidePage';
import { AccountRouter } from './routers/AccountRouter';
import { browserHistory } from './routers/browserHistory';
import { TestsRouter } from './routers/TestsRouter';
import { SessionStore } from './stores/SessionStore';
import { TutorialsPage } from './pages/TutorialsPage';
import { WhatsNewPage } from './pages/WhatsNewPage';
import { EmployeesPage } from './pages/EmployeesPage';
import { EmployeeTestsPage } from './pages/EmployeeTestsPage';
import { FutureSkillsPage } from './pages/FutureSkillsPage';

interface Props {
  sessionStore?: SessionStore;
}

browserHistory.listen((location, action) => {
  window.scrollTo(0, 0);
});

export const AppRouter: React.FunctionComponent<Props> = (props) => {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route path="/account" component={AccountRouter} />
        <Route path="/tests" component={TestsRouter} />
        <Route path="/whatsnew" component={WhatsNewPage} />
        <Route path="/future" component={FutureSkillsPage} />
        <Route path="/development-guides/:testId/employee/:employeeId" component={DevelopmentGuidePage} />
        <Route path="/development-guides/history/:testId/" component={DevelopmentGuidePage} />
        <Route path="/development-guides/:testId" component={DevelopmentGuidePage} />
        <Route path="/tutorials" component={TutorialsPage} />
        <Route path="/employees" component={EmployeesPage} />
        <Route exact path="/employee/:id" component={EmployeeTestsPage} />

        <Redirect exact from="/welcome" to="/tests/welcome" />
        <Redirect exact from="/" to="/account/login" />
      </Switch>
    </Router>
  );
};
