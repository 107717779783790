import React from 'react';
import './HalfPageBackgroundLayout.scss';

type P = {
  backgroundImage: string;
};

export const HalfPageBackgroundLayout: React.FunctionComponent<P> = (props) => {
  return (
    <div className="login-page-layout">
      <div className="login-page-layout__container">
        <div className="login-page-layout__container__content">{props.children}</div>
      </div>
      <div
        className="login-page-layout__image"
        style={{
          background: 'url(' + props.backgroundImage + ') no-repeat',
          backgroundSize: 'cover'
        }}
      ></div>
    </div>
  );
};
