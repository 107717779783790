import { ApiResponse, create } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import { browserHistory } from '../routers/browserHistory';
import { sessionStore } from '../stores';

interface HttpErrorReponse {
  message: string;
}

export type HttpResponse<O, E = HttpErrorReponse> = Promise<ApiResponse<O, E>>;

export const Http = create({
  baseURL: process.env.REACT_APP_API_URL
});

Http.addRequestTransform((request: AxiosRequestConfig) => {
  if (sessionStore.accessToken) {
    request.headers.Authorization = `Bearer ${sessionStore.accessToken}`;
  }
});

Http.addResponseTransform((response: ApiResponse<any, any>) => {
  if (response.status === 401) {
    sessionStore.reset();
    browserHistory.replace('/account/login');
  }
});
