type ScrollOptions = Partial<{
  offset: number;
}>;

const DEFAULT_CONFIG = {
  offset: 0
};

export function scrollIntoView(ref: HTMLElement, config?: ScrollOptions) {
  if (ref) {
    const c = { ...DEFAULT_CONFIG, ...config };
    const elemPos = ref.getBoundingClientRect().top + window.pageYOffset;
    window.scroll({ top: elemPos + c.offset, left: 0, behavior: 'smooth' });
  }
}
