export type ExternalResource = {
  id: string;
  title: string;
  url: string;
  level: 'foundation' | 'intermediate' | 'advanced';
};

type ResourceMap = {
  [key: string]: ExternalResource[];
};

export const NAVIGATION_ITEMS = [
  {
    id: 'section-functional-development',
    label: 'Functional development'
  },
  {
    id: 'section-behavioral-development',
    label: 'Behavioral development'
  },
  {
    id: 'section-on-the-job-development',
    label: 'On the job development'
  },
  {
    id: 'section-knowlege-base',
    label: 'Existing knowledgebase'
  }
];
export const CATEGORY_RESOURCES: ResourceMap = {
  p01: [
    {
      id: 'p01-l0',
      title: 'Introduction to Procurement',
      url: 'https://rbi.sourcexchange.co.uk/p01/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p01-l1',
      title: 'Procurement Guiding principles',
      url: 'https://rbi.sourcexchange.co.uk/p01/02/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p01-l2',
      title: 'Procurement Cycle',
      url: 'https://rbi.sourcexchange.co.uk/p01/03/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p01-l3',
      title: 'Procurement process',
      url: 'https://rbi.sourcexchange.co.uk/p01/04/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p01-l4',
      title: 'Roles and Responsibilities of Procurement',
      url: 'https://rbi.sourcexchange.co.uk/p01/05/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p01-l5',
      title: 'Procurement Policy',
      url: 'https://rbi.sourcexchange.co.uk/p01/06/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p01-l6',
      title: 'Evaluation to Establishment',
      url: 'https://rbi.sourcexchange.co.uk/p01/07/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p01-l7',
      title: 'Introduction to outsourcing',
      url: 'https://rbi.sourcexchange.co.uk/p01/08/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p01-l8',
      title: 'Outsourcing process',
      url: 'https://rbi.sourcexchange.co.uk/p01/09/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p01-l9',
      title: 'Outsourcing rationale',
      url: 'https://rbi.sourcexchange.co.uk/p01/10/story_html5.html',
      level: 'advanced'
    }
  ],
  p02: [
    {
      id: 'p02-l0',
      title: 'Defining and Managing the business need',
      url: 'https://rbi.sourcexchange.co.uk/p02/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p02-l1',
      title: 'Supply Positioning',
      url: 'https://rbi.sourcexchange.co.uk/p02/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p02-l2',
      title: 'Shaping the market',
      url: 'https://rbi.sourcexchange.co.uk/p02/03/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p02-l3',
      title: 'Porters 5 Forces',
      url: 'https://rbi.sourcexchange.co.uk/p02/04/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p02-l4',
      title: 'Customer Preferencing',
      url: 'https://rbi.sourcexchange.co.uk/p02/05/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p02-l5',
      title: 'Monopolies and Cartels',
      url: 'https://rbi.sourcexchange.co.uk/p02/06/story_html5.html',
      level: 'advanced'
    }
  ],
  p03: [
    {
      id: 'p03-l0',
      title: 'Introduction to Risk Management',
      url: 'https://rbi.sourcexchange.co.uk/p03/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p03-l1',
      title: 'Vulnerability',
      url: 'https://rbi.sourcexchange.co.uk/p03/02/story_html5.html',
      level: 'intermediate'
    }
  ],
  p04: [
    {
      id: 'p04-l0',
      title: 'Business Needs and Specifications',
      url: 'https://rbi.sourcexchange.co.uk/p04/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p04-l1',
      title: 'Developing strategies',
      url: 'https://rbi.sourcexchange.co.uk/p04/02/story.html',
      level: 'intermediate'
    },
    {
      id: 'p04-l3',
      title: 'Contract strategy',
      url: 'https://rbi.sourcexchange.co.uk/p04/05/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p04-l4',
      title: 'Procurement marketing',
      url: 'https://rbi.sourcexchange.co.uk/p04/06/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p04-l5',
      title: 'Market matrix',
      url: 'https://rbi.sourcexchange.co.uk/p04/07/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p04-l6',
      title: 'Integrating techniques',
      url: 'https://rbi.sourcexchange.co.uk/p04/08/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p04-l7',
      title: 'Reverse marketing',
      url: 'https://rbi.sourcexchange.co.uk/p04/09/story_html5.html',
      level: 'advanced'
    }
  ],
  p05: [
    {
      id: 'p05-l0',
      title: 'Basics of Supplier Appraisal',
      url: 'https://rbi.sourcexchange.co.uk/p05/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p05-l1',
      title: 'Supplier Appraisal',
      url: 'https://rbi.sourcexchange.co.uk/p05/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p05-l2',
      title: 'Supplier Selection',
      url: 'https://rbi.sourcexchange.co.uk/p05/03/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p05-l3',
      title: 'Contract Award',
      url: 'https://rbi.sourcexchange.co.uk/p05/04/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p05-l4',
      title: 'Evaluation Panel Member Responsibilities',
      url: 'https://rbi.sourcexchange.co.uk/p05/05/story_html5.html',
      level: 'intermediate'
    }
  ],
  p06: [
    {
      id: 'p06-l0',
      title: 'Introduction to Contract Law',
      url: 'https://rbi.sourcexchange.co.uk/p06/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p06-l1',
      title: 'Overview of English Law',
      url: 'https://rbi.sourcexchange.co.uk/p06/02/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p06-l2',
      title: 'Contract and contract terms',
      url: 'https://rbi.sourcexchange.co.uk/p06/03/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p06-l3',
      title: 'Offer and Acceptance',
      url: 'https://rbi.sourcexchange.co.uk/p06/04/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p06-l4',
      title: 'Consideration',
      url: 'https://rbi.sourcexchange.co.uk/p06/05/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p06-l5',
      title: 'Intention, Capacity and Form',
      url: 'https://rbi.sourcexchange.co.uk/p06/06/story_html5.html',
      level: 'intermediate'
    }
  ],
  p07: [
    {
      id: 'p07-l0',
      title: 'Basics of Company Statements',
      url: 'https://rbi.sourcexchange.co.uk/p07/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p07-l1',
      title: 'Finance Reports',
      url: 'https://rbi.sourcexchange.co.uk/p07/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p07-l2',
      title: 'Pricing Policy',
      url: 'https://rbi.sourcexchange.co.uk/p07/03/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p07-l3',
      title: 'Finance Ratios',
      url: 'https://rbi.sourcexchange.co.uk/p07/04/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p07-l4',
      title: 'Costing Methods',
      url: 'https://rbi.sourcexchange.co.uk/p07/05/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p07-l5',
      title: 'Should Cost Modelling',
      url: 'https://rbi.sourcexchange.co.uk/p07/06/story_html5.html',
      level: 'advanced'
    }
  ],
  p08: [
    {
      id: 'p08-l0',
      title: 'People and Behaviour',
      url: 'https://rbi.sourcexchange.co.uk/p08/01/story.html',
      level: 'foundation'
    },
    {
      id: 'p08-l1',
      title: 'Principles of Negotiation',
      url: 'https://rbi.sourcexchange.co.uk/p08/02/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p08-l2',
      title: 'Structure of Negotiation',
      url: 'https://rbi.sourcexchange.co.uk/p08/03/story.html',
      level: 'foundation'
    },
    {
      id: 'p08-l3',
      title: 'Negotiation Tactics',
      url: 'https://rbi.sourcexchange.co.uk/p08/04/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p08-l4',
      title: 'Persuasion Methods',
      url: 'https://rbi.sourcexchange.co.uk/p08/05/story.html',
      level: 'foundation'
    },
    {
      id: 'p08-l5',
      title: 'Persuasion Profile',
      url: 'https://rbi.sourcexchange.co.uk/p08/06/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p08-l6',
      title: 'Developing and Using Power',
      url: 'https://rbi.sourcexchange.co.uk/p08/07/story.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l7',
      title: 'Communication Skills',
      url: 'https://rbi.sourcexchange.co.uk/p08/08/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l8',
      title: 'Competitive and Collaborative',
      url: 'https://rbi.sourcexchange.co.uk/p08/09/story.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l9',
      title: 'Assertiveness',
      url: 'https://rbi.sourcexchange.co.uk/p08/10/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l10',
      title: 'Culture Dimensions',
      url: 'https://rbi.sourcexchange.co.uk/p08/11/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l11',
      title: 'Preparation and Planning',
      url: 'https://rbi.sourcexchange.co.uk/p08/12/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l12',
      title: 'Dealing with Conflict and Pressure',
      url: 'https://rbi.sourcexchange.co.uk/p08/13/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l13',
      title: 'Movement Techniques',
      url: 'https://rbi.sourcexchange.co.uk/p08/14/story.html',
      level: 'intermediate'
    },
    {
      id: 'p08-l14',
      title: 'Tactics 2',
      url: 'https://rbi.sourcexchange.co.uk/p08/15/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p08-l15',
      title: 'Advanced Culture Dimensions',
      url: 'https://rbi.sourcexchange.co.uk/p08/16/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p08-l16',
      title: 'Game Theory',
      url: 'https://rbi.sourcexchange.co.uk/p08/17/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p08-l17',
      title: 'Understanding Yourself',
      url: 'https://rbi.sourcexchange.co.uk/p08/18/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p08-l18',
      title: 'Motivational Theory',
      url: 'https://rbi.sourcexchange.co.uk/p08/19/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p08-l19',
      title: 'Tactics 3',
      url: 'https://rbi.sourcexchange.co.uk/p08/20/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p08-l20',
      title: 'Virtual Negotiations – Top Tricks and Tips',
      url: 'https://rbi.sourcexchange.co.uk/p08/21/story.html',
      level: 'advanced'
    }
  ],
  p09: [
    {
      id: 'p09-l0',
      title: 'Contract Management Essentials – Part 1',
      url: 'https://rbi.sourcexchange.co.uk/p09/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p09-l1',
      title: 'Contract Management Essentials – Part 2',
      url: 'https://rbi.sourcexchange.co.uk/p09/02/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p09-l2',
      title: 'Supplier Relationship Management Governance',
      url: 'https://rbi.sourcexchange.co.uk/p09/03/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p09-l3',
      title: 'Managing Supplier Performance',
      url: 'https://rbi.sourcexchange.co.uk/p09/04/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p09-l4',
      title: 'Supplier Development',
      url: 'https://rbi.sourcexchange.co.uk/p09/05/story_html5.html',
      level: 'advanced'
    },
    {
      id: 'p09-l5',
      title: 'Supplier Relationship Measurement',
      url: 'https://rbi.sourcexchange.co.uk/p09/06/story_html5.html',
      level: 'advanced'
    }
  ],
  p10: [
    {
      id: 'p10-l0',
      title: 'eProcurement',
      url: 'https://rbi.sourcexchange.co.uk/p10/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p10-l1',
      title: 'eAuctions',
      url: 'https://rbi.sourcexchange.co.uk/p10/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p10-l2',
      title: 'eCatalogues',
      url: 'https://rbi.sourcexchange.co.uk/p10/03/story_html5.html',
      level: 'intermediate'
    }
  ],
  p11: [
    {
      id: 'p11-l0',
      title: 'eProcurement',
      url: 'https://rbi.sourcexchange.co.uk/p10/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p11-l1',
      title: 'eAuctions',
      url: 'https://rbi.sourcexchange.co.uk/p10/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p11-l2',
      title: 'eCatalogues',
      url: 'https://rbi.sourcexchange.co.uk/p10/03/story_html5.html',
      level: 'intermediate'
    }
  ],
  p12: [
    {
      id: 'p12-l0',
      title: 'Change Management – Introduction to Change',
      url: 'https://rbi.sourcexchange.co.uk/p12/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p12-l1',
      title: 'Project Management – Introduction to Project Management',
      url: 'https://rbi.sourcexchange.co.uk/p12/04/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p12-l2',
      title: 'Change Management – Methods of Change',
      url: 'https://rbi.sourcexchange.co.uk/p12/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p12-l3',
      title: 'Change Management – Driving Change',
      url: 'https://rbi.sourcexchange.co.uk/p12/03/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p12-l4',
      title: 'Project Management – Project Planning',
      url: 'https://rbi.sourcexchange.co.uk/p12/05/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p12-l5',
      title: 'Project Management – Managing Successful Projects',
      url: 'https://rbi.sourcexchange.co.uk/p12/06/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p12-l6',
      title: 'Project Management – Phases and Methodology',
      url: 'https://rbi.sourcexchange.co.uk/p12/07/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p12-l7',
      title: 'Project Management – Managing the Stakeholder',
      url: 'https://rbi.sourcexchange.co.uk/p12/08/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p12-l8',
      title: 'Project Management – Effective Team Management',
      url: 'https://rbi.sourcexchange.co.uk/p12/09/story_html5.html',
      level: 'intermediate'
    }
  ],
  p13: [
    {
      id: 'p13-l0',
      title: 'Introduction to Sustainability in the Supply Chain',
      url: 'https://rbi.sourcexchange.co.uk/p13/01/story_html5.html',
      level: 'foundation'
    },
    {
      id: 'p13-l1',
      title: 'The Business Case for Sustainable Procurement',
      url: 'https://rbi.sourcexchange.co.uk/p13/02/story_html5.html',
      level: 'intermediate'
    },
    {
      id: 'p13-l2',
      title: 'Probity in Procurement',
      url: 'https://rbi.sourcexchange.co.uk/p13/03/story_html5.html',
      level: 'intermediate'
    }
  ]
};
