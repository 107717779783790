import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import './FutureSkillsPage.scss';
import { TabLayout } from '../../layouts/TabLayout';
import { Button } from '../../components/Button';
import { SectionHeader } from '../../components/SectionHeader';

interface Props extends RouteComponentProps<{}> {}

export const FutureSkillsPage: React.FunctionComponent<Props> = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  return (
    <TabLayout
      isDropdownVisible={isDropdownVisible}
      selectedIndex="2"
      callback={(isVisible) => setIsDropdownVisible(isVisible)}
    >
      <SectionHeader
        title="NEW – AI in Procurement"
        subtitle="Explore available e-learning trainings covering the most important skills for future-ready Procurement professionals."
        history={props.history}
        location={props.location}
        match={props.match}
      />
      <Button
        className="futureskills__button"
        type="submit"
        size="lg"
        variant="primary"
        isExternal={true}
        title={'VISIT LINK'}
        withShadow={true}
        onClick={() => {
          window.open(
            'https://rbinternational.sharepoint.com/sites/ProcINT/SitePages/AI-in-Procurement.aspx',
            '_blank'
          );
        }}
      />
    </TabLayout>
  );
};
