import React from 'react';
import './FormErrors.scss';

interface Props {
  message?: string;
}

export const FormErrors: React.FunctionComponent<Props> = (props) => {
  return <div className="form-errors">{props.message}</div>;
};
