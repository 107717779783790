import {
  FUN_P01,
  FUN_P02,
  FUN_P03,
  FUN_P04,
  FUN_P05,
  FUN_P06,
  FUN_P07,
  FUN_P08,
  FUN_P09,
  FUN_P10,
  FUN_P11,
  FUN_P12,
  BEH_SELF_MANAGEMENT,
  BEH_DELIVERING_RESULTS,
  BEH_BUILDING_RELATIONSHIPS,
  BEH_PERSONAL_IMPACT_AND_PRESENTATION,
  BEH_ENTREPRENEURIAL_INITTATIVE,
  BEH_LEADERSHIP,
  BEH_DEVELOPING_OTHERS,
  FunctionalRoleCompetencyID,
  BehavioralRoleCompetencyID
} from './competencies';

export interface RoleCompetencySelection {
  functional: FunctionalRoleCompetencyID[];
  behavioral: BehavioralRoleCompetencyID[];
}

const MANAGER_COMPETENCIES: RoleCompetencySelection = {
  functional: [FUN_P04.id, FUN_P09.id, FUN_P02.id, FUN_P03.id, FUN_P07.id],
  behavioral: [
    BEH_SELF_MANAGEMENT.id,
    BEH_DELIVERING_RESULTS.id,
    BEH_BUILDING_RELATIONSHIPS.id,
    BEH_PERSONAL_IMPACT_AND_PRESENTATION.id,
    BEH_ENTREPRENEURIAL_INITTATIVE.id
  ]
};

const SOURCER_COMPETENCIES: RoleCompetencySelection = {
  functional: [FUN_P08.id, FUN_P06.id, FUN_P02.id, FUN_P04.id, FUN_P07.id],
  behavioral: [
    BEH_SELF_MANAGEMENT.id,
    BEH_DELIVERING_RESULTS.id,
    BEH_BUILDING_RELATIONSHIPS.id,
    BEH_PERSONAL_IMPACT_AND_PRESENTATION.id,
    BEH_ENTREPRENEURIAL_INITTATIVE.id
  ]
};

const BUYER_COMPETENCIES: RoleCompetencySelection = {
  functional: [FUN_P10.id, FUN_P06.id, FUN_P11.id],
  behavioral: [BEH_SELF_MANAGEMENT.id, BEH_DELIVERING_RESULTS.id, BEH_BUILDING_RELATIONSHIPS.id]
};

const PROCUREMENT_TECH_AND_PROCESSES_COMPETENCIES: RoleCompetencySelection = {
  functional: [FUN_P05.id, FUN_P12.id, FUN_P11.id, FUN_P09.id],
  behavioral: [BEH_SELF_MANAGEMENT.id, BEH_DELIVERING_RESULTS.id, BEH_PERSONAL_IMPACT_AND_PRESENTATION.id]
};

const LEADERS_COMPETENCIES: RoleCompetencySelection = {
  functional: [FUN_P01.id, FUN_P12.id, FUN_P03.id, FUN_P08.id],
  behavioral: [BEH_LEADERSHIP.id, BEH_DELIVERING_RESULTS.id, BEH_DEVELOPING_OTHERS.id]
};

const TAILOR_MADE_COMPETENCIES: RoleCompetencySelection = {
  functional: [],
  behavioral: []
};

export const DEFAULT_COMPETENCY_SELECTION = {
  manager: MANAGER_COMPETENCIES,
  sourcer: SOURCER_COMPETENCIES,
  buyer: BUYER_COMPETENCIES,
  'tech-processes': PROCUREMENT_TECH_AND_PROCESSES_COMPETENCIES,
  leaders: LEADERS_COMPETENCIES,
  'tailor-made': TAILOR_MADE_COMPETENCIES
};

export const TEST_RESULTS = {
  FOUNDATION: 'Foundation',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced'
};

export const TEST_PARTS = [
  'Select role',
  'Functional competencies',
  'Behavioral competencies',
  'Personalized training package proposal'
];
