import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SectionHeader } from '../../components/SectionHeader';
import './CompetencyDetailPage.scss';
import { COMPETENCIES_WITH_MODULES_LIST } from '../../const/competenciesWithLinks';
import { TabLayout } from '../../layouts/TabLayout';

interface Props
  extends RouteComponentProps<{
    index: string;
  }> {}

export const CompetencyDetailPage: React.FunctionComponent<Props> = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  return (
    <TabLayout selectedIndex="1" isDropdownVisible={isDropdownVisible} callback={setIsDropdownVisible}>
      <SectionHeader
        annotationTitle={COMPETENCIES_WITH_MODULES_LIST[Number(props.match.params.index)].index}
        title={COMPETENCIES_WITH_MODULES_LIST[Number(props.match.params.index)].title}
        backButtonTitle="BACK TO COMPETENCIES"
        backButtonURL="../compets"
        history={props.history}
        location={props.location}
        match={props.match}
      />
      <div className="detail">
        {COMPETENCIES_WITH_MODULES_LIST[Number(props.match.params.index)].modules.map((test, index) => (
          <div key={index} className="detail__test" onClick={navigateFromElement(test.redirect)}>
            <div className="detail__test__title">{test.title}</div>
            <div className="detail__test__startTraining">START TRAINING</div>
          </div>
        ))}
      </div>
    </TabLayout>
  );
};

const navigateFromElement = (url: string) => () => {
  window.open(url, '_blank');
};
