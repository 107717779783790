import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RemoteTestInstance } from '../../services/TestResultsService';

import './TestRow.scss';

interface Props extends RouteComponentProps {
  title: string;
  test: RemoteTestInstance;
  canProceedTest: boolean;
  onClick: () => void;
}

const TestRowPresenter: React.FunctionComponent<Props> = (props) => {
  return (
    <div
      className={'test-row__test' + (props.test.status !== 'completed' ? ' test-row__test-inprogress' : '')}
      onClick={() => {
        (props.test.status === 'completed' || (props.canProceedTest && props.test.status === 'active')) &&
          props.onClick();
      }}
    >
      <div className="test-row__leading">
        <div className="test-row__circle">
          <img
            className="test-row__icon"
            src={require('../../assets-shared/' + props.title.replace(/\s/g, '') + '.png').default}
            alt="RBI"
          />
        </div>
        <p className="test-row__testTitle">{props.title}</p>
      </div>

      <span className="test-row__date">
        <img
          className="test-row__calendar"
          src={require('./../../../src/assets-shared/calendar.png').default}
          alt="RBI"
        />
        {props.test.date}
      </span>

      <div className="test-row__trailing">
        <div className="test-row__status-container">
          <span
            className={
              'test-row__status test-row__status-' + (props.test.status === 'completed' ? 'completed' : 'inprogress')
            }
          >
            <img
              className={'employee__' + (props.test.status === 'completed' ? 'completed' : 'inprogress')}
              src={
                require(props.test.status === 'completed'
                  ? './../../../src/assets-shared/completed.png'
                  : './../../../src/assets-shared/inProgress.png').default
              }
              alt="RBI"
            />
            {props.test.status === 'completed' ? 'Finished' : 'In progress'}
          </span>
        </div>

        {props.test.status === 'completed' ? (
          <div className="test-row__disclosure">SEE RESULT</div>
        ) : props.canProceedTest ? (
          <div className="test-row__disclosure test-row__disclosure-continue">CONTINUE</div>
        ) : (
          <div className="test-row__spacer" />
        )}

        {/* not used for now, maybe in future?
{this.getScoreForTest(res.test.id)} */}
      </div>
    </div>
  );
};

export const TestRow = withRouter(TestRowPresenter);
