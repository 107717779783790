import { Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SessionStore } from '../../stores/SessionStore';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { FormControl } from '../FormControl';
import { FormErrors } from '../FormErrors';
import { TextField } from '../FormField';
import { Label } from '../Label';

interface Props {
  sessionStore?: SessionStore;
  resetToken: string;
}

const INITIAL_VALUES = {
  password: ''
};

@inject('sessionStore')
@observer
export class PasswordResetForm extends React.Component<Props> {
  render() {
    return (
      <div>
        <h1 className="text-center mb-3">Welcome</h1>
        <div>
          Your personalized training package will help you to build and bring the most out of your Procurement career in
          RBI.
        </div>
        <div className="mt-10 mb-4 flex justify-center">
          <Avatar size={100} />
        </div>
        <div>
          <Formik
            initialValues={{
              ...INITIAL_VALUES,
              resetToken: this.props.resetToken
            }}
            onSubmit={this.props.sessionStore!.changePassword}
          >
            {(formikProps) => {
              const errors = this.props.sessionStore!.passwordResetError;

              return (
                <Form>
                  <FormControl
                    label={<Label>New password</Label>}
                    input={<TextField name="password" placeholder="New password" autoFocus />}
                  />
                  {errors && (
                    <div className="mb-5">
                      <FormErrors message={errors} />
                    </div>
                  )}
                  <Button
                    type="submit"
                    size="lg"
                    className="block"
                    variant="primary"
                    disabled={formikProps.isSubmitting}
                  >
                    {formikProps.isSubmitting ? 'Continue...' : 'Continue'}
                  </Button>
                  <div className="text-grey text-sm mt-5">
                    Already have an account? <Link to="/account/login">Login here</Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}
