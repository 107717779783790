import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { RegistrationForm } from '../../components/RegistrationForm';
import { LandingHeader } from '../../components/LandingHeader';
import { HalfPageBackgroundLayout } from '../../layouts/HalfPageBackgroundLayout';
import { SessionStore } from '../../stores/SessionStore';
import { inject, observer } from 'mobx-react';

interface Props extends RouteComponentProps<{}> {
  sessionStore: SessionStore;
}

const AccountRegistrationPagePresenter: React.FunctionComponent<Props> = (props) => (
  <HalfPageBackgroundLayout backgroundImage={require('../../assets-shared/login_background.png').default}>
    <LandingHeader
      title="Create your account"
      subtitle="Welcome in RBI Procurement Staff Development Guide"
      desc="Your personalized training package will help you to build and bring the most out of your Procurement career in RBI."
    />
    <div className="auth-card__body">
      {props.sessionStore.registrationFormError === 'success' ? (
        <div>
          We have received your request. Please wait, we will be in touch. If you have other questions, contact{' '}
          <a href="mailto:contact@rbiprocurement.academy">contact@rbiprocurement.academy</a>.
        </div>
      ) : (
        <RegistrationForm
          onSuccess={() => {
            props.history.push('/welcome');
          }}
        />
      )}
    </div>
  </HalfPageBackgroundLayout>
);

const AccountRegistrationPageObserver = observer(AccountRegistrationPagePresenter);
export const AccountRegistrationPage = inject('sessionStore')(AccountRegistrationPageObserver);
