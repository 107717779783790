import { ApiResponse } from 'apisauce';
import { Http } from './HttpService';
import { RemoteTestInstance } from './TestResultsService';

export interface ChildrenInstance {
  username: string;
  first_name: string;
  last_name: string;
  id: number;
  data: RemoteTestInstance[];
}

type CollectionInstanceResponse = Promise<ApiResponse<ChildrenInstance[]>>;

export const ChildrenService = {
  get: () => Http.get('/api/v1/children') as CollectionInstanceResponse
};
