import format from 'date-fns/format';
import { action, computed, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { RemoteTestInstance } from '../services/TestResultsService';
import { ChildrenInstance, ChildrenService } from '../services/ChildrenService';
import { readableRole } from '../utils/readableRole';

export interface EmployeeTest {
  title: string;
  test: RemoteTestInstance;
}

export class ManagerStore {
  @persist('map')
  @observable
  _data: Map<string, ChildrenInstance> = new Map();
  @observable
  userTests: Map<string, RemoteTestInstance[]> = new Map();

  isManager = computed(() => {
    return this._data.size > 0;
  });

  getLastTestPropsFor = (id: number) => {
    const tests = this.userTests.get(String(id)) || [];
    if (tests.length >= 1) {
      let mostRecentTest = tests[0];
      return {
        status: mostRecentTest.status,
        date: format(
          Date.parse(mostRecentTest.date) || new Date(mostRecentTest.date.replace(/-/g, '/')),
          'MMM dd, yyyy HH:mm'
        )
      };
    }
    return {};
  };

  getTitleForTestWith = (testId: string, employeeId: string) => {
    const test = this.findTestFromEmployeeWith(employeeId, testId);
    return test ? readableRole(test.role) : '';
  };
  getFormattedDateForTest = (test: RemoteTestInstance) => {
    return format(Date.parse(test.date) || new Date(test.date.replace(/-/g, '/')), 'MMM dd, yyyy HH:mm');
  };

  getEmployee = (id: string) => {
    let employeeData = this._data.get(id);
    if (employeeData) {
      const completedTests = Array.from(this.userTests.get(id) || []).map((test) => ({
        title: `${readableRole(test.role)}`,
        test: test
      }));

      return {
        name: employeeData.first_name + ' ' + employeeData.last_name,
        tests: completedTests
      };
    }
    return null;
  };

  findTestFromEmployeeWith = (id: string, testId: string) => {
    let tests = this.userTests.get(String(id));
    if (!tests) return undefined;
    let searchedTest = tests.find(function (element) {
      return element.id === testId;
    });
    return searchedTest;
  };

  allEmployees = computed(() => {
    return Array.from(this._data.values()).map((employee) => ({
      id: employee.id,
      name: employee.first_name + ' ' + employee.last_name,
      isUserTested: employee.data.length > 0,
      lastTest: this.getLastTestPropsFor(employee.id)
    }));
  });

  @action
  reset = () => {
    this._data.clear();
    this.userTests.clear();
  };

  @action
  fetchResults = async () => {
    const response = await ChildrenService.get();
    runInAction(() => {
      this.reset();
      if (response.ok) {
        response.data.forEach((element) => {
          this._data.set(String(element.id), element);
          var tests: Array<RemoteTestInstance> = [];
          element.data.forEach((test) => {
            tests.push(JSON.parse(String(test)));
          });
          tests = tests.sort((a, b) => Number(a.id) + Number(b.id));
          this.userTests.set(String(element.id), tests);
        });
      }
    });
  };
}
