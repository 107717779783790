import React from 'react';
import './CheckList.scss';
import { CheckBox } from '../Checkbox';

interface ChecklistProps<T> {
  selectedItems: T[];
  items: {
    id: T;
    value: string;
  }[];
  onChange: (items: T[]) => void;
  isSelectionDisabled: Boolean;
}

export class CheckList<T> extends React.Component<ChecklistProps<T>> {
  render() {
    function toggleItem(items: T[], id: T): T[] {
      return items.includes(id) ? items.filter((_id) => _id !== id) : items.concat(id);
    }
    return (
      <div className="checklist">
        {this.props.items.map((item) => {
          const checked = this.props.selectedItems.includes(item.id);
          return (
            <div
              key={item.id as any}
              className={
                'checklist__item ' + (this.props.isSelectionDisabled && !checked && 'checklist__item-deactivated')
              }
              onClick={() =>
                (!this.props.isSelectionDisabled || (this.props.isSelectionDisabled && checked)) &&
                this.props.onChange(toggleItem(this.props.selectedItems, item.id))
              }
            >
              <CheckBox checked={checked} />
              {item.value}
            </div>
          );
        })}
      </div>
    );
  }
}
