import { Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SessionStore } from '../../stores/SessionStore';
import { Button } from '../Button';
import { FormControl } from '../FormControl';
import { FormErrors } from '../FormErrors';
import { TextField } from '../FormField';
import { Label } from '../Label';
import { LandingFooter } from '../LandingFooter';

import './PasswordRequestForm.scss';

interface Props extends RouteComponentProps {
  sessionStore?: SessionStore;
}

const INITIAL_VALUES = {
  email: ''
};

@inject('sessionStore')
@observer
class PasswordRequestFormPresenter extends React.Component<Props> {
  render() {
    const { requestNewPasswordStatus } = this.props.sessionStore!;
    return (
      <div className="password-request-form">
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            if (requestNewPasswordStatus === 'success') {
              this.props.sessionStore?.resetNewPasswordStatus();
              this.props.history.push('/account/login');
            } else {
              this.props.sessionStore!.requestNewPassword(values.email);
            }
          }}
        >
          {(formikProps) => (
            <Form>
              {!requestNewPasswordStatus && (
                <FormControl label={<Label>Email</Label>} input={<TextField name="email" placeholder="" autoFocus />} />
              )}
              {requestNewPasswordStatus === 'error' && (
                <div className="mb-5">
                  <FormErrors message="Invalid credentials given." />
                </div>
              )}
              <Button
                type="submit"
                size="lg"
                variant="primary"
                disabled={formikProps.isSubmitting && !requestNewPasswordStatus}
              >
                {requestNewPasswordStatus === 'success' && (
                  <img
                    className="password-request-form__icon-flipped"
                    src={require('./../../assets-shared/arrow-right-white.png').default}
                    alt="RBI"
                  />
                )}
                <span className="password-request-form__submit-text">
                  {formikProps.isSubmitting && !requestNewPasswordStatus
                    ? 'SEND INSTRUCTIONS...'
                    : requestNewPasswordStatus === 'success'
                    ? 'BACK TO LOGIN'
                    : 'SEND INSTRUCTIONS'}
                </span>
                {requestNewPasswordStatus !== 'success' && (
                  <img
                    className="password-request-form__icon"
                    src={require('./../../assets-shared/arrow-right-white.png').default}
                    alt="RBI"
                  />
                )}
              </Button>
              <LandingFooter />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export const PasswordRequestForm = withRouter(PasswordRequestFormPresenter);
