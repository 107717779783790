import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { Logo } from '../Logo';
import { inject, observer } from 'mobx-react';
import { SessionStore } from '../../stores/SessionStore';
import { ManagerStore } from '../../stores/ManagerStore';
import { getWelcomeMenuList } from '../../const/welcomeRows';
import { WelcomeMenuRow } from '../../const/welcomeRows';
import './SideBar.scss';

interface Props extends RouteComponentProps {
  managerStore?: ManagerStore;
  sessionStore?: SessionStore;
  selectedIndex: string;
  isDropdownVisible: boolean;
  callback: (isVisible: boolean) => void;
}

const SideBarPresenter: React.FunctionComponent<Props> = inject(
  'managerStore',
  'sessionStore'
)(
  observer((props) => {
    props.managerStore?.fetchResults();
    const MENU_LIST = getWelcomeMenuList(props.managerStore!.isManager.get());
    const tab = (isSelected: boolean, row: WelcomeMenuRow) => {
      return (
        <div
          className="sidebar__link"
          onClick={() => {
            row.external ? window.open(row.redirect, '_blank') : props.history.push(row.redirect);
          }}
        >
          <div className={'sidebar__tab sidebar__' + (isSelected ? 'selected' : 'unselected')}>
            <div className="sidebar__tab__img-holder">
              <img
                className="sidebar__tab__img"
                src={require('./assets/' + (isSelected ? 'white_' : '') + row.img).default}
                alt="RBI"
              />
            </div>
            <div className="sidebar__tab__title-container">
              <p className="sidebar__tab__title">{row.title}</p>
              {row.external && (
                <img className="sidebar__tab__img-external" src={require('./assets/redirect.png').default} alt="RBI" />
              )}
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div className={'sidebar ' + (!props.isDropdownVisible ? 'sidebar__hidden' : '')}>
          <div>
            <div className="sidebar__header">
              <Link to="/">
                <Logo className="sidebar__logo" />
              </Link>
              <button
                onClick={() => {
                  props.callback(!props.isDropdownVisible);
                }}
                className={
                  'sidebar__header__dropdown-button sidebar__header__' + (props.isDropdownVisible ? 'close' : 'burger')
                }
              >
                <img
                  src={require('./assets/' + (props.isDropdownVisible ? 'close.png' : 'burger.png')).default}
                  alt="RBI"
                />
              </button>
            </div>

            <div className={'sidebar__menu sidebar__menu__' + (props.isDropdownVisible ? 'open' : 'closed')}>
              {MENU_LIST.map((row, index) => tab(index.toString() === props.selectedIndex, row))}
            </div>
          </div>
          <div>
            <button
              className={'sidebar__signout sidebar__signout__' + (props.isDropdownVisible ? 'open' : 'closed')}
              onClick={() => {
                props.sessionStore?.logout();
              }}
            >
              <p className="sidebar__signout__text">SIGN OUT</p>
            </button>
          </div>
        </div>
      </>
    );
  })
);

export const SideBar = withRouter(SideBarPresenter);
