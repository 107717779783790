import React from 'react';
import './EmptyMessage.scss';

interface EmptyMessageProps {
  message: string;
}

export class EmptyMessage extends React.Component<EmptyMessageProps> {
  render() {
    return <p className="emptyMessage">{this.props.message}</p>;
  }
}
