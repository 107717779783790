import { Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SessionStore } from '../../stores/SessionStore';
import { Button } from '../Button';
import { FormControl } from '../FormControl';
import { FormErrors } from '../FormErrors';
import { TextField } from '../FormField';
import { Label } from '../Label';
import { LandingFooter } from '../LandingFooter';

import './RegistrationForm.scss';

interface Props {
  sessionStore?: SessionStore;
  onSuccess: () => void;
}

const INITIAL_VALUES = {
  first_name: '',
  last_name: '',
  email: ''
};

@inject('sessionStore')
@observer
export class RegistrationForm extends React.Component<Props> {
  render() {
    return (
      <div className="registration-form">
        <Formik initialValues={INITIAL_VALUES} onSubmit={this.props.sessionStore!.requestRegistrationAccess}>
          {(formikProps) => {
            const error = this.props.sessionStore!.registrationFormError;
            return (
              <Form>
                <div className="registration-form__name">
                  <FormControl
                    label={<Label>First Name</Label>}
                    input={<TextField name="first_name" placeholder="" autoFocus />}
                  />
                  <FormControl label={<Label>Last Name</Label>} input={<TextField name="last_name" placeholder="" />} />
                </div>
                <FormControl label={<Label>E-mail</Label>} input={<TextField name="email" placeholder="" />} />
                {error && (
                  <div className="mb-5">
                    <FormErrors message={error} />
                  </div>
                )}
                <Button type="submit" size="lg" variant="primary" disabled={formikProps.isSubmitting}>
                  <span className="registration-form__submit-text">
                    {formikProps.isSubmitting ? 'CREATE ACCOUNT...' : 'CREATE ACCOUNT'}
                  </span>
                  <img
                    className="registration-form__icon"
                    src={require('./../../assets-shared/arrow-right-white.png').default}
                    alt="RBI"
                  />
                </Button>
                <div className="text-grey text-sm mt-5">
                  Already have an account? <Link to="/account/login">Log in.</Link>
                </div>
                <LandingFooter />
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
