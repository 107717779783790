import React from 'react';
import { SideBar } from '../../components/SideBar';
import './TabLayout.scss';

interface Props {
  selectedIndex: string;
  isDropdownVisible: boolean;
  callback: (isVisible: boolean) => void;
}

export const TabLayout: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="tab-layout-container">
      <SideBar
        isDropdownVisible={props.isDropdownVisible}
        callback={props.callback}
        selectedIndex={props.selectedIndex}
      />
      <div className="tab-layout">
        <>
          {props.children}
          {props.isDropdownVisible && <div className="tab-layout-overlay" />}
        </>
      </div>
    </div>
  );
};
