import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { TestCompetencyTestPage } from '../pages/TestCompetencyTestPage';
import { TestProcessingPage } from '../pages/TestProcessingPage';
import { AgendaPage } from '../pages/AgendaPage';
import { WelcomePage } from '../pages/WelcomePage';
import { RolesDetailPage } from '../pages/RolesDetailPage';
import { AuthGate } from '../components/AuthGate';
import { LastTestingPage } from '../pages/LastTestingPage';
import { CompetenciesPage } from '../pages/CompetenciesPage';
import { CompetencyDetailPage } from '../pages/CompetencyDetailPage';

interface Props extends RouteComponentProps {}

export const TestsRouter: React.FunctionComponent<Props> = (props) => (
  <>
    <AuthGate />
    <Switch>
      <Route exact path="/tests/welcome" component={WelcomePage} />
      <Route exact path="/tests/agenda" component={AgendaPage} />

      <Switch>
        <Route exact path="/tests/lastTesting" component={LastTestingPage} />
        <Route exact path="/tests/compets/" component={CompetenciesPage} />
        <Route exact path="/tests/compets/:index" component={CompetencyDetailPage} />
        <Route exact path="/tests/role-selection" component={RolesDetailPage} />
        <Redirect exact from="/tests/:testId" to="/tests/:testId/functional" />
        <Redirect
          exact
          from="/tests/:testId/:competenciesCategory(functional|behavioral)"
          to="/tests/:testId/:competenciesCategory(functional|behavioral)/step/0"
        />
        <Route
          exact
          path="/tests/:testId/:competenciesCategory(functional|behavioral)/step/:step"
          component={TestCompetencyTestPage}
        />
        <Route exact path="/tests/:testId/:processing" component={TestProcessingPage} />
      </Switch>
    </Switch>
  </>
);
