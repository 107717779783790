import { Role } from '../const/roles';

export function readableRole(role: Role) {
  switch (role) {
    case 'sourcer':
      return 'Sourcer';
    case 'buyer':
      return 'Buyer';
    case 'manager':
      return 'Category Manager';
    case 'tech-processes':
      return 'Procurement Technology and Processes';
    case 'leaders':
      return 'Leaders';
    case 'tailor-made':
      return 'Tailor made';
    default:
      return 'Unknown role';
  }
}
