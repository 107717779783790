import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import format from 'date-fns/format';
import { Card } from '../../components/Card';
import { Score } from '../../components/Score';
import { Button } from '../../components/Button';
import { NavigationItem, HorizontalNavigation } from '../../components/HorizontalNavigation';
import { DevelopmentGuideLayout } from '../../layouts/DevelopmentGuideLayout';
import { TestResultStore } from '../../stores/TestResultStore';
import { scrollIntoView } from '../../utils/scrollIntoView';
import { CATEGORY_RESOURCES, NAVIGATION_ITEMS } from './DevelopmentGiudePage.const';
import './DevelopmentGuidePage.scss';
import { TestResultsService } from '../../services/TestResultsService';
import { FunctionalTestResult } from '../../stores/TestResultStore';
import { TestResult } from '../../stores/TestResultStore';
import { AuthGate } from '../../components/AuthGate';
import { ManagerStore } from '../../stores/ManagerStore';
import { Link } from 'react-router-dom';
import '../../styles/variables.scss';
import { computed } from 'mobx';

interface RouteParams {
  testId: string;
  employeeId?: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  testResultStore: TestResultStore;
  managerStore: ManagerStore;
}

interface State {
  activeSidebarItem: string;
}

interface ResourceClickHandler {
  (id: string): any;
}

@inject('testResultStore')
@inject('managerStore')
@observer
export class DevelopmentGuidePage extends React.Component<Props, State> {
  onScroll = () => {
    const sectionBehavioral = document.getElementById('section-behavioral-development');
    const sectionDevelopment = document.getElementById('section-on-the-job-development');
    const sectionKnowledgeBase = document.getElementById('section-knowlege-base');
    const offset = window.pageYOffset + 100;

    switch (true) {
      case offset < (sectionBehavioral?.offsetTop || 0):
        this.setState({ activeSidebarItem: 'section-functional-development' });
        break;
      case offset > (sectionBehavioral?.offsetTop || 0) && offset < (sectionDevelopment?.offsetTop || 0):
        this.setState({ activeSidebarItem: 'section-behavioral-development' });
        break;
      case offset > (sectionDevelopment?.offsetTop || 0) && offset < (sectionKnowledgeBase?.offsetTop || 0):
        this.setState({ activeSidebarItem: 'section-on-the-job-development' });
        break;
      case offset > (sectionKnowledgeBase?.offsetTop || 0):
        this.setState({ activeSidebarItem: 'section-knowlege-base' });
    }
  };

  componentDidMount() {
    if (this.props.match.params.employeeId) {
      this.props.managerStore!.fetchResults();
    }

    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  state = {
    activeSidebarItem: 'section-functional-development'
  };

  markReadResource: ResourceClickHandler = async (id) => {
    //don't want to visit resource when guidePage is showed from manager zone
    if (!this.props.match.params.employeeId) {
      await TestResultsService.visitResources(this.props.match.params.testId, [id]);
      this.props.testResultStore.fetchResults();
    }
  };

  homeButton() {
    return (
      <div className="footer">
        <Button
          type="submit"
          size="lg"
          className="block"
          variant="primary"
          onClick={() => {
            this.props.history.push('/tests/lastTesting');
          }}
          title={'BACK TO HOME'}
          withDisclosure={true}
        />
      </div>
    );
  }

  isShowedFromHistory = () =>
    computed(() => {
      return this.props.match.url.toString().includes('history');
    });

  getReadableRole = () =>
    computed(() => {
      return this.props.match.params.employeeId
        ? this.props.managerStore.getTitleForTestWith(
            this.props.match.params.testId,
            this.props.match.params.employeeId
          )
        : this.props.testResultStore.getTitleForTestWith(this.props.match.params.testId).get();
    });

  backButton() {
    const employee = this.props.managerStore.getEmployee(this.props.match.params.employeeId || '');

    return (
      (employee || this.isShowedFromHistory().get()) && (
        <Link
          style={{ textDecorationColor: '#585757' }}
          className="development-guide-page__link"
          to={employee ? '../../../employee/' + this.props.match.params.employeeId! : '../../tests/lastTesting'}
        >
          <img
            src={require('./assets/arrowLeft.png').default}
            style={{ width: 7.4, height: 12, marginTop: '25px' }}
            alt="RBI"
          />
          <div className="development-guide-page__back-title">
            {employee && 'TESTINGS'}
            {this.isShowedFromHistory().get() && 'MY TESTINGS'}
          </div>
        </Link>
      )
    );
  }

  render() {
    const renderNavigation = () =>
      NAVIGATION_ITEMS.map((props) => (
        <NavigationItem
          key={props.id}
          id={props.id}
          active={this.state.activeSidebarItem === props.id}
          onClick={() => {
            this.setState({ activeSidebarItem: props.id });
            jumpToSection(props.id);
          }}
        >
          {props.label}
        </NavigationItem>
      ));
    return (
      <DevelopmentGuideLayout
        developmentGuideType={this.backButton() !== false ? 'testWasFinishedBefore' : 'testIsCurrentlyFinished'}
        onGoHomeTapped={() => this.props.history.push('/tests/lastTesting')}
      >
        <AuthGate />
        <div className="header">
          {this.backButton()}
          <div
            className="development-guide-page__header"
            style={{
              textShadow: '1px 1px 2px white, 0 0 1em white, 0 0 0.2em white'
            }}
          >
            <div className="development-guide-page__header__title">
              {!this.props.match.params.employeeId && !this.isShowedFromHistory().get() ? (
                <p className="development-guide-page__title-without-icon">Your personal development guide</p>
              ) : (
                <div className="development-guide-page__title-with-icon">
                  <div className="development-guide-page__circle">
                    {this.getReadableRole().get() !== '' && (
                      <img
                        className="development-guide-page__icon"
                        src={
                          require('../../assets-shared/' + this.getReadableRole().get().replace(/\s/g, '') + '.png')
                            .default
                        }
                        alt="RBI"
                      />
                    )}
                  </div>
                  <p className="development-guide-page__title-with-right-margin">{this.getReadableRole().get()}</p>
                </div>
              )}
            </div>

            <div className="development-guide-page__header__subtitle">
              {!this.props.match.params.employeeId && !this.isShowedFromHistory().get() && 'Congratulations!'} Based on
              your assessment results, you can find below e-learning trainings and other relevant materials and
              information tailor-made for your further personal development.
            </div>
          </div>
        </div>
        <div className="development-guide-page__navigation">
          <div className="development-guide-page__navigation-content">
            <HorizontalNavigation>{renderNavigation()}</HorizontalNavigation>
          </div>
        </div>
        <div className="development-guide-page">
          <div className="development-guide-page__body">
            <div>
              <div className="development-guide-page__content__container">
                <div className="development-guide-page__content__logo">
                  <img
                    src={require('./assets/book.png').default}
                    style={{ width: 28, height: 35.2, marginTop: '3px' }}
                    alt="RBI"
                  />
                </div>
                <div className="development-guide-page__content">
                  {renderFunctionalTestResults(this.props, this.markReadResource)}
                </div>
              </div>
              <div className="separator" />
              <div className="development-guide-page__content__container">
                <div className="development-guide-page__content__logo">
                  <img
                    src={require('./assets/hat.png').default}
                    style={{ width: 36, height: 25.2, marginTop: '3px' }}
                    alt="RBI"
                  />
                </div>
                <div className="development-guide-page__content">{renderBehavioralTestResults(this.props)}</div>
              </div>
              <div className="separator" />
              <div className="development-guide-page__content__container">
                <div className="development-guide-page__content__logo">
                  <img
                    src={require('./assets/building.png').default}
                    style={{ width: 33, height: 33, marginTop: '3px' }}
                    alt="RBI"
                  />
                </div>
                <div className="development-guide-page__content">{renderOnTheJobDevelopmentSection()}</div>
              </div>
              <div className="separator" />
              <div className="development-guide-page__content__container">
                <div className="development-guide-page__content__logo">
                  <img
                    src={require('./assets/world.png').default}
                    style={{ width: 26, height: 26, marginTop: '3px' }}
                    alt="RBI"
                  />
                </div>
                <div className="development-guide-page__content">{renderExistingKnowledgeBaseSection()} </div>
              </div>
            </div>
          </div>
        </div>
        {!this.props.match.params.employeeId && !this.isShowedFromHistory().get() && this.homeButton()}
      </DevelopmentGuideLayout>
    );
  }
}

function getScoreForTest(props: Props, tests: TestResult[]) {
  const results = props.testResultStore!.sumTests(tests).get();
  return (
    <div className="development-guide-page__score">
      {Score(results.points, results.max, results.percentage, 'Overall results', false)}
    </div>
  );
}

function jumpToSection(id: string) {
  const target = document.getElementById(id);
  if (target) {
    scrollIntoView(target, { offset: -110 });
  }
}

function getTestResult(test: TestResult) {
  return (
    <div className="mb-4">
      <p className="text-2xl mb-2 font-bold">{test.title}</p>
      <div className="flex" style={{ fontSize: '14px', paddingTop: '3px' }}>
        <p style={{ color: '#585757', paddingRight: '4px' }}>Current level: </p>
        <p className="text-brand" style={{ fontWeight: 'bold' }}>
          {test.result + ' - ' + test.percentage + '%'}
        </p>
      </div>
    </div>
  );
}

function renderFunctionalTestResults(props: Props, onResourceClick: ResourceClickHandler) {
  const resultSetsComputed = props.testResultStore.getFunctionalTestResults(
    props.match.params.testId,
    props.match.params.employeeId
  );

  let resultSets: FunctionalTestResult[] = [];
  if (resultSetsComputed) {
    resultSets = resultSetsComputed!.get();
  }

  const renderLinks = (result: FunctionalTestResult) => {
    function renderLastAccess(resourceId: string) {
      const match = result.visitedResources.find((res) => res.link_id === resourceId);
      if (!match) {
        return null;
      }
      const date = new Date(match.last_access);
      return (
        <small className="external-resource__last-access ml-auto mr-2 text-grey">
          Last access on {format(date, 'dd.MM.yyyy HH:mm')}
        </small>
      );
    }

    let reachedLevel: string = 'advanced';
    if (result.points <= 11) {
      reachedLevel = 'foundation';
    } else if (result.points <= 17) {
      reachedLevel = 'intermediate';
    }

    return CATEGORY_RESOURCES[result.category]
      .filter((c) => c.level === reachedLevel)
      .map((resource) => (
        <div
          key={resource.url}
          className="external-resource"
          onClick={() => {
            window.open(resource.url, '_blank');
          }}
        >
          {resource.title}
          {renderLastAccess(resource.id)}
          <span className="external-resource__link">
            START TRAINING
            <img className="external-resource__link-image" src={require('./assets/arrowRight.png').default} alt="RBI" />
          </span>
        </div>
      ));
  };
  const renderTitleAndLinks = (resultSet: FunctionalTestResult) => (
    <div key={resultSet.category} className="card__section">
      {getTestResult(resultSet)}
      <div className="links">{renderLinks(resultSet)}</div>
    </div>
  );
  return (
    <Card className="mb-8" id="section-functional-development">
      <div className="card__header">
        <div className="card__header__title">
          <div className="card__header__title__leading">
            <div>
              <h2 className="card__header__title__leading__text">Functional development</h2>
              <p className="card__header__subtitle">
                In this section you can find a list of e-learning trainings aiming at enhancing your procurement
                technical skills.
              </p>
            </div>
          </div>
          {getScoreForTest(props, resultSets)}
        </div>
      </div>
      {resultSets.map(renderTitleAndLinks)}
    </Card>
  );
}

function renderBehavioralTestResults(props: Props) {
  const resultSetsComputed = props.testResultStore.getBehavioralTestResults(
    props.match.params.testId,
    props.match.params.employeeId
  );

  let resultSets: TestResult[] = [];
  if (resultSetsComputed) {
    resultSets = resultSetsComputed!.get();
  }

  return (
    <Card className="mb-8" id="section-behavioral-development">
      <div className="card__header">
        <div className="card__header__title">
          <div className="card__header__title__leading">
            <div>
              <h2 className="card__header__title__leading__text text-3xl">Behavioral development</h2>
              <p className="card__header__subtitle">
                This is a list of measured behavioral competencies. To further develop these competences, please consult
                your local HR. The contact list is attached below.
              </p>
            </div>
          </div>
          {getScoreForTest(props, resultSets)}
        </div>
      </div>
      {resultSets.map((resultSet: any, idx) => {
        return (
          <div key={idx} className="card__section">
            {getTestResult(resultSet)}
          </div>
        );
      })}
      <div className="card__footer">
        <a
          className="card__footer-href"
          href="/assets/hr-contacts-2019.pdf?u=201906270001"
          target="_blank"
          rel="noopener noreferrer"
        >
          Show List of local HR contact points
        </a>
      </div>
    </Card>
  );
}

function renderOnTheJobDevelopmentSection() {
  return (
    <Card className="mb-8" id="section-on-the-job-development">
      <div className="card__header">
        <div className="card__header__title">On-the-job & off-the-job development</div>
        <p className="card__header__subtitle">
          There are many other ways how to progress and improve your both technical and behavioral competencies. For
          more information consult the attached document.
        </p>
      </div>
      <div className="card__footer">
        <a
          className="card__footer-href"
          href="/assets/Development-opportunities-for-Procurement.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Explore On the job development opportunities here
        </a>
      </div>
    </Card>
  );
}

function renderExistingKnowledgeBaseSection() {
  return (
    <Card className="mb-8" id="section-knowlege-base">
      <div className="card__header">
        <div className="card__header__title">Existing knowledgebase</div>
        <p className="card__header__subtitle">
          Presentations from all the previous Procurement Academy sessions and other useful materials are to be found on
          the RBI Procurement Collaboration Room.
        </p>
      </div>
      <div className="card__footer">
        <a
          className="card__footer-href"
          href="https://rbinternational.sharepoint.com/sites/LearnDev/pa/Forms/AllItems.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Explore our rich collection of knowledge available on RBI Procurement Collaboration Room
        </a>
      </div>
    </Card>
  );
}
