import React from 'react';
import './Score.scss';

export function Score(score: number, maxScore: number, percentage: number, result: string, isPointsShowed: boolean) {
  return (
    <div className="score__container">
      <div className="score__bullet">{percentage}%</div>
      <div className="score__info__result">
        <p className="score__info__rating" style={{ paddingTop: isPointsShowed ? 12 : 18 }}>
          {result}
        </p>
        {isPointsShowed && (
          <p className="score__info__points">
            {score}/{maxScore} points
          </p>
        )}
      </div>
    </div>
  );
}
