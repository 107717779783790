import cx from 'classnames';
import update from 'immutability-helper';
import React from 'react';
import {
  BehavioralRoleCompetencyID,
  BEHAVIORAL_COMPETENCIES_LIST,
  FunctionalRoleCompetencyID,
  FUNCTIONAL_COMPETENCIES_LIST
} from '../../const/competencies';
import { Role, RoleDetail } from '../../const/roles';
import { DEFAULT_COMPETENCY_SELECTION, RoleCompetencySelection } from '../../const/testTemplate';
import { scrollIntoView } from '../../utils/scrollIntoView';
import { CheckList } from '../CheckList';
import './RoleCard.scss';

interface RoleCardProps extends RoleDetail {
  onSelect: (role: Role, competencies: RoleCompetencySelection) => void;
  isExpanded: boolean;
  onExpanding: () => void;
  onChangeIsSelectable: (isSelectable: boolean) => void;
}

export const RoleCard: React.FunctionComponent<RoleCardProps> = (props) => {
  const ref = React.useRef() as React.RefObject<HTMLDivElement>;
  const [selectedCompetencies, _setSelectedCompetencies] = React.useState(DEFAULT_COMPETENCY_SELECTION[props.id]);
  const setSelectedCompetencies = (type: keyof RoleCompetencySelection) => (
    nextIds: (FunctionalRoleCompetencyID | BehavioralRoleCompetencyID)[]
  ) => {
    const updatedValue = update(selectedCompetencies, { [type]: { $set: nextIds } });
    _setSelectedCompetencies(updatedValue);
    props.onChangeIsSelectable(selectionIsValid(updatedValue));
    props.onSelect(props.id, updatedValue);
  };
  const renderExpandedContent = () => {
    function renderResponsibilities(): React.ReactNode {
      return (
        <React.Fragment>
          <p className="cluster-role-card__body__title">Main roles and responsibilities</p>
          <ul className="cluster-role-card__two-col-text">
            {props.responsibilities.map((responsibility, idx) => (
              <li key={idx} className="cluster-role-card__li">
                <p className="cluster-role-card__li__text">{responsibility}</p>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    }
    function renderCompetenciesSelector() {
      return (
        <div>
          <div className="cluster-role-card__body__desc">
            Please select competencies relevant for your specific role (no more than 5 functional and 5 behavioral
            competencies). You will be assessed only in the selected competencies.
          </div>
          <div className="cluster-role-card__body__title-competencies">Functional competencies:</div>
          <CheckList
            isSelectionDisabled={selectedCompetencies.functional.length === 5}
            items={FUNCTIONAL_COMPETENCIES_LIST.map((c) => ({
              id: c.id,
              value: c.title
            }))}
            selectedItems={selectedCompetencies['functional']}
            onChange={setSelectedCompetencies('functional')}
          />
          <div className="cluster-role-card__body__title-competencies">Behavioral competencies:</div>
          <CheckList
            isSelectionDisabled={selectedCompetencies.behavioral.length === 5}
            items={BEHAVIORAL_COMPETENCIES_LIST.map((c) => ({
              id: c.id,
              value: c.title
            }))}
            selectedItems={selectedCompetencies['behavioral']}
            onChange={setSelectedCompetencies('behavioral')}
          />
        </div>
      );
    }

    return <div>{props.competenciesAreConfigurable ? renderCompetenciesSelector() : renderResponsibilities()}</div>;
  };

  return (
    <div
      ref={ref}
      className={cx('cluster-role-card mb-6', {
        'cluster-role-card--collapsed': !props.isExpanded,
        'cluster-role-card--expanded': props.isExpanded
      })}
    >
      <div>
        <div
          className={'cluster-role-card__header' + (props.isExpanded ? ' cluster-role-card__header__border' : '')}
          onClick={() => {
            props.onExpanding();
            !props.competenciesAreConfigurable && props.onSelect(props.id, selectedCompetencies);
            setTimeout(() => {
              ref && ref.current && scrollIntoView(ref.current, { offset: -100 });
            }, 100);
          }}
        >
          <div className="cluster-role-card__header__radio">
            {props.isExpanded && <div className="cluster-role-card__header__radio-content" />}
          </div>
          <div className="cluster-role__circle">
            <img
              className="cluster-role__icon"
              src={require('../../assets-shared/' + props.title.replace(/\s/g, '') + '.png').default}
              alt="RBI"
            />
          </div>
          <div>
            <p className="cluster-role-card__header__title">{props.title}</p>
            <p className="cluster-role-card__header__subtitle">{props.subtitle}</p>
          </div>
          <img
            className="cluster-role-card__header__arrow"
            src={require('./assets/' + (props.isExpanded ? 'arrow-up' : 'arrow-down') + '.png').default}
            alt="RBI"
          />
        </div>
        {props.isExpanded && (
          <React.Fragment>
            <div className="cluster-role-card__body">{renderExpandedContent()}</div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

function selectionIsValid(selection: typeof DEFAULT_COMPETENCY_SELECTION['tailor-made']): boolean {
  function hasMandatoryCompetenciesSelection(selection: typeof DEFAULT_COMPETENCY_SELECTION['tailor-made']): boolean {
    return selection.functional.length > 0 && selection.behavioral.length > 0;
  }
  function doesNotExceedSelectionLimit(selection: typeof DEFAULT_COMPETENCY_SELECTION['tailor-made']): boolean {
    return selection.functional.length <= 5 && selection.behavioral.length <= 5;
  }
  return hasMandatoryCompetenciesSelection(selection) && doesNotExceedSelectionLimit(selection);
}
