import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { SessionStore } from '../../stores/SessionStore';

interface AuthGateProps {
  sessionStore?: SessionStore;
}

const AuthGateRenderer: React.FC<AuthGateProps> = (props) => {
  return props.sessionStore!.accessToken === '' ? <Redirect to="/account/login" /> : null;
};

export const AuthGate = inject('sessionStore')(observer(AuthGateRenderer));
