import React from 'react';
import cx from 'classnames';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  width?: number;
}

const DEFAULT_WIDTH = 182;
const DEFAULT_HEIGHT = 48;
const SIZE_RATIO = DEFAULT_HEIGHT / DEFAULT_WIDTH;

export const Logo: React.FunctionComponent<Props> = (props) => {
  const width = props.width || DEFAULT_WIDTH;
  return (
    <img
      src={require('./assets/logo.png').default}
      style={{ minWidth: width, height: width * SIZE_RATIO }}
      alt="RBI"
      className={cx(props.className)}
    />
  );
};
