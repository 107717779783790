import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SectionHeader } from '../../components/SectionHeader';
import './WhatsNewPage.scss';
import { TabLayout } from '../../layouts/TabLayout';
import { Button } from '../../components/Button';

interface Props extends RouteComponentProps<{}> {}

export const WhatsNewPage: React.FunctionComponent<Props> = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  return (
    <TabLayout
      isDropdownVisible={isDropdownVisible}
      selectedIndex="3"
      callback={(isVisible) => setIsDropdownVisible(isVisible)}
    >
      <SectionHeader
        title="What´s new?"
        subtitle="More information regarding the ongoing events and activities under the Procurement Academy Program can be found on RBI Procurement Collaboration Room:"
        history={props.history}
        location={props.location}
        match={props.match}
      />
      <Button
        className="whatsnew__button"
        type="submit"
        size="xl"
        variant="primary"
        title="VISIT COLLABORATION ROOM"
        withShadow={true}
        isExternal={true}
        onClick={() => {
          window.open('https://rbinternational.sharepoint.com/sites/LearnDev', '_blank');
        }}
      />
    </TabLayout>
  );
};
