import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SectionHeader } from '../../components/SectionHeader';
import './CompetenciesPage.scss';
import { COMPETENCIES_WITH_MODULES_LIST } from '../../const/competenciesWithLinks';
import { computed } from 'mobx';
import { TabLayout } from '../../layouts/TabLayout';

interface Props extends RouteComponentProps<{}> {}

export const CompetenciesPage: React.FunctionComponent<Props> = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  return (
    <TabLayout selectedIndex="1" isDropdownVisible={isDropdownVisible} callback={setIsDropdownVisible}>
      <SectionHeader
        title="Competencies"
        subtitle="Explore the whole collection of available trainings. Take any training you are interested in without going through the assessment phase."
        history={props.history}
        location={props.location}
        match={props.match}
      />

      <div className="competencies">{getRows(props)}</div>
    </TabLayout>
  );
};

const maxElementsPerRow = () =>
  computed(() => {
    return window.screen.width > 1024 ? 3 : 2;
  });

const getRows = (props: Props) => {
  const rows = [];
  for (var i = 0; i < COMPETENCIES_WITH_MODULES_LIST.length / maxElementsPerRow().get(); i++) {
    rows.push(
      <div key={i.toString()} className="competencies__row">
        {getElements(props, i * maxElementsPerRow().get())}
      </div>
    );
  }
  return rows;
};

const getElements = (props: Props, index: number) => {
  const items = [];
  var max =
    index + maxElementsPerRow().get() < COMPETENCIES_WITH_MODULES_LIST.length
      ? index + maxElementsPerRow().get()
      : COMPETENCIES_WITH_MODULES_LIST.length;

  for (var element = index; element < max; element++) {
    const indexClassName = 'competencies__row__column competencies__row__column__' + (element % 3).toString();
    items.push(
      <div key={element} className={indexClassName} onClick={navigateFromElement(props, element)}>
        <p className="competencies__index">{COMPETENCIES_WITH_MODULES_LIST[element].index}</p>

        <div className="competencies__title">{COMPETENCIES_WITH_MODULES_LIST[element].title}</div>
        <div className="competencies__button">
          TRAININGS
          <img className="competencies__disclosure" src={require('./assets/disclosure.png').default} alt="RBI" />
        </div>
      </div>
    );
  }
  return items;
};

const navigateFromElement = (props: Props, index: Number) => () => {
  props.history.push('/tests/compets/' + index);
};
