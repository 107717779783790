import React from 'react';
import { Button } from '../Button';

import './TestFooter.scss';

interface Props {
  isActive: boolean;
  onButtonClick: () => void;
  onBackButtonClick?: () => void;
  withBackButton?: boolean;
  status?: { selecedAnswersCount: number; maxSelectedAnswers: number };
}

export const TestFooter: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="test__footer">
      {props.withBackButton && (
        <Button
          className="test__footer__back-button"
          type="submit"
          size="lg"
          variant="transparent"
          title="BACK"
          withDisclosure={true}
          withReversedDisclosure={true}
          onClick={() => {
            props.onBackButtonClick && props.onBackButtonClick!();
          }}
        />
      )}
      <div className="test__footer__trailing">
        {props.status && (
          <span className="test__footer__status">
            Selected
            <p className="test__footer__answers">
              {props.status.selecedAnswersCount + ' of ' + props.status.maxSelectedAnswers}
            </p>
          </span>
        )}
        <Button
          className="test__footer__button"
          type="submit"
          size="lg"
          variant="primary"
          title="CONTINUE"
          withDisclosure={true}
          withShadow={true}
          isInactive={!props.isActive}
          onClick={() => {
            props.isActive && props.onButtonClick();
          }}
        />
      </div>
    </div>
  );
};
