export interface Tutorial {
  title: string;
  redirect: string;
}

const TUTORIAL_1: Tutorial = {
  title: 'RBI Procurement Staff Development Guide Intro and Training',
  redirect: 'https://www.youtube.com/embed/7le9uzV46i0'
};

const TUTORIAL_2: Tutorial = {
  title: 'RBI Procurement Staff Development Guide Short',
  redirect: 'https://www.youtube.com/embed/KGahdxW87eM'
};

const TUTORIAL_3: Tutorial = {
  title: 'RBI Procurement Academy 2020',
  redirect: 'https://www.youtube.com/embed/1ZIK2T5R0bE'
};

const TUTORIAL_4: Tutorial = {
  title: 'RBI Staff Development Guide 2.0',
  redirect: 'https://youtube.com/embed/rIjQ6pmMXNg'
};

export const TUTORIALS_LIST: Tutorial[] = [TUTORIAL_1, TUTORIAL_2, TUTORIAL_3, TUTORIAL_4];
