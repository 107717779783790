import cx from 'classnames';
import React from 'react';
import Tooltip from 'react-tooltip-lite';
import './HorizontalTestNavigation.scss';

interface Props {
  items: { title: string; description: string }[];
  activeItem: number;
}

export const HorizontalTestNavigation: React.FunctionComponent<Props> = (props) => (
  <div className="horizontal-test-navigation">
    <div
      className="horizontal-test-navigation__container"
      style={{
        WebkitMaskImage: `-webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0.8, rgba(0, 0, 0, 1)),
          color-stop(1, rgba(0, 0, 0, 0))
        )`
      }}
    >
      {props.items.map((item, idx) => {
        const isActive = idx === props.activeItem;
        const navigationItem = <div>{item.title}</div>;
        const child = isActive ? (
          <Tooltip className="tooltip" content={<div className="react-tooltip-lite__content">{item.description}</div>}>
            {navigationItem} <QuestionMark />
          </Tooltip>
        ) : (
          navigationItem
        );
        return (
          <NavigationItem
            key={idx}
            active={isActive}
            style={{
              transform: `translateX(${props.activeItem * -100}%)`
            }}
            children={
              <div className="navigation-item">
                {child}
                {props.items.length - 1 !== idx && (
                  <img
                    className="navigation-item__image"
                    alt="RBI"
                    src={require('../../assets-shared/arrow-right-gray.png').default}
                  />
                )}
              </div>
            }
          />
        );
      })}
    </div>
  </div>
);

class NavigationItem extends React.Component<{
  active: boolean;
  style: React.CSSProperties;
}> {
  render() {
    return (
      <div
        className={cx('horizontal-test-navigation__item font-bold text-lg', {
          'horizontal-test-navigation__item--active': this.props.active
        })}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

const QuestionMark: React.FunctionComponent<{}> = (props) => <div className="icon-question-mark">?</div>;
