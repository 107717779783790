import * as mobx from 'mobx';
import { create } from 'mobx-persist';
import { SessionStore } from './SessionStore';
import { AppStore } from './AppStore';
import { TestResultStore } from './TestResultStore';
import { TestTemplateStore } from './TestTemplateStore';
import { ManagerStore } from './ManagerStore';
mobx.configure({ enforceActions: 'observed' });

export interface Stores {
  sessionStore: SessionStore;
  appStore: AppStore;
  testResultStore: TestResultStore;
  testTemplateStore: TestTemplateStore;
  managerStore: ManagerStore;
}

const hydrate = create({
  storage: localStorage,
  jsonify: true
});

export const sessionStore = new SessionStore();
export const appStore = new AppStore();
export const testResultStore = new TestResultStore();
export const testTemplateStore = new TestTemplateStore();
export const managerStore = new ManagerStore();

(async () => {
  await Promise.all([
    hydrate('appStore', appStore),
    hydrate('managerStore', managerStore),
    hydrate('sessionStore', sessionStore),
    hydrate('testResultStore', testResultStore)
  ]);
  appStore.setReady();
})();
