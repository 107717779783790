import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { LandingHeader } from '../../components/LandingHeader';
import { HalfPageBackgroundLayout } from '../../layouts/HalfPageBackgroundLayout';
import { PasswordRequestForm } from '../../components/PasswordRequestForm';
import { SessionStore } from '../../stores/SessionStore';
import '../AccountLoginPage/AccountLoginPage.scss';

interface RouterParams {
  resetToken: string;
}

interface Props extends RouteComponentProps<RouterParams> {
  sessionStore?: SessionStore;
}

export const PasswordRequestPage: React.FunctionComponent<Props> = inject('sessionStore')(
  observer(({ sessionStore }) => (
    <HalfPageBackgroundLayout backgroundImage={require('../../assets-shared/login_background.png').default}>
      <LandingHeader
        title={sessionStore!.requestNewPasswordStatus === 'success' ? 'Check your e-mail' : 'Reset password'}
        goBackTitle={sessionStore!.requestNewPasswordStatus === 'success' ? undefined : 'BACK TO LOGIN'}
        desc={
          sessionStore!.requestNewPasswordStatus === 'success'
            ? 'We’ve sent instructions to reset your password on your e-mail: '
            : 'Enter the email associated with your account and we will send an email with instructions to reset your password.'
        }
        resetPasswordEmail={sessionStore?.changePasswordAccountEmail}
      />
      <div className="auth-card__body">
        <PasswordRequestForm />
      </div>
    </HalfPageBackgroundLayout>
  ))
);
