import React from 'react';
import cx from 'classnames';
import './Card.scss';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {}

export const Card: React.FunctionComponent<Props> = (props) => {
  return (
    <div id={props.id} className={cx('card', props.className)}>
      {props.children}
    </div>
  );
};
