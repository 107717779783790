import React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

interface Props {
  className?: string;
  backgroundImage: string;
  backgroundPosition?: string;
  backgroundSize?: string;
}

const BODY_CLASS_NAME = 'full-page-background-layout';
const TAG_ID = BODY_CLASS_NAME;

export const FullPageBackgroundLayout: React.FunctionComponent<Props> = (props) => {
  const effect = () => {
    removeStyleElement(TAG_ID);
    createStyleElement(props);
    return () => {
      removeStyleElement(TAG_ID);
    };
  };
  React.useEffect(effect, [props, props.backgroundImage]);
  return (
    <>
      <Helmet>
        <body className={cx(BODY_CLASS_NAME, props.className)} />
      </Helmet>
      {props.children}
    </>
  );
};

function createStyleElement(props: Props) {
  const styleElement = document.createElement('style');
  styleElement.id = TAG_ID;
  styleElement.innerHTML = `.${BODY_CLASS_NAME} {
    background: url(${props.backgroundImage}) no-repeat;
    background-position: ${props.backgroundPosition || 'top center'};
    background-size: ${props.backgroundSize || 'cover'};
    background-attachment: fixed;
    min-height: 100vh;
  }`;
  document.head.appendChild(styleElement);
}

function removeStyleElement(id: string) {
  const styleElement = document.getElementById(TAG_ID);
  const parent = styleElement && styleElement.parentElement;
  if (parent && styleElement) {
    parent.removeChild(styleElement);
  }
}
