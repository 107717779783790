import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import {
  AccountService,
  Credentials,
  RegistrationCredentials,
  ResetPasswordCredentials
} from '../services/AccountService';
import { testResultStore } from './index';

interface AuthenticatedUserRaw {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
}

interface AuthenticatedUser {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
}

type LoginStatus = 'unknown' | 'error' | 'success' | 'error-ban';

type RegistrationError = 'success' | 'error';

export class SessionStore {
  @persist
  @observable
  accessToken = '';

  @persist('object')
  @observable
  user?: AuthenticatedUser = undefined;

  @observable
  passwordResetError?: RegistrationError;

  @observable
  registrationFormError: string = '';

  @observable
  requestNewPasswordStatus?: RegistrationError;

  @observable
  changePasswordAccountEmail: string = '';

  @observable loginStatus: LoginStatus = 'unknown';

  getFullName = () => {
    return this.user?.firstName + ' ' + this.user?.lastName;
  };

  @action
  setUser(data?: AuthenticatedUserRaw) {
    this.user = !data
      ? undefined
      : {
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          name: `${data.first_name} ${data.last_name}`,
          email: data.first_name
        };
  }

  @action
  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  @action
  reset = () => {
    this.loginStatus = 'unknown';
    this.accessToken = '';
    this.user = undefined;
    testResultStore.reset();
  };

  @action
  resetNewPasswordStatus = () => {
    this.requestNewPasswordStatus = undefined;
    this.changePasswordAccountEmail = '';
  };

  @action
  logout = () => {
    AccountService.logout();
    this.reset();
  };

  @action
  setLoginStatus(loginStatus: LoginStatus) {
    this.loginStatus = loginStatus;
  }

  @action
  loginWithCredentials = (credentials: Credentials) => {
    this.reset();
    runInAction(async () => {
      const authResponse = await AccountService.loginWithCredentials(credentials);
      runInAction(async () => {
        if (authResponse.ok) {
          this.setAccessToken(authResponse.data.access_token);
          const [accountResponse] = await Promise.all([AccountService.getAccount(), testResultStore.fetchResults()]);
          runInAction(() => {
            this.setUser(accountResponse.ok ? accountResponse.data : undefined);
          });
        }
        if (
          authResponse.status === 401 ||
          authResponse.status === 400 ||
          authResponse.status === 502 ||
          authResponse.problem === 'NETWORK_ERROR'
        ) {
          this.setLoginStatus(authResponse.ok ? 'success' : 'error');
        } else {
          this.setLoginStatus(authResponse.ok ? 'success' : 'error-ban');
        }
      });
    });
  };

  @action
  requestRegistrationAccess = async (credentials: RegistrationCredentials) => {
    this.registrationFormError = '';
    const response = await AccountService.requestRegistrationAccess(credentials);
    runInAction(() => {
      this.registrationFormError = response.ok ? '' : response.data.error;
    });
  };

  @action
  requestNewPassword = async (email: string) => {
    this.requestNewPasswordStatus = undefined;
    const response = await AccountService.requestNewPassword(email);
    runInAction(() => {
      this.changePasswordAccountEmail = response.ok ? email : '';
      this.requestNewPasswordStatus = response.ok ? 'success' : 'error';
    });
  };

  @action
  changePassword = async (credentials: ResetPasswordCredentials) => {
    console.log(credentials);
  };
}
