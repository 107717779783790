import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { SessionStore } from '../../stores/SessionStore';
import { TestResultStore } from '../../stores/TestResultStore';
import { Logo } from '../Logo';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import { Button } from '../Button';
import { TEST_PARTS } from '../../const/testTemplate';
import './InAppHeader.scss';

interface RouteParams {
  testId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  sessionStore?: SessionStore;
  testResultStore?: TestResultStore;
  progressBarType: 'testIsInProgress' | 'testIsFinished' | 'none';
  progressStepIndex?: 0 | 1 | 2 | 3;
  onButtonTapped: () => void;
  isLogoRedirectDisabled?: boolean;
  isHomeButtonShowed?: boolean;
  homeButtonTapped?: () => void;
}

@inject('sessionStore', 'testResultStore')
@observer
class InAppHeaderPresenter extends React.Component<Props> {
  timeout?: number;

  componentDidMount() {
    this.startFetchingResults();
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  startFetchingResults() {
    this.timeout = window.setTimeout(async () => {
      await this.props.testResultStore!.fetchResults();
      this.startFetchingResults();
    }, 30000);
  }

  render() {
    const progressBar = (onMobileDevice: boolean) => {
      return (
        this.props.progressStepIndex !== undefined && (
          <div
            className={
              'app-header__body__progress-bar ' + (onMobileDevice ? 'app-header__body__progress-bar__mobile' : '')
            }
          >
            {TEST_PARTS.map((test, index) => (
              <div
                className={
                  'app-header__body__progress-bar__part ' +
                  (index === this.props.progressStepIndex
                    ? 'app-header__body__progress-bar__part-current'
                    : this.props.progressStepIndex! > index
                    ? 'app-header__body__progress-bar__part-completed'
                    : 'app-header__body__progress-bar__part-incompleted')
                }
              >
                <div
                  className={
                    'app-header__body__progress-bar__part__circle ' +
                    (index === this.props.progressStepIndex
                      ? 'app-header__body__progress-bar__part__circle-current'
                      : this.props.progressStepIndex! > index
                      ? 'app-header__body__progress-bar__part__circle-completed'
                      : 'app-header__body__progress-bar__part__circle-incompleted')
                  }
                >
                  {index < this.props.progressStepIndex! ? (
                    <img
                      className="app-header__body__progress-bar__part__check"
                      alt="RBI"
                      src={require('../../assets-shared/checked-white.png').default}
                    />
                  ) : (
                    index + 1
                  )}
                </div>
                <p className={'app-header__body__progress-bar__part__title'}>{test}</p>
              </div>
            ))}
          </div>
        )
      );
    };
    return (
      <div className="app-header__container">
        <div className="app-header__body items-center justify-between">
          {this.props.isHomeButtonShowed ? (
            <div className="app-header__body__home-button-container">
              <Button
                className="app-header__body__home-button"
                type="submit"
                size="lg"
                variant="transparent"
                title="HOME"
                withDisclosure={true}
                withReversedDisclosure={true}
                onClick={this.props.homeButtonTapped}
              />
            </div>
          ) : (
            <p
              className={
                'app-header__body__link ' + (this.props.isLogoRedirectDisabled ? 'app-header__body__link-disabled' : '')
              }
              onClick={() => {
                !this.props.isLogoRedirectDisabled && this.props.history.push('/tests/lastTesting');
              }}
            >
              <Logo width={160} />
            </p>
          )}

          {this.props.progressBarType !== 'none' && (
            <>
              {progressBar(false)}
              <Button
                className="app-header__body__close-button"
                size="md"
                variant={this.props.progressBarType === 'testIsInProgress' ? 'close' : 'primary'}
                title={this.props.progressBarType === 'testIsInProgress' ? 'CANCEL THE TEST' : 'BACK TO HOME'}
                withDisclosure={this.props.progressBarType === 'testIsInProgress' ? false : true}
                onClick={() => this.props.onButtonTapped()}
              />
            </>
          )}
        </div>
        {progressBar(true)}
      </div>
    );
  }
}

export const InAppHeader = withRouter(InAppHeaderPresenter);
