import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { InAppHeader } from '../../components/InAppHeader';
import { Popup } from '../../components/Popup';
import './DevelopmentGuideLayout.scss';

interface Props {
  onGoHomeTapped: () => void;
  developmentGuideType: 'testIsCurrentlyFinished' | 'testWasFinishedBefore';
}

export const DevelopmentGuideLayout: React.FunctionComponent<Props> = (props) => {
  const [wasPopupClosed, setWasPopupClosed] = useState(false);
  return (
    <div className="development-guide-layout">
      <div className="development-guide-layout__body">{props.children}</div>
      <div className="development-guide-layout__header">
        <InAppHeader
          progressBarType={props.developmentGuideType === 'testIsCurrentlyFinished' ? 'testIsFinished' : 'none'}
          progressStepIndex={3}
          onButtonTapped={() => props.onGoHomeTapped()}
        />
      </div>
      {!wasPopupClosed && props.developmentGuideType === 'testIsCurrentlyFinished' && (
        <Popup maxWidth={586}>
          <div className="popup__body__container">
            <div className="popup__body__container__content">
              <Card className="agenda-card">
                <div className="popup__body__container__content__bullet">
                  <img
                    className="popup__body__container__content__image"
                    src={require('./assets/award.png').default}
                    alt="RBI"
                  />
                </div>
                <h1 className="popup__body__container__content__title">Congratulations!</h1>
                <div className="popup__body__container__content__desc">
                  You’ve finished your testing. Now you can see your results and personalized training package to make
                  some progress.
                </div>
                <Button
                  size="lg"
                  variant="primary"
                  title="SHOW MY PERSONALIZED PLAN"
                  withDisclosure={true}
                  onClick={() => {
                    setWasPopupClosed(true);
                  }}
                />
              </Card>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};
