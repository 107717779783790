import { Form, Formik } from 'formik';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { FormControl } from '../../components/FormControl';
import { TextField } from '../../components/FormField';
import { Label } from '../../components/Label';
import { SessionStore } from '../../stores/SessionStore';
import { FormErrors } from '../FormErrors';
import { LandingFooter } from '../LandingFooter';

import './LoginForm.scss';

interface Props {
  sessionStore?: SessionStore;
  onSuccess: () => void;
}

interface State {
  isPasswordVisible: boolean;
}

const INITIAL_VALUES = {
  username: '',
  password: ''
};

@inject('sessionStore')
@observer
export class LoginForm extends React.Component<Props, State> {
  componentDidMount() {
    when(
      () => this.props.sessionStore!.loginStatus === 'success',
      () => this.props.onSuccess()
    );
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      isPasswordVisible: false
    };
  }

  render() {
    const { loginStatus } = this.props.sessionStore!;
    return (
      <div>
        <div className="login-form">
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={async (values) => this.props.sessionStore!.loginWithCredentials(values)}
          >
            {(formikProps) => (
              <Form>
                <FormControl
                  label={<Label>E-mail</Label>}
                  input={<TextField name="username" placeholder="" autoFocus />}
                />
                <div className="login-form__password">
                  <Link className="login-form__forgot-password" to="/account/password-reset">
                    Forgot password?
                  </Link>
                  <FormControl
                    label={<Label>Password</Label>}
                    input={
                      <TextField name="password" placeholder="" type={this.state.isPasswordVisible ? '' : 'password'} />
                    }
                  />
                  <p
                    className="login-form__password__button"
                    onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                  >
                    {this.state.isPasswordVisible ? 'HIDE' : 'SHOW'}
                  </p>
                </div>

                {loginStatus === 'error' && (
                  <div className="mb-5">
                    <FormErrors message="Invalid credentials given." />
                  </div>
                )}
                {loginStatus === 'error-ban' && (
                  <div className="mb-5">
                    <FormErrors message="Too many unsuccessful login attempts in a row! Try again later!" />
                  </div>
                )}
                <Button
                  type="submit"
                  size="lg"
                  variant="primary"
                  disabled={formikProps.isSubmitting}
                  title={formikProps.isSubmitting ? 'SIGN IN...' : 'SIGN IN'}
                  withDisclosure={true}
                  withShadow={true}
                />
                <div className="login-form__footer text-sm mt-6">
                  Don´t you have an account yet? <Link to="/account/register">Create your account.</Link>
                </div>
                <LandingFooter />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
