import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SectionHeader } from '../../components/SectionHeader';
import './EmployeesPage.scss';
import { ManagerStore } from '../../stores/ManagerStore';
import { inject, observer } from 'mobx-react';
import { TabLayout } from '../../layouts/TabLayout';

interface Props extends RouteComponentProps<{}> {
  managerStore?: ManagerStore;
}

@inject('managerStore')
@observer
export class EmployeesPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.managerStore!.isManager.get()) {
      this.props.history.push('/welcome');
    }
    this.props.managerStore!.fetchResults();
  }

  state = {
    isDropdownVisible: false
  };
  results = this.props.managerStore!.allEmployees.get();

  render() {
    return (
      <TabLayout
        selectedIndex="5"
        isDropdownVisible={this.state.isDropdownVisible}
        callback={(isVisible) => {
          this.setState({ isDropdownVisible: isVisible });
        }}
      >
        <SectionHeader
          title={'My team (' + this.results.length + ')'}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
        />

        <div className="employee__list">
          {this.results.map((res, index) => (
            <div key={index} className="employee__test" onClick={() => this.props.history.push('/employee/' + res.id)}>
              <div className="employee__testTitle">{res.name}</div>
              <div className="employee__trailing">
                <div className={'employee__testDate' + (res.isUserTested && ' employee__testDate-taken')}>
                  {res.isUserTested ? (
                    <p className="employee__testDate__taken">Last self-assessment test:</p>
                  ) : (
                    <p className="employee__testDate__not-taken">This user doesn’t have any tests</p>
                  )}

                  {res.isUserTested && (
                    <span className="employee__testDate__date">
                      <img
                        className="employee__calendar"
                        src={require('./../../../src/assets-shared/calendar.png').default}
                        alt="RBI"
                      />
                      {res.lastTest.date}
                    </span>
                  )}
                </div>

                {res.isUserTested && (
                  <div className={'employee__testDate employee__test-status'}>
                    <p className="employee__testDate__status">Self-assessment test status:</p>

                    <span
                      className={
                        'employee__testDate__status-' +
                        (res.lastTest.status === 'completed' ? 'completed' : 'inprogress')
                      }
                    >
                      <img
                        className={'employee__' + (res.lastTest.status === 'completed' ? 'completed' : 'inprogress')}
                        src={
                          require(res.lastTest.status === 'completed'
                            ? './../../../src/assets-shared/completed.png'
                            : './../../../src/assets-shared/inProgress.png').default
                        }
                        alt="RBI"
                      />
                      {res.lastTest.status === 'completed' ? 'Finished' : 'In progress'}
                    </span>
                  </div>
                )}

                <div className={res.isUserTested ? 'employee__spacer_longer' : 'employee__spacer'} />

                <img
                  className="employee__disclosure"
                  src={require('./../../../src/assets-shared/disclosure.png').default}
                  alt="RBI"
                />
              </div>
            </div>
          ))}
        </div>
      </TabLayout>
    );
  }
}
