export type Role = 'sourcer' | 'buyer' | 'manager' | 'tech-processes' | 'leaders' | 'tailor-made';

export interface RoleDetail {
  id: Role;
  title: string;
  subtitle: string;
  responsibilities: string[];
  competenciesAreConfigurable: boolean;
}

const SOURCER_RESPONSIBILITIES = [
  'Procurement professional responsible for various given categories of goods and/or services at a local network bank. Sourcers generate and implement sourcing strategies with the exception of “1-hand” and regional categories where the alignment with responsible category manager is necessary',
  'Manages the company’s supply portfolio ensuring transparency of spending',
  'Generates and implements efficient sourcing strategies',
  'Analyzes and calculates costs of procurement and suggests methods to decrease expenditure',
  'Invents negotiation strategies and secures profitable deals',
  'Optimizes sourcing procedures to attain maximum efficiency',
  'Cooperates with stakeholders to guarantee agreement on terms and processes',
  'Researches and anticipates shifts in the negotiating power of suppliers',
  'Performs cost and scenario analysis, and benchmarking',
  'Estimates risks and apply risk minimizing techniques',
  'Discovers and partners with trustworthy vendors and suppliers',
  'Determines quantity and timing of deliveries'
];

const CATEGORY_MANAGER_RESPONSIBILITIES = [
  'Procurement role specialized in a particular “1-hand” or regional category of goods and/or services. This role has a strategic long-term oversight over the sourcing and procurement processes across RBI network banks; requires strong understanding of given categories and commonly involves cross-border interaction with internal and external stakeholders.',
  'Building a long-term category strategy applicable in given categories either locally or on group level',
  'Involving peers from other network banks when formulating and implementing the strategy during regular meetings',
  'Sharing best practices and solutions with colleagues working on similar categories',
  'Involving stakeholders into defining the category strategies with a goal to reach a common alignment',
  'Driving projects contributing to the saving goals in given categories and supporting local savings projects',
  'Leading projects aiming at measuring the satisfaction of internal stakeholders with selected suppliers',
  'Ensuring the overall satisfaction of the stakeholders',
  'Managing strategic supplier relationships',
  'Identification and implementation of new business processes which lead to efficiencies and other benefits',
  'Engagement and development of peer category managers within given areas'
];

const BUYER_RESPONSIBILITIES = [
  'Creates purchase orders, manages potential changes or cancellation of orders',
  'Controls the correctness of invoices compared to orders',
  'Approves purchase requisitions based on Frame agreements',
  'Is responsible for validation of deliveries of ordered goods and/or services and the related receipt',
  'Creates catalogue items in the ordering system if needed',
  'Consults requestors how to apply procurement rules and policies in case of purchasing goods and/or services',
  'Manages the possible complaints related to deliveries, their incompleteness, damages, return, loss or cancellation',
  'Reports potential problems with deliveries and supports their resolution',
  'Supports the supplier evaluation process',
  'Is responsible for correctness of data of suppliers and respective purchasing items for the assigned category'
];

const PROCUREMENT_TECH_AND_PROCESSES_RESPONSIBILITIES = [
  'Analysing and promoting improvements of existing processes and leading the implementation of the standard process at Head office and in Network banks',
  'Offering support to members of the group to optimize the Procure & Pay process for its suppliers',
  'Supporting the implementation of the group strategy of e-Procurement and its utilization for reporting functions and needs of RBI Senior management',
  'Promoting the utilization of e-Procurement applications',
  'Interacting with Oracle Technical support regarding system issues and bugs related to standard functionality',
  'Working with development team on the creation and validation of user interfaces and functional/non-functional requirements',
  'Providing legal guidance on the contractual process and documentation',
  'Creating and maintaining Procurement employee development programs',
  'Planning, organizing and managing regular and ad hoc Procurement events',
  'Setting and tracking Key Performance Indicators',
  'Utilization of Reporting tools at group level',
  'Utilization of a  group-wide communication platform serving RBI Procurement community',
  'Utilization of Procurement Policies througout RBI Group'
];

const LEADER_RESPONSIBILITIES = [
  'Creation and management of short, mid, and long term goals and objectives',
  'Management of team of procurement staff in the relevant procurement department',
  'Development of organizational procurement strategy',
  'Identification and realization of cost-saving and cost-reduction opportunities',
  'Selection and management of procurement systems',
  'Creating a talent management process in coordination with HR to ensure that the right resources are in place',
  'Budget management for categories under management – and for procurement itself',
  'Communication with key internal customers',
  'Development of benchmarks and scorecards to be used for continuous improvement',
  'Leadership of cross-functional teaming across other business functions and initiatives'
];

const ROLE_SOURCER: RoleDetail = {
  id: 'sourcer',
  title: 'Sourcer',
  subtitle: 'Sourcer, Sourcing Manager, Real Estate / Facility Specialist, Property Manager, Logistic Specialist',
  responsibilities: SOURCER_RESPONSIBILITIES,
  competenciesAreConfigurable: false
};

const ROLE_BUYER: RoleDetail = {
  id: 'buyer',
  title: 'Buyer',
  subtitle: 'Buyer, Operative Buyer, P2P Manager',
  responsibilities: BUYER_RESPONSIBILITIES,
  competenciesAreConfigurable: false
};

const ROLE_CATEGORY_MANAGER: RoleDetail = {
  id: 'manager',
  title: 'Category Manager',
  subtitle: 'Category Leader, Category Expert, Group Category Manager, Regional Category Manager/Lead Sourcer CZ&SK',
  responsibilities: CATEGORY_MANAGER_RESPONSIBILITIES,
  competenciesAreConfigurable: false
};

const ROLE_PROCUREMENT_TECH_AND_PROCSSES: RoleDetail = {
  id: 'tech-processes',
  title: 'Procurement Technology and Processes',
  subtitle:
    'Performance Manager, Contract Manager, Operations Manager, iProc System Administrator, iProc Superuser, Solution Architect, Process Manager, Controller, Outsourcing Manager',
  responsibilities: PROCUREMENT_TECH_AND_PROCESSES_RESPONSIBILITIES,
  competenciesAreConfigurable: false
};

const ROLE_LEADERS: RoleDetail = {
  id: 'leaders',
  title: 'Leaders',
  subtitle: 'Head of Group Procurement Department, Head of Central Procurement Department, Team Leader',
  responsibilities: LEADER_RESPONSIBILITIES,
  competenciesAreConfigurable: false
};

const ROLE_TAILORED: RoleDetail = {
  id: 'tailor-made',
  title: 'Tailor made',
  subtitle: 'Roles not identified within any of previous 5 role clusters',
  responsibilities: SOURCER_RESPONSIBILITIES,
  competenciesAreConfigurable: true
};

export const ROLE_LIST: RoleDetail[] = [
  ROLE_SOURCER,
  ROLE_BUYER,
  ROLE_CATEGORY_MANAGER,
  ROLE_PROCUREMENT_TECH_AND_PROCSSES,
  ROLE_LEADERS,
  ROLE_TAILORED
];
