export interface CompetencyWithModules {
  index: string;
  title: string;
  modules: CompetencyModule[];
}
export interface CompetencyModule {
  title: string;
  redirect: string;
}

const p1: CompetencyWithModules = {
  index: 'P01',
  title: 'Procurement Leadership, Governance and Functional Design',
  modules: [
    { title: 'Introduction to Procurement', redirect: 'https://rbi.sourcexchange.co.uk/p01/01/story_html5.html' },
    { title: 'Procurement Guiding principles', redirect: 'https://rbi.sourcexchange.co.uk/p01/02/story_html5.html' },
    { title: 'Procurement Cycle', redirect: 'https://rbi.sourcexchange.co.uk/p01/03/story_html5.html' },
    { title: 'Procurement process', redirect: 'https://rbi.sourcexchange.co.uk/p01/04/story_html5.html' },
    {
      title: 'Roles and Responsibilities of Procurement',
      redirect: 'https://rbi.sourcexchange.co.uk/p01/05/story_html5.html'
    },
    { title: 'Procurement Policy', redirect: 'https://rbi.sourcexchange.co.uk/p01/06/story_html5.html' },
    { title: 'Evaluation to Establishment', redirect: 'https://rbi.sourcexchange.co.uk/p01/07/story_html5.html' },
    { title: 'Introduction to outsourcing', redirect: 'https://rbi.sourcexchange.co.uk/p01/08/story_html5.html' },
    { title: 'Outsourcing process', redirect: 'https://rbi.sourcexchange.co.uk/p01/09/story_html5.html' },
    { title: 'Outsourcing rationale', redirect: 'https://rbi.sourcexchange.co.uk/p01/10/story_html5.html' }
  ]
};

const p2: CompetencyWithModules = {
  index: 'P02',
  title: 'Supply Base and Category Analysis',
  modules: [
    {
      title: 'Defining and Managing the business need',
      redirect: 'https://rbi.sourcexchange.co.uk/p02/01/story_html5.html'
    },
    { title: 'Supply Positioning', redirect: 'https://rbi.sourcexchange.co.uk/p02/02/story_html5.html' },
    { title: 'Shaping the market', redirect: 'https://rbi.sourcexchange.co.uk/p02/03/story_html5.html' },
    { title: 'Porters 5 Forces', redirect: 'https://rbi.sourcexchange.co.uk/p02/04/story_html5.html' },
    { title: 'Customer Preferencing', redirect: 'https://rbi.sourcexchange.co.uk/p02/05/story_html5.html' },
    { title: 'Monopolies and Cartels', redirect: 'https://rbi.sourcexchange.co.uk/p02/06/story_html5.html' }
  ]
};

const p3: CompetencyWithModules = {
  index: 'P03',
  title: 'Risk Management and Vulnerability',
  modules: [
    { title: 'Introduction to Risk Management', redirect: 'https://rbi.sourcexchange.co.uk/p03/01/story_html5.html' },
    { title: 'Vulnerability', redirect: 'https://rbi.sourcexchange.co.uk/p03/02/story_html5.html' }
  ]
};

const p4: CompetencyWithModules = {
  index: 'P04',
  title: 'Strategy Formulation and Supply Market Engagement',
  modules: [
    { title: 'Business Needs and Specifications', redirect: 'https://rbi.sourcexchange.co.uk/p04/01/story_html5.html' },
    { title: 'Developing strategies', redirect: 'https://rbi.sourcexchange.co.uk/p04/02/story_html5.html' },
    { title: 'Contract strategy', redirect: 'https://rbi.sourcexchange.co.uk/p04/05/story_html5.html' },
    { title: 'Procurement marketing', redirect: 'https://rbi.sourcexchange.co.uk/p04/06/story_html5.html' },
    { title: 'Market matrix', redirect: 'https://rbi.sourcexchange.co.uk/p04/07/story_html5.html' },
    { title: 'Integrating techniques', redirect: 'https://rbi.sourcexchange.co.uk/p04/08/story_html5.html' },
    { title: 'Reverse marketing', redirect: 'https://rbi.sourcexchange.co.uk/p04/09/story_html5.html' },
    { title: 'Agile Sourcing', redirect: 'https://rbi.sourcexchange.co.uk/p04/10/story_html5.html' }
  ]
};

const p5: CompetencyWithModules = {
  index: 'P05',
  title: 'Supplier Appraisal, Evaluation and Audit',
  modules: [
    { title: 'Basics of Supplier Appraisal', redirect: 'https://rbi.sourcexchange.co.uk/p05/01/story_html5.html' },
    { title: 'Supplier Appraisal', redirect: 'https://rbi.sourcexchange.co.uk/p05/02/story_html5.html' },
    { title: 'Supplier Selection', redirect: 'https://rbi.sourcexchange.co.uk/p05/03/story_html5.html' },
    { title: 'Contract Award', redirect: 'https://rbi.sourcexchange.co.uk/p05/04/story_html5.html' },
    {
      title: 'Evaluation Panel Member Responsibilities',
      redirect: 'https://rbi.sourcexchange.co.uk/p05/05/story_html5.html'
    }
  ]
};

const p6: CompetencyWithModules = {
  index: 'P06',
  title: 'Contracting and Legal Aspects',
  modules: [
    { title: 'Introduction to Contract Law', redirect: 'https://rbi.sourcexchange.co.uk/p06/01/story_html5.html' },
    { title: 'Overview of English Law', redirect: 'https://rbi.sourcexchange.co.uk/p06/02/story_html5.html' },
    { title: 'Contract and contract terms', redirect: 'https://rbi.sourcexchange.co.uk/p06/03/story_html5.html' },
    { title: 'Offer and Acceptance', redirect: 'https://rbi.sourcexchange.co.uk/p06/04/story_html5.html' },
    { title: 'Consideration', redirect: 'https://rbi.sourcexchange.co.uk/p06/05/story_html5.html' },
    { title: 'Intention', redirect: 'https://rbi.sourcexchange.co.uk/p06/06/story_html5.html' }
  ]
};

const p7: CompetencyWithModules = {
  index: 'P07',
  title: 'Pricing, Cost and Financial Analysis',
  modules: [
    { title: 'Basics of Company Statements', redirect: 'https://rbi.sourcexchange.co.uk/p07/01/story_html5.html' },
    { title: 'Finance Reports', redirect: 'https://rbi.sourcexchange.co.uk/p07/02/story_html5.html' },
    { title: 'Pricing Policy', redirect: 'https://rbi.sourcexchange.co.uk/p07/03/story_html5.html' },
    { title: 'Finance Ratios', redirect: 'https://rbi.sourcexchange.co.uk/p07/04/story_html5.html' },
    { title: 'Costing Methods', redirect: 'https://rbi.sourcexchange.co.uk/p07/05/story_html5.html' },
    { title: 'Should Cost Modelling', redirect: 'https://rbi.sourcexchange.co.uk/p07/06/story_html5.html' }
  ]
};

const p8: CompetencyWithModules = {
  index: 'P08',
  title: 'Negotiation',
  modules: [
    { title: 'People and Behaviour', redirect: 'https://rbi.sourcexchange.co.uk/p08/01/story.html' },
    { title: 'Principles of Negotiation', redirect: 'https://rbi.sourcexchange.co.uk/p08/02/story_html5.html' },
    { title: 'Structure of Negotiation', redirect: 'https://rbi.sourcexchange.co.uk/p08/03/story.html' },
    { title: 'Negotiation Tactics', redirect: 'https://rbi.sourcexchange.co.uk/p08/04/story_html5.html' },
    { title: 'Persuasion Methods', redirect: 'https://rbi.sourcexchange.co.uk/p08/05/story.html' },
    { title: 'Persuasion Profile', redirect: 'https://rbi.sourcexchange.co.uk/p08/06/story_html5.html' },
    { title: 'Developing and Using Power', redirect: 'https://rbi.sourcexchange.co.uk/p08/07/story.html' },
    { title: 'Communication Skills', redirect: 'https://rbi.sourcexchange.co.uk/p08/08/story_html5.html' },
    { title: 'Competitive and Collaborative', redirect: 'https://rbi.sourcexchange.co.uk/p08/09/story_html5.html' },
    { title: 'Assertiveness', redirect: 'https://rbi.sourcexchange.co.uk/p08/10/story_html5.html' },
    { title: 'Culture Dimensions', redirect: 'https://rbi.sourcexchange.co.uk/p08/11/story_html5.html' },
    { title: 'Preparation and Planning', redirect: 'https://rbi.sourcexchange.co.uk/p08/12/story_html5.html' },
    {
      title: 'Dealing with Conflict and Pressure',
      redirect: 'https://rbi.sourcexchange.co.uk/p08/13/story_html5.html'
    },
    { title: 'Movement Techniques', redirect: 'https://rbi.sourcexchange.co.uk/p08/14/story_html5.html' },
    { title: 'Tactics 2', redirect: 'https://rbi.sourcexchange.co.uk/p08/15/story_html5.html' },
    { title: 'Advanced Culture Dimensions', redirect: 'https://rbi.sourcexchange.co.uk/p08/16/story_html5.html' },
    { title: 'Game Theory', redirect: 'https://rbi.sourcexchange.co.uk/p08/17/story_html5.html' },
    { title: 'Understanding Yourself', redirect: 'https://rbi.sourcexchange.co.uk/p08/18/story_html5.html' },
    { title: 'Motivational Theory', redirect: 'https://rbi.sourcexchange.co.uk/p08/19/story_html5.html' },
    { title: 'Tactics 3', redirect: 'https://rbi.sourcexchange.co.uk/p08/20/story_html5.html' },
    {
      title: 'Virtual Negotiations – Top Tricks and Tips',
      redirect: 'https://rbi.sourcexchange.co.uk/p08/21/story.html'
    }
  ]
};

const p9: CompetencyWithModules = {
  index: 'P09',
  title: 'Supplier Performance, Relationship and Contract Management',
  modules: [
    {
      title: 'Contract Management Essentials – Part 1',
      redirect: 'https://rbi.sourcexchange.co.uk/p09/01/story_html5.html'
    },
    {
      title: 'Contract Management Essentials – Part 2',
      redirect: 'https://rbi.sourcexchange.co.uk/p09/02/story_html5.html'
    },
    {
      title: 'Supplier Relationship Management Governance',
      redirect: 'https://rbi.sourcexchange.co.uk/p09/03/story_html5.html'
    },
    { title: 'Managing Supplier Performance', redirect: 'https://rbi.sourcexchange.co.uk/p09/04/story_html5.html' },
    { title: 'Supplier Development', redirect: 'https://rbi.sourcexchange.co.uk/p09/05/story_html5.html' },
    { title: 'Supplier Relationship Measurement', redirect: 'https://rbi.sourcexchange.co.uk/p09/06/story_html5.html' }
  ]
};

const p10p11: CompetencyWithModules = {
  index: 'P10/11',
  title: 'Transactional Procurement and Technology',
  modules: [
    { title: 'eProcurement', redirect: 'https://rbi.sourcexchange.co.uk/p10/01/story.html' },
    { title: 'eAuctions', redirect: 'https://rbi.sourcexchange.co.uk/p10/02/story_html5.html' },
    { title: 'eCatalogues', redirect: 'https://rbi.sourcexchange.co.uk/p10/03/story_html5.html' }
  ]
};

const p12: CompetencyWithModules = {
  index: 'P12',
  title: 'Project Management and Change',
  modules: [
    {
      title: 'Change Management – Introduction to Change',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/01/story_html5.html'
    },
    {
      title: 'Change Management – Methods of Change',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/02/story_html5.html'
    },
    {
      title: 'Change Management – Driving Change',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/03/story_html5.html'
    },
    {
      title: 'Project Management – Introduction to Project Management',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/04/story_html5.html'
    },
    {
      title: 'Project Management – Project Planning',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/05/story_html5.html'
    },
    {
      title: 'Project Management – Managing Successful Projects',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/06/story_html5.html'
    },
    {
      title: 'Project Management – Phases and Methodology',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/07/story_html5.html'
    },
    {
      title: 'Project Management – Managing the Stakeholder',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/08/story_html5.html'
    },
    {
      title: 'Project Management – Effective Team Management',
      redirect: 'https://rbi.sourcexchange.co.uk/p12/09/story_html5.html'
    }
  ]
};

const p13: CompetencyWithModules = {
  index: 'P13',
  title: 'Sustainability, Corporate and Social Responsibility',
  modules: [
    {
      title: 'Introduction to Sustainability in the Supply Chain',
      redirect: 'https://rbi.sourcexchange.co.uk/p13/01/story.html'
    },
    {
      title: 'The Business Case for Sustainable Procurement',
      redirect: 'https://rbi.sourcexchange.co.uk/p13/02/story.html'
    },
    { title: 'Probity in Procurement', redirect: 'https://rbi.sourcexchange.co.uk/p13/03/story_html5.html' }
  ]
};

const p14: CompetencyWithModules = {
  index: 'P14',
  title: '  RBI Specific modules',
  modules: [
    { title: 'IT Procurement', redirect: 'https://rbi.sourcexchange.co.uk/p14/01/story_html5.html' },
    { title: 'Project Management', redirect: 'https://rbi.sourcexchange.co.uk/p14/02/story_html5.html' },
    { title: 'Tendering', redirect: 'https://rbi.sourcexchange.co.uk/p14/03/story_html5.html' },
    { title: 'Marketing Procurement', redirect: 'https://rbi.sourcexchange.co.uk/p14/04/story_html5.html' },
    {
      title: 'Cloud Computing and Procuring Cloud Services',
      redirect: 'https://rbi.sourcexchange.co.uk/p02/07/story_html5.html?lms=1'
    },
    { title: 'Agile Sourcing', redirect: 'https://rbi.sourcexchange.co.uk/p04/10/story_html5.html' },
    {
      title: 'Agile Sourcing in RBI Procurement',
      redirect: 'https://rbi.sourcexchange.co.uk/agile_sourcing/story.html'
    }
  ]
};

export const COMPETENCIES_WITH_MODULES_LIST: CompetencyWithModules[] = [
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10p11,
  p12,
  p13,
  p14
];
