import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Score } from '../../components/Score';
import './LastTestingPage.scss';
import { TestResultStore } from '../../stores/TestResultStore';
import { SessionStore } from '../../stores/SessionStore';
import { inject, observer } from 'mobx-react';
import { EmptyMessage } from '../../components/EmptyMessage';
import { TabLayout } from '../../layouts/TabLayout';
import { Button } from '../../components/Button';
import { sessionStore } from '../../stores';
import { TestRow } from '../../components/TestRow';
import { RemoteTestInstance } from '../../services/TestResultsService';
import { TestTemplateStore } from '../../stores/TestTemplateStore';

interface Props extends RouteComponentProps<{}> {
  testResultStore?: TestResultStore;
  sessionStore?: SessionStore;
  testTemplateStore?: TestTemplateStore;
}

@inject('testResultStore')
@inject('sessionStore')
@inject('testTemplateStore')
@observer
export class LastTestingPage extends React.Component<Props> {
  componentDidMount() {
    this.props.testResultStore!.fetchResults();

    if (this.props.testTemplateStore?.redirectToTest) {
      this.props.testTemplateStore.setSholdOpenExitDialog(true);
      this.props.history.push('/tests/' + this.props.testTemplateStore.redirectToTest + '/functional/step/0');
    }
  }
  state = {
    isDropdownVisible: false
  };

  getScoreForTest = (testId: string) => {
    const functional = this.props.testResultStore!.getFunctionalTestResults(testId)!.get();
    const behavioral = this.props.testResultStore!.getBehavioralTestResults(testId)!.get();
    const results = this.props.testResultStore!.sumTests(behavioral.concat(functional)).get();
    return (
      <div className="testing__score">
        {Score(results.points, results.max, results.percentage, 'Overall results', true)}
      </div>
    );
  };

  render() {
    const navigateFromRow = (status: string, id: string) => () => {
      if (status === 'active') {
        this.props.history.push('/tests/' + id + '/functional/step/0');
      } else {
        this.props.history.push('/development-guides/history/' + id);
      }
    };

    const results = this.props.testResultStore!.allTestsAsList.get();
    return (
      <TabLayout
        isDropdownVisible={this.state.isDropdownVisible}
        selectedIndex="0"
        callback={(isVisible) => {
          this.setState({ isDropdownVisible: isVisible });
        }}
      >
        <div className="lastTesting__header">
          <p className="lastTesting__header__title">{'Welcome ' + sessionStore.getFullName() + ','}</p>
          <div className="lastTesting__header__body">
            <p className="lastTesting__header__body__desc">
              to a guide to foster your professional growth. Your personalized training package will help you to build
              and bring the most out of your Procurement career in RBI.
            </p>
            <Button
              className="lastTesting__header__body__button"
              type="submit"
              size="lg"
              variant="primary"
              title={results.length === 0 || results[0].status !== 'active' ? 'START NEW TESTING' : 'CONTINUE TESTING'}
              withShadow={true}
              onClick={() => {
                if (results.length === 0 || results[0].status !== 'active') {
                  this.props.history.push('/tests/role-selection');
                } else {
                  this.props.history.push('/tests/' + results[0].id + '/functional/step/0');
                }
              }}
            />
          </div>
        </div>

        {results.length > 0 && <p className="lastTesting__title">{'My previous testings (' + results.length + ')'}</p>}
        <div className="lastTesting__list">
          {results.length === 0 ? (
            <EmptyMessage message="There aren't any tests" />
          ) : (
            <>
              {results.map(
                (res) =>
                  res.status !== 'deleted' && (
                    <TestRow
                      canProceedTest={true}
                      test={
                        {
                          status: res.status,
                          id: res.id,
                          date: res.date
                        } as RemoteTestInstance
                      }
                      title={res.title}
                      onClick={navigateFromRow(res.status, res.id)}
                    />
                  )
              )}
            </>
          )}
        </div>
      </TabLayout>
    );
  }
}
