import React from 'react';
import cx from 'classnames';
import './Popup.scss';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  maxWidth: Number;
}

export const Popup: React.FunctionComponent<Props> = (props) => {
  return (
    <div className={cx('popup', props.className)}>
      <div className="popup__body" style={{ maxWidth: props.maxWidth + 'px' }}>
        {props.children}
      </div>
    </div>
  );
};
