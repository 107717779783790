import cx from 'classnames';
import React from 'react';
import './HorizontalNavigation.scss';

interface Props {}

interface NavigationItemProps {
  id: string;
  active?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const HorizontalNavigation: React.FunctionComponent<Props> = (props) => (
  <div className="card horizontal-navigation">{props.children}</div>
);

export const NavigationItem: React.FunctionComponent<NavigationItemProps> = (props) => {
  return (
    <div
      className={cx('horizontal-navigation-item', {
        'horizontal-navigation-item--active': props.active
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
