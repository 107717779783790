export interface WelcomeMenuRow {
  title: string;
  redirect: string;
  desc?: string;
  external?: Boolean;
  img: string;
}

const MENU_HISTORY: WelcomeMenuRow = {
  title: 'My testings',
  redirect: '/tests/lastTesting',
  desc: 'Continue taking online trainings specifically selected for your job profile and current skill level',
  img: 'myTesting.png'
};

const MENU_EXPLORE: WelcomeMenuRow = {
  title: 'Available trainings',
  redirect: '/tests/compets',
  desc: 'Take any training you are interested in without going through the assessment phase',
  img: 'trainings.png'
};

const MENU_NEWSKILLS: WelcomeMenuRow = {
  title: 'NEW – AI in Procurement',
  redirect: '/future',
  img: 'whatsNew.png'
};

const MENU_WHATSNEW: WelcomeMenuRow = {
  title: 'What’s new?',
  redirect: '/whatsnew',
  desc:
    'More information regarding the ongoing events and activities under the Procurement Academy Program can be found on RBI Procurement Colllaboration Room.',
  img: 'whatsNew.png'
};

const MENU_TUTORIALS: WelcomeMenuRow = {
  title: 'Videos & tutorials',
  redirect: '/tutorials',
  img: 'videos.png'
};

const MENU_MANAGERZONE: WelcomeMenuRow = {
  title: 'My team',
  redirect: '/employees',
  img: 'myTeam.png'
};

export const getWelcomeMenuList = (isManager: boolean) => {
  let list = [MENU_HISTORY, MENU_EXPLORE, MENU_NEWSKILLS, MENU_WHATSNEW, MENU_TUTORIALS];
  if (isManager) {
    list.push(MENU_MANAGERZONE);
  }
  return list;
};
