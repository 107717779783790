import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../components/Logo';

import './LandingHeader.scss';

type P = {
  title: string;
  goBackTitle?: string;
  subtitle?: string;
  resetPasswordEmail?: string;
  desc: string;
};

export const LandingHeader: React.FunctionComponent<P> = (props) => {
  return (
    <div className="landing-header">
      <div className="landing-header__logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {props.goBackTitle && (
        <div className="landing-header__button-back">
          <Link className="flex hover:underline no-underline" to={''}>
            <img
              className="landing-header__arrow-back"
              src={require('./assets/left_arrow_green.png').default}
              alt="RBI"
            />
            <p>{props.goBackTitle}</p>
          </Link>
        </div>
      )}
      <p className={'title'}>{props.title}</p>
      {props.subtitle && <p className="subtitle">{props.subtitle}</p>}
      <p className="description">{props.desc}</p>
      {props.resetPasswordEmail && <p className="landing-header__reset-password-email">{props.resetPasswordEmail}</p>}
    </div>
  );
};
