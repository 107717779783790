import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { LoginForm } from '../../components/LoginForm';
import { LandingHeader } from '../../components/LandingHeader';
import { HalfPageBackgroundLayout } from '../../layouts/HalfPageBackgroundLayout';

interface Props extends RouteComponentProps<{}> {}

export const AccountLoginPage: React.FunctionComponent<Props> = (props) => (
  <HalfPageBackgroundLayout backgroundImage={require('../../assets-shared/login_background.png').default}>
    <LandingHeader
      title="Log in"
      subtitle="Welcome back in RBI Procurement Staff Development Guide"
      desc="Your personalized training package will help you to build and bring the most out of your Procurement career in RBI."
    />
    <div className="auth-card__body">
      <LoginForm
        onSuccess={() => {
          props.history.push('/tests/lastTesting');
        }}
      />
    </div>
  </HalfPageBackgroundLayout>
);
