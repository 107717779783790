import React from 'react';
import cx from 'classnames';
import './VideoOverlay.scss';

const VIDEO_SESSION_KEY = 'introVideoDidShow210720192020';

export const VideoOverlay: React.FC = () => {
  const [isVisible, setVisibility] = React.useState(false);
  const [isActive, setIsActive] = React.useState(localStorage.getItem(VIDEO_SESSION_KEY) !== 'yes');

  const hideVideo = () => {
    localStorage.setItem(VIDEO_SESSION_KEY, 'yes');
    setVisibility(false);
    setTimeout(() => {
      setIsActive(false);
    }, 1000);
  };

  const showVideo = () => {
    setVisibility(true);
  };

  React.useEffect(() => {
    if (isActive) {
      showVideo();
    }
  }, [isActive]);

  return !isActive ? null : (
    <div
      className={cx('video-overlay', {
        'video-overlay--visible': isVisible
      })}
    >
      <div className="video-box">
        <video src="/assets/rbi-video-2.0.mp4" controls onEnded={hideVideo}>
          <source src="/assets/rbi-video-2.0.mp4" type="video/mp4" />
        </video>
        <div className="close-video" onClick={hideVideo} />
      </div>
    </div>
  );
};
