import React from 'react';

import './LandingFooter.scss';

export const LandingFooter: React.FunctionComponent = () => {
  return (
    <>
      <div className="landing-footer__footer text-sm mt-6">
        In case of need please contact our support team at&nbsp;
        <a href="mailto:support@rbiprocurement.academy">support@rbiprocurement.academy</a>.
      </div>
    </>
  );
};
