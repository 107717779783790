import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SectionHeader } from '../../components/SectionHeader';
import { Score } from '../../components/Score';
import './EmployeeTestsPage.scss';
import { ManagerStore } from '../../stores/ManagerStore';
import { TestResultStore } from '../../stores/TestResultStore';
import { inject, observer } from 'mobx-react';
import { EmptyMessage } from '../../components/EmptyMessage';
import { TabLayout } from '../../layouts/TabLayout';
import { TestRow } from '../../components/TestRow';

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  managerStore?: ManagerStore;
  testResultStore?: TestResultStore;
}

@inject('managerStore')
@inject('testResultStore')
@observer
export class EmployeeTestsPage extends React.Component<Props> {
  navigateFromRow(id: string) {
    this.props.history.push('/development-guides/' + id + '/employee/' + this.props.match.params.id);
  }

  componentDidMount() {
    this.props.managerStore!.fetchResults();
    if (!this.props.managerStore!.isManager.get()) {
      this.props.history.push('/welcome');
    }
  }

  state = {
    isDropdownVisible: false
  };

  getScoreForTest = (testId: string) => {
    const functional = this.props.testResultStore!.getFunctionalTestResults(testId, this.props.match.params.id)!.get();
    const behavioral = this.props.testResultStore!.getBehavioralTestResults(testId, this.props.match.params.id)!.get();
    const results = this.props.testResultStore!.sumTests(behavioral.concat(functional)).get();
    return (
      <div className="testing__score">
        {Score(results.points, results.max, results.percentage, 'Overall results', true)}
      </div>
    );
  };

  render() {
    const employee = this.props.managerStore!.getEmployee(String(this.props.match.params.id));
    if (!employee) {
      this.props.history.push('/welcome');
    }
    if (employee) {
      return (
        <TabLayout
          selectedIndex="4"
          isDropdownVisible={this.state.isDropdownVisible}
          callback={(isVisible) => {
            this.setState({ isDropdownVisible: isVisible });
          }}
        >
          <SectionHeader
            title={employee.name}
            backButtonTitle="TEAM"
            backButtonURL="../employees"
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
          />

          {employee.tests.length === 0 ? (
            <EmptyMessage message="This user doesn’t have any tests" />
          ) : (
            <div className="testing__list">
              {employee.tests.map(
                (res, index) =>
                  res.test.status !== 'deleted' && (
                    <TestRow
                      canProceedTest={false}
                      test={res.test}
                      title={res.title}
                      onClick={() => {
                        this.navigateFromRow(res.test.id);
                      }}
                    />
                  )
              )}
            </div>
          )}
        </TabLayout>
      );
    } else {
      return <></>;
    }
  }
}
