import React from 'react';
import cx from 'classnames';
import './CheckBox.scss';

interface Props {
  checked: boolean;
}

export const CheckBox: React.FunctionComponent<Props> = (props) => {
  return (
    <div
      className={cx('checkbox', {
        'checkbox--checked': props.checked
      })}
    >
      <div className="checkbox__icon">
        {props.checked && <img src={require('./assets/icn-check.png').default} alt="" />}
      </div>
    </div>
  );
};
