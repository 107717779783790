import React from 'react';
import { ROLE_LIST, Role, RoleDetail } from '../../const/roles';
import { RoleCompetencySelection } from '../../const/testTemplate';
import { RoleCard } from './RoleCard';

interface Props {
  onSelect: (role: Role, selectedCompetencies: RoleCompetencySelection) => void;
  onChangeIsSelectable: (isSelectable: boolean) => void;
}

export const RoleList: React.FunctionComponent<Props> = (props) => {
  const [expandedRoleDetail, setExpandedRoleDetail] = React.useState<RoleDetail>();

  return (
    <div>
      {ROLE_LIST.map((role) => (
        <RoleCard
          onChangeIsSelectable={props.onChangeIsSelectable}
          isExpanded={expandedRoleDetail === role}
          onExpanding={() => {
            expandedRoleDetail !== role ? setExpandedRoleDetail(role) : setExpandedRoleDetail(undefined);
            !role.competenciesAreConfigurable && props.onChangeIsSelectable(expandedRoleDetail !== role ? true : false);
          }}
          key={role.id}
          {...role}
          onSelect={props.onSelect}
        />
      ))}
    </div>
  );
};
