import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import resolvePathname from 'resolve-pathname';
import { Button } from '../../components/Button';
import { CheckList } from '../../components/CheckList';
import { HorizontalTestNavigation } from '../../components/HorizontalTestNavigation';
import { InAppHeader } from '../../components/InAppHeader';
import { Popup } from '../../components/Popup';
import { TestFooter } from '../../components/TestFooter';
import { CompetencyCategory } from '../../const/competencies';
import { Role } from '../../const/roles';
import { testResultStore, testTemplateStore } from '../../stores';
import { TestResultStore } from '../../stores/TestResultStore';
import { TestTemplateStore } from '../../stores/TestTemplateStore';
import './TestCompetencyTestPage.scss';

interface RouteParams {
  testId: string;
  role: Role;
  competenciesCategory: CompetencyCategory;
  step: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  testTemplateStore: TestTemplateStore;
  testResultStore: TestResultStore;
}

@inject('testTemplateStore', 'testResultStore')
@observer
export class TestCompetencyTestPage extends React.Component<Props> {
  state = {
    isExitButtonVisible: false
  };

  componentDidMount() {
    testTemplateStore.setRedirectToTest(this.props.match.params.testId);
    if (testTemplateStore.shouldOpenExitDialog) {
      testTemplateStore.setSholdOpenExitDialog(false);
      this.setState({ isExitButtonVisible: true });
    }
  }

  step = () => {
    return Number(this.props.match.params.step);
  };

  template = () => {
    return this.props.testTemplateStore.getTemplate(this.testInstance()!).get();
  };

  competenciesCategory = () => {
    return this.props.match.params.competenciesCategory;
  };

  allCompetencies = () => {
    return this.template()[this.competenciesCategory()];
  };

  navigateToNextStep = () => {
    const nextStep = this.step() + 1;
    const isLastStep = nextStep === this.allCompetencies().length;

    if (isLastStep) {
      this.navigateToNextPage();
    } else {
      this.props.history.push(resolvePathname(`${nextStep}`, this.props.match.url));
    }
  };

  testInstance = () => {
    return toJS(this.props.testResultStore.getTest(this.props.match.params.testId).get());
  };

  navigateToNextPage = () => {
    testTemplateStore.setSholdOpenExitDialog(false);
    const nextPage = {
      functional: '../../behavioral',
      behavioral: '../../../../development-guides/' + this.testInstance()?.id.toString() || ''
    }[this.props.match.params.competenciesCategory];

    if (this.props.match.params.competenciesCategory === 'behavioral' && this.testInstance()) {
      this.props.testTemplateStore.setRedirectToTest();
      this.props.testResultStore.finishTest(this.testInstance()!.id);
    }
    this.props.history.push(resolvePathname(nextPage, this.props.match.url));
  };

  render() {
    const { competenciesCategory } = this.props.match.params;
    const testInstance = toJS(this.props.testResultStore.getTest(this.props.match.params.testId).get());
    if (!testInstance) {
      this.props.history.push('/tests/lastTesting');
      return <></>;
    }
    const allCompetencies = this.template()[competenciesCategory];
    const currentCompetency = allCompetencies[this.step()];
    const allAnswers = testInstance ? testInstance!.answers[competenciesCategory] : [];
    const currentAnswers = allAnswers[this.step()] || [];

    const navigateToPrevStep = () => {
      this.step() !== 0
        ? this.props.history.push(resolvePathname(`${this.step() - 1}`, this.props.match.url))
        : this.props.history.push(
            '../../functional/step/' + (this.template()['functional'].length - 1).toString(),
            this.props.match.url
          );
    };

    const renderTitle = (params: RouteParams) => {
      const title =
        params.competenciesCategory === 'behavioral' ? 'Behavioral competencies' : 'Functional competencies';
      return <h1 className="page-role-competency-test__header__title">{title}</h1>;
    };

    return (
      <div className="page-role-competency-test">
        <InAppHeader
          isLogoRedirectDisabled={true}
          progressStepIndex={this.props.match.params.competenciesCategory === 'functional' ? 1 : 2}
          progressBarType={'testIsInProgress'}
          onButtonTapped={() => this.setState({ isExitButtonVisible: true })}
          isHomeButtonShowed={true}
          homeButtonTapped={() => {
            testTemplateStore.setRedirectToTest();
            this.props.history.push('/tests/lastTesting');
          }}
        />
        <div className="page-role-competency-test__header">
          <div className="page-role-competency-test__width-constraint">
            {renderTitle(this.props.match.params)}
            <div className="page-role-competency-test__header__desc">
              Competencies are abilities, knowledge, skills and behaviors needed to fulfil roles and achieve objectives.
              Competencies are observable and displayed in actions. They can be learned, measured and developed.
              Throughout the wide range of employee development activities – from selection to promotion, objective
              setting to training needs analysis and succession planning to assigning increased responsibilities –
              companies utilize competences as the basis for assessment and development.
            </div>
          </div>
        </div>
        <div>
          <HorizontalTestNavigation
            items={(allCompetencies as any).map((c: any) => ({
              title: c.title,
              description: c.description
            }))}
            activeItem={this.step()}
          />
        </div>
        <div className="page-role-competency-test__width-constraint">
          <div className="page-role-competency-test__width-constraint__form">
            <p className="page-role-competency-test__width-constraint__desc">
              {'Please review the detailed description of each competency and select' +
                (this.props.match.params.competenciesCategory === 'behavioral' ? ' 5 ' : ' 7 ') +
                'statements you can identify yourself most with.'}
            </p>
            <CheckList
              isSelectionDisabled={currentAnswers.length >= currentCompetency.maxSelectedQuotes}
              items={currentCompetency.quotes}
              selectedItems={currentAnswers}
              onChange={(selectedItems) =>
                this.props.testResultStore.setAnswers(
                  testInstance!.id,
                  competenciesCategory,
                  this.step(),
                  selectedItems
                )
              }
            />
          </div>
        </div>
        <TestFooter
          isActive={currentAnswers.length === currentCompetency.maxSelectedQuotes}
          withBackButton={!(this.step() === 0 && this.props.match.params.competenciesCategory === 'functional')}
          status={{
            selecedAnswersCount: currentAnswers.length,
            maxSelectedAnswers: currentCompetency.maxSelectedQuotes
          }}
          onButtonClick={() => {
            this.navigateToNextStep();
          }}
          onBackButtonClick={() => {
            navigateToPrevStep();
          }}
        />
        {this.state.isExitButtonVisible && (
          <Popup className="popup-exit" maxWidth={464}>
            <div className="popup-exit__body__container">
              <div className="popup-exit__body__container__content">
                <p className="popup-exit__body__container__content__title">
                  Are you sure that you want to cancel the test?
                </p>
                <p className="popup-exit__body__container__content__desc">
                  When you cancel the test, you can start new test from your home screen.
                </p>
                <Button
                  className="popup-exit__body__container__content__button-exit"
                  size="lg"
                  variant="close-dark-red"
                  title="YES, CANCEL THE TEST"
                  onClick={() => {
                    testTemplateStore.setRedirectToTest();
                    testResultStore.deleteActiveTestIfExists();
                    this.props.history.push('/tests/lastTesting');
                  }}
                />
                <Button
                  className="popup-exit__body__container__content__button-continue"
                  size="lg"
                  variant="framed"
                  title="NO, I WILL CONTINUE"
                  onClick={() => this.setState({ isExitButtonVisible: false })}
                />
              </div>
            </div>
          </Popup>
        )}
      </div>
    );
  }
}
