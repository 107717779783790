import { ApiResponse } from 'apisauce';
import format from 'date-fns/format';
import { Role } from '../const/roles';
import { RoleCompetencySelection } from '../const/testTemplate';
import { Http, HttpResponse } from './HttpService';

interface TestAnswers {
  functional: string[][];
  behavioral: string[][];
}

interface TestInstance {
  role: Role;
  competencies: RoleCompetencySelection;
}

export interface RemoteTestInstance extends TestInstance {
  id: string;
  date: string;
  status: 'active' | 'completed' | 'deleted';
  answers: TestAnswers;
  visitedResources: [
    {
      last_access: string;
      test_id: string;
      link_id: string;
    }
  ];
}

type InstanceResponse = Promise<ApiResponse<RemoteTestInstance>>;

type CollectionInstanceResponse = Promise<ApiResponse<RemoteTestInstance[]>>;

export const TestResultsService = {
  get: () => Http.get('/api/v1/tests') as CollectionInstanceResponse,

  create: async (data: TestInstance) => {
    const payload = {
      data: JSON.stringify({
        date: format(new Date(), 'yyyy-MM-dd HH:mm'),
        status: 'active',
        answers: {
          functional: [],
          behavioral: []
        },
        ...data
      })
    };
    return Http.post('/api/v1/tests', payload) as InstanceResponse;
  },

  update: (data: RemoteTestInstance) => {
    return Http.put('/api/v1/tests', serializeTestInstance(data)) as HttpResponse<any>;
  },

  visitResources: (testId: string, visitedResources: Array<string>) => {
    return Http.put(`/api/v1/tests/${testId}/visited-resource`, {
      visitedResources: visitedResources
    });
  }
};

function serializeTestInstance(data: any) {
  return {
    id: data.id,
    data: JSON.stringify(data)
  };
}
