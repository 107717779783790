import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Card } from '../../components/Card';
import './TestProcessingPage.scss';
import { inject } from 'mobx-react';
import { TestResultStore } from '../../stores/TestResultStore';

interface Props
  extends RouteComponentProps<{
    testId: string;
  }> {
  testResultStore: TestResultStore;
}

export const TestProcessingPagePresenter: React.FunctionComponent<Props> = (props) => {
  function effect() {
    const testId = props.match.params.testId;
    props.testResultStore.finishTest(props.match.params.testId);
    setTimeout(() => {
      props.history.replace(`/development-guides/${testId}`);
    }, 3000);
  }
  React.useEffect(effect, [props.history, props.match.params.testId, props.testResultStore]);

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <Card className="p-16 test-processing-page__card">
        <h2 className="text-center">Thank you for your participation in the assessment exercise!</h2>
      </Card>
    </div>
  );
};

export const TestProcessingPage = inject('testResultStore')(TestProcessingPagePresenter);
