import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AccountLoginPage } from '../pages/AccountLoginPage';
import { AccountRegistrationPage } from '../pages/AccountRegistrationPage';
import { ResetPasswordPage } from '../pages/PassowordResetPage';
import { PasswordRequestPage } from '../pages/PasswordRequestPage';

interface Props extends RouteComponentProps {}

export const AccountRouter: React.FunctionComponent<Props> = (props) => {
  const path = (path: string): string => `${props.match.path}${path}`;
  return (
    <Switch>
      <Route path={path('/login')} component={AccountLoginPage} />
      <Route path={path('/register')} component={AccountRegistrationPage} />
      <Route exact path={path('/password-reset')} component={PasswordRequestPage} />
      <Route path={path('/password-reset/:resetToken')} component={ResetPasswordPage} />
    </Switch>
  );
};
