import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { InAppHeader } from '../../components/InAppHeader';
import { RoleList } from '../../components/RoleList';
import { TestFooter } from '../../components/TestFooter';
import { TestResultStore } from '../../stores/TestResultStore';
import { Role } from '../../const/roles';
import './RolesDetailPage.scss';
import { RoleCompetencySelection } from '../../const/testTemplate';
import { Popup } from '../../components/Popup';

interface RouteParams {}

interface Props extends RouteComponentProps<RouteParams> {
  testResultStore: TestResultStore;
}

type State = {
  wasPopupClosed: boolean;
  isRoleSelected: boolean;
  role?: Role;
  selectedCompetencies?: RoleCompetencySelection;
};

@inject('testResultStore')
@observer
export class RolesDetailPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      wasPopupClosed: false,
      isRoleSelected: false,
      role: undefined,
      selectedCompetencies: undefined
    };
  }

  componentDidMount(): void {
    when(
      () => this.props.testResultStore.activeTest.get() !== undefined,
      () => {
        const activeTest = this.props.testResultStore.activeTest.get();
        this.props.history.push(`/tests/${activeTest!.id}/functional/step/0`);
      }
    );
  }

  render() {
    return (
      <div className="page__roles">
        <InAppHeader
          progressStepIndex={0}
          progressBarType={'testIsInProgress'}
          onButtonTapped={() => this.props.history.push('/tests/lastTesting')}
        />
        <div className="page__roles-selection">
          <div className="mb-12 page__roles-selection__header">
            <h1 className="page__roles-selection__title">Please select your current procurement role</h1>
          </div>
          <RoleList
            onChangeIsSelectable={(isSelectable) => this.setState({ isRoleSelected: isSelectable })}
            onSelect={(role, selectedCompetencies) => {
              this.setState({ role: role, selectedCompetencies: selectedCompetencies });
            }}
          />
        </div>
        {!this.state.wasPopupClosed && (
          <Popup maxWidth={1024}>
            <div className="page__roles-popup__body__image" />
            <div className="page__roles-popup__body__container">
              <div className="page__roles-popup__body__container__content">
                <Card className="agenda-card">
                  <h1 className="agenda__title">Self-assessment test</h1>
                  <div className="agenda__desc">
                    During the self-assessment you will be guided through the following steps:
                  </div>
                  <div className="agenda">
                    <div className="agenda__item">
                      <div className="agenda__bullet">1</div>
                      <div className="agenda__itemTitle">Role cluster selection</div>
                      <div className="agenda__itemDesc">First you will select your current role.</div>
                    </div>
                    <div className="agenda__item">
                      <div className="agenda__bullet">2</div>
                      <div className="agenda__itemTitle">Discovering the key competencies to focus on</div>
                      <div className="agenda__itemDesc">
                        Learn and understand the key competencies related to your role.
                      </div>
                    </div>
                    <div className="agenda__item">
                      <div className="agenda__bullet">3</div>
                      <div className="agenda__itemTitle">Assess yourself</div>
                      <div className="agenda__itemDesc">
                        You will assess your current skills within each of the relevant competencies.
                      </div>
                    </div>
                    <div className="agenda__item">
                      <div className="agenda__bullet">4</div>
                      <div className="agenda__itemTitle">Get a personalized training package proposal</div>
                      <div className="agenda__itemDesc">All you need to focus on to grow and thrive.</div>
                    </div>
                  </div>
                  <Button
                    size="lg"
                    variant="primary"
                    title="CONTINUE TO TEST"
                    withDisclosure={true}
                    onClick={() => this.setState({ wasPopupClosed: true })}
                  />
                </Card>
              </div>
            </div>
          </Popup>
        )}
        <TestFooter
          isActive={this.state.isRoleSelected}
          onButtonClick={() => {
            this.state.role &&
              this.state.selectedCompetencies &&
              this.props.testResultStore.startTest(this.state.role, this.state.selectedCompetencies);
          }}
        />
      </div>
    );
  }
}
