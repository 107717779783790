import format from 'date-fns/format';
import { action, computed, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { Role } from '../const/roles';
import { RoleCompetencySelection } from '../const/testTemplate';
import { TEST_RESULTS } from '../const/testTemplate';
import { RemoteTestInstance, TestResultsService } from '../services/TestResultsService';
import { readableRole } from '../utils/readableRole';
import { testTemplateStore } from './index';
import { managerStore } from './index';

export interface TestResult {
  points: number;
  maxPoints: number;
  minPoints: number;
  title: string;
  percentage: number;
  result: string;
}

export interface FunctionalTestResult extends TestResult {
  category: string;
  visitedResources: RemoteTestInstance['visitedResources'];
}

export class TestResultStore {
  @persist('map')
  @observable
  data: Map<string, RemoteTestInstance> = new Map();

  @observable
  activeTest = computed(() => {
    const arr = Array.from(this.data.values()).reverse();
    return arr.find((test) => test.status === 'active');
  });

  @observable
  lastCompleteTest = computed(() => {
    const arr = Array.from(this.data.values()).reverse();
    return arr.find((test) => test.status === 'completed');
  });

  sumTests = (tests: TestResult[]) =>
    computed(() => {
      const sum = tests.reduce(
        (sum, test) => {
          sum.points += test.points;
          sum.max += test.maxPoints;
          sum.min += test.minPoints;
          return sum;
        },
        {
          points: 0,
          max: 0,
          min: 0,
          percentage: 0
        }
      );
      sum.percentage = Math.round(((sum.points - sum.min) / (sum.max - sum.min)) * 100);
      return sum;
    });

  @observable
  getTest = (testId: string, employeeId?: string) =>
    computed(() => {
      return employeeId
        ? managerStore.findTestFromEmployeeWith(employeeId!, testId)
        : (this.data.get(testId) as RemoteTestInstance);
    });

  @observable
  getTitleForTestWith = (testId: string) =>
    computed(() => {
      const test = this.getTest(testId);
      return test.get() ? readableRole(test.get()!.role) : '';
    });

  setFunctionalTestScore(test: TestResult) {
    let x = test.points;
    switch (true) {
      case x >= 7 && x <= 11:
        test.result = TEST_RESULTS.FOUNDATION;
        break;
      case x >= 12 && x <= 16:
        test.result = TEST_RESULTS.INTERMEDIATE;
        break;
      case x >= 17 && x <= 21:
        test.result = TEST_RESULTS.ADVANCED;
        break;
      default:
        test.result = '';
        break;
    }
    test.percentage = Math.round(((test.points - 7) / 14) * 100);
  }

  getFunctionalTestResults(testId: string, employeeId?: string) {
    var test = this.getTest(testId, employeeId).get();
    if (test === undefined) {
      return null;
    }
    var testUnwrapped = test!;

    return computed(() => {
      const template = testTemplateStore.getTemplate(testUnwrapped).get();
      const result: Array<FunctionalTestResult> = [];
      template.functional.forEach((step, stepIndex) => {
        const answers = testUnwrapped.answers.functional[stepIndex];
        const subresult = step.quotes.reduce(
          (sum, quote) => {
            if (answers.includes(quote.id)) {
              sum.points += quote.weight;
              sum.maxPoints += 3;
              sum.minPoints += 1;
            }
            sum.category = step.id;
            return sum;
          },
          {
            points: 0,
            maxPoints: 0,
            minPoints: 0,
            title: step.title,
            category: '',
            visitedResources: testUnwrapped.visitedResources,
            percentage: 0,
            result: ''
          }
        );
        this.setFunctionalTestScore(subresult);
        result.push(subresult);
      });
      return result;
    });
  }

  setBehavioralTestScore(test: TestResult) {
    let x = test.points;
    switch (true) {
      case x >= 5 && x <= 8:
        test.result = TEST_RESULTS.FOUNDATION;
        break;
      case x >= 9 && x <= 12:
        test.result = TEST_RESULTS.INTERMEDIATE;
        break;
      case x >= 13 && x <= 15:
        test.result = TEST_RESULTS.ADVANCED;
        break;
      default:
        test.result = '';
        break;
    }
    test.percentage = ((test.points - 5) / 10) * 100;
  }

  getBehavioralTestResults(testId: string, employeeId?: string) {
    var test = this.getTest(testId, employeeId).get();
    if (test === undefined) {
      return null;
    }
    var testUnwrapped = test!;

    return computed(() => {
      const template = testTemplateStore.getTemplate(testUnwrapped).get();
      const result: Array<TestResult> = [];
      template.behavioral.forEach((step, stepIndex) => {
        const answers = testUnwrapped.answers.behavioral[stepIndex];
        const subresult = step.quotes.reduce(
          (sum, quote) => {
            if (answers.includes(quote.id)) {
              sum.points += quote.weight;
              sum.maxPoints += 3;
              sum.minPoints += 1;
            }
            return sum;
          },
          {
            points: 0,
            maxPoints: 0,
            minPoints: 0,
            title: step.title,
            percentage: 0,
            result: ''
          }
        );
        this.setBehavioralTestScore(subresult);
        result.push(subresult);
      });
      return result;
    });
  }
  @observable
  completedTestsAsList = computed(() => {
    return Array.from(this.data.values())
      .filter((test) => test.status === 'completed')
      .map((test) => ({
        id: test.id,
        title: `${readableRole(test.role)} (${format(
          Date.parse(test.date) || new Date(test.date.replace(/-/g, '/')),
          'dd.MM.yyyy HH:mm'
        )})`
      }))
      .sort((a, b) => Number(a.id) - Number(b.id));
  });

  @observable
  allTestsAsList = computed(() => {
    return Array.from(this.data.values())
      .filter((test) => test.status === 'completed' || test.status === 'active')
      .map((test) => ({
        status: test.status,
        id: test.id,
        title: `${readableRole(test.role)}`,
        date: `${format(Date.parse(test.date) || new Date(test.date.replace(/-/g, '/')), 'MMM dd, yyyy HH:mm')}`
      }))
      .sort((a, b) => Number(b.id) - Number(a.id));
  });

  @action
  reset = () => {
    this.data.clear();
  };

  @action
  setAnswers = (testId: string, testCategory: 'functional' | 'behavioral', step: number, answers: string[]) => {
    try {
      const testInstance = this.data.get(testId.toString()) as RemoteTestInstance;
      testInstance.answers[testCategory][step] = answers;
      this.data.set(testInstance.id, testInstance);
      TestResultsService.update(testInstance);
    } catch {}
  };

  @action
  fetchResults = async () => {
    const response = await TestResultsService.get();
    runInAction(() => {
      this.reset();
      this.data.clear();
      if (response.ok) {
        response.data.forEach((test: any) => this.data.set(test.id, test));
      }
    });
  };

  @action
  startTest = async (role: Role, competencies: RoleCompetencySelection) => {
    const response = await TestResultsService.create({
      role: role,
      competencies: competencies
    });
    if (response.ok) {
      this.fetchResults();
    }
  };

  @action
  finishTest = (testId: string) => {
    const test = this.data.get(testId) as RemoteTestInstance;
    test.status = 'completed';
    this.data.set(test.id, test);
    TestResultsService.update(test);
  };

  @action
  deleteActiveTestIfExists = async () => {
    const test = this.activeTest.get();
    if (test) {
      test.status = 'deleted';
      TestResultsService.update(test);
    }
  };
}
