import { arrayShuffle } from '../utils/arrayShuffle';

export type CompetencyCategory = 'functional' | 'behavioral';

export type FunctionalRoleCompetencyID =
  | 'p01'
  | 'p02'
  | 'p03'
  | 'p04'
  | 'p05'
  | 'p06'
  | 'p07'
  | 'p08'
  | 'p09'
  | 'p10'
  | 'p11'
  | 'p12';

export type BehavioralRoleCompetencyID =
  | 'self-management'
  | 'delivering-results'
  | 'building-relationships'
  | 'personal-impact-and-presentation'
  | 'entrepreneurial-initiative'
  | 'leadership'
  | 'developing-others';

interface CompetencyQuote {
  id: string;
  value: string;
  weight: number;
}

export interface RoleCompetency<T> {
  id: T;
  title: string;
  description: string;
  maxSelectedQuotes: number;
  quotes: CompetencyQuote[];
}

export const FUN_P01: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p01',
  title: 'Procurement Leadership, Governance and Functional Design',
  description:
    'Ensuring the effectiveness and continuing development of the Procurement role inside and outside of the function together with the development of procurement policy and supporting processes across the entire requisition-to-pay process. Establishes the governance framework and defines the role of Procurement, securing and maintaining an effective mandate. The establishment and modification of functional processes to address supply base management and control, demand management, sourcing, functional measurement, knowledge management, audit and supplier management. Establishes effective organizational structures with clear roles and responsibilities and segregation across the requisition-to-pay process.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: 'a3809328-7d97-4fe5-baea-8f6ff56fcb2a',
      weight: 3,
      value:
        'Sets overall people development strategy and leads efforts to establish effective people management and development mechanisms'
    },
    {
      id: '0ad9ec6b-d551-4827-ab39-73ee4c814c61',
      weight: 3,
      value:
        'Interprets the legal and regulatory issues that impact procurement and incorporates them into policy and ways of working'
    },
    {
      id: 'e5047112-5dbb-4446-9ffa-cb1ba7e92306',
      weight: 3,
      value: 'Champions the application of advanced and innovative processes, technologies, techniques and strategies'
    },
    {
      id: 'c7c6e760-bc20-4e06-9f63-83364efd7bd8',
      weight: 3,
      value:
        'Frequently sought after for definitive advice on procurement process across the function and organisation at senior levels'
    },
    {
      id: '7f6fcbf9-325d-46d9-9f3f-a7e5750daa9e',
      weight: 3,
      value:
        'Represents the Company to the Board, Government or Trade Organisations with regards to procurement/supply chain management'
    },
    //  2b
    {
      id: 'faf20d50-202b-404f-92a8-518045d84122',
      weight: 2,
      value: 'Proactively identifies and recommends improvements to policy, processes and standards'
    },
    {
      id: '9a19804f-a487-4bbc-a85d-b6aec708e2e5',
      weight: 2,
      value:
        'Deals confidently with senior stakeholders and other users to deal with significant issues and resolve matters'
    },
    {
      id: 'b5d61d6d-8460-4d36-a690-94c9d9e5f4c8',
      weight: 2,
      value: 'Coaches staff within the function, users and suppliers so that maximum value is derived by all processes'
    },
    {
      id: '6ab41372-5b5b-460e-ab88-41173a37019a',
      weight: 2,
      value: 'Sets target, priorities and objectives for own area of responsibility and team'
    },
    {
      id: '13c1a50f-daf5-44aa-b7d1-30a474cc209c',
      weight: 2,
      value: 'Able to identify improvements to the organisation to drive effectiveness'
    },
    //  1b
    {
      id: '75df90cc-bddf-4909-a332-e22054ee12c3',
      weight: 1,
      value: 'Competently uses and is knowledgeable about the organisation’s procurement policy and key processes'
    },
    {
      id: '4b70225a-d8b0-4203-9a99-ea9a72e023b0',
      weight: 1,
      value:
        'Understands the organisational structure and their role within it clearly, recognises procurement role/ responsibilities within and outside of function'
    },
    {
      id: '71ac88b9-401e-4d4b-8f8f-29996817a3d5',
      weight: 1,
      value: 'Deals confidently with queries from users, stakeholders on policy and process issues'
    },
    {
      id: '1e562556-6faa-49a6-9f6b-8b986e8c5a2d',
      weight: 1,
      value:
        'Contributes to the development and standardisation of processes as part of a multi-disciplinary team; applies processes and standards consistently'
    },
    {
      id: '17ef3cb5-dceb-4c81-9988-7dbc09369152',
      weight: 1,
      value:
        'Participates in reporting of functional measurement, audit of the function, supplier audits and provides advice to users and stakeholders on transactional methods, supplier set-up and controls, authority levels, policy etc.'
    }
  ])
};

export const FUN_P02: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p02',
  title: 'Supply Base and Category Analysis',
  description:
    'Able to effectively evaluate supply markets to understand the organisation’s position in a supply market and have a deterministic influence on the market to achieve business advantage. Able to research supply markets to source new suppliers. Uses strategic procurement such as Kraljic/Supply Positioning, market segmentation analysis, PESTLE and Porters 5 Forces to understand the markets in which the company operates. Able to scope and shape categories to align with supply markets, increase the organisation’s influence within them and ensure an appropriate fit between activities undertaken by the company and those delivered by the supply market. Use supply chain and value chain analysis to imform thinking. The individual should be able to map and extract market trends and history. Capable of performing a pareto analysis or ABC analysis of the supplier (s). Can perform the task of supplier/customer preferencing. Able to carry out market analysis to allow informed decision making to be made. Understands what differentiate the key and/or most preferred suppliers in a sector to those of its competitor set.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '0ce1d88b-12a8-5b05-8c23-71428887befa',
      weight: 3,
      value:
        'Viewed as an expert in analyzing supply markets and categories and is consulted on process. Sought for advice on others’ work.'
    },
    {
      id: '47c72611-f26a-567b-9223-11e67e16164a',
      weight: 3,
      value: 'Leads large, complex, global categories or projects at the corporate level'
    },
    {
      id: '1fe03c35-9d96-545a-848f-224a28ffc616',
      weight: 3,
      value: 'Gathers and applies leading edge thinking and practice across the company'
    },
    {
      id: 'fae38be7-b07a-5aa3-87d3-0752d3d18e22',
      weight: 3,
      value: 'Interprets markets and their dynamics insightfully and effectively to outperform competitors'
    },
    {
      id: '2c3834a7-bdba-54dd-a02e-b5075c30f6e2',
      weight: 3,
      value:
        'Shares knowledge of tools and techniques/templates proactively and encourages development of best practice'
    },
    // 2b
    {
      id: '85602f8d-9830-5536-8ce3-0f3c2ee739ac',
      weight: 2,
      value:
        'Leads multi-discipline teams in complex categories, consulting and engaging external analysts, Finance and other internal stakeholders as required'
    },
    {
      id: 'ad44496b-60b9-5162-a2c6-a1e19174cf2f',
      weight: 2,
      value:
        'Recognizes the potential positive and negative consequences of actions in specific markets resulting from analysis'
    },
    {
      id: '33cfee44-149a-5d05-829d-4b6ca2e39f61',
      weight: 2,
      value: 'Thoroughly researches the key risks, people, market, supplier and timing issues for categories'
    },
    {
      id: 'f86b6983-3d8f-59c5-825b-987e612d5591',
      weight: 2,
      value: 'Sources new suppliers proactively'
    },
    {
      id: '7810781a-57a9-539d-8edd-0a1fb7c9ba82',
      weight: 2,
      value: 'Recognizes and uses a wide range of resources to assess supply markets'
    },
    // 1b
    {
      id: 'ea2908ae-0d3b-5581-a028-3804d4e32e53',
      weight: 1,
      value:
        'Engaged in category/supply market analysis for straightforward areas of spend (local BUs, single sites, countries) with some direction and oversight'
    },
    {
      id: '84d4a428-e4f1-5ea7-8899-2b8f4cbb847e',
      weight: 1,
      value:
        'Reviews and selects tools and templates developed to suit the application needed; possesses a working knowledge of market analysis tools'
    },
    {
      id: '7e5ab966-6c18-5df2-b860-de99c2c9d656',
      weight: 1,
      value: 'Confidently and effectively analyses straightforward supply markets'
    },
    {
      id: 'ef231523-99cd-5ec9-ac0b-5f12a2044a31',
      weight: 1,
      value: 'Recognizes the issues and options and provides information and advice for others'
    },
    {
      id: 'a84a1d3c-9e86-56c8-8c65-33560f763dc3',
      weight: 1,
      value:
        'Able to use a variety of information sources to access data. Uses the internet competently to access supplier and supply market information'
    }
  ])
};

export const FUN_P03: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p03',
  title: 'Risk Management and Analysis',
  description:
    'Identifying and managing commercial, contractual, operational, financial, reputational, ethical and supply chain risks emanating from procurement activity and supply base arrangements to ensure that undesirable consequences of the risks are mitigated, eliminated or managed. As well as market knowledge and commercial acumen, an understanding of the broader business environment and the HSE Management Expectations is particularly valuable. Elements of Risk Management: HSE Assessment/ Financial Assessment/ Contractual risk/Market Analysis/Operational risk/Business Continuity/ Strategic Impact on Business/Company Brand Values (i.e. Innovative, Performance, Green, Progressive) /Ethical Conduct.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '54c14cad-208d-5f96-9cf3-6779dab8a770',
      weight: 3,
      value:
        'Develops a risk strategy and a consistent, sustainable approach to the identification and assessment of risks and opportunities, fully integrated and aligned with the company’s corporate risk assessment framework'
    },
    {
      id: '6bc98148-eca6-5a70-83a0-1bf7df27dc2d',
      weight: 3,
      value:
        'Acts as a champion for Risk and develops a culture of Risk Management and Mitigation balanced with realisation of future opportunities'
    },
    {
      id: '24c05ab2-1f23-5d2d-9063-90a24a2cfd76',
      weight: 3,
      value:
        'Coordinates and leads corporate/business-wide approach to the management of key risk areas such as major supplier failures'
    },
    {
      id: '7856f382-2aaf-5093-b94c-731bb3391c5a',
      weight: 3,
      value:
        'Provides consulting to the organisation on techniques and actions to manage risks for high value complex projects and relationships'
    },
    {
      id: '7cd7448b-b549-56c7-893c-ba7990bdb50d',
      weight: 3,
      value:
        'Manages and advises on key risks for contracts which effect the whole enterprise – complex arrangements such as JVs, mergers etc.'
    },
    // 2b
    {
      id: '9931a475-4dbb-5c9e-9206-dfe298266dc6',
      weight: 2,
      value:
        'Leads development and implementation of risk management techniques at regional or business unit level to identify and prioritise risks to business delivery, and to develop and manage action plans to manage the risk'
    },
    {
      id: '013e963c-86e3-51a7-b8da-3592deaa2405',
      weight: 2,
      value:
        'Coaches others within the team on how to conduct risk assessments using established processes and frameworks'
    },
    {
      id: 'e1e09989-27bb-5643-852e-694e8fd5b7ab',
      weight: 2,
      value: 'Seeks out best practice and proactively shares across the company'
    },
    {
      id: '5f7d1ae5-7db0-51af-adca-bf000bbeb3e1',
      weight: 2,
      value:
        'Manages and advises on key risks at a contract level for larger more complex arrangements, involving and facilitating appropriate input from legal, HSE, technical and commercial specialists'
    },
    {
      id: 'd7784292-badd-52a0-bb96-abac6b3ed301',
      weight: 2,
      value: 'Implements appropriate measures to identify risks on an on-going basis'
    },
    // 1b
    {
      id: 'e185ce11-68a0-5b08-b915-4d5bfc3d266c',
      weight: 1,
      value:
        'Advises on key risks at a contract level for straightforward arrangements and may seek input from other functions where appropriate under guidance'
    },
    {
      id: '92a880a9-fa6a-54a1-a32c-e9094cee6e0e',
      weight: 1,
      value: 'Utilises standard contractual provisions to mitigate business risk'
    },
    {
      id: '646f2d6c-3bf0-53ea-a716-59ac18aae6f7',
      weight: 1,
      value:
        'Applies simple risk management techniques to routine contracts, that is, understanding level of risk, probability and manageability'
    },
    {
      id: 'e45631de-0c1d-5fc4-9b30-4b1b87a82c37',
      weight: 1,
      value:
        'Identifies commercial risks and through collaboration with Quality and other interested parties channels risks through the correct procedures'
    },
    {
      id: '0d572669-6922-595b-9a8e-58a8ec45ec63',
      weight: 1,
      value: 'Uses modelling techniques and tools to identify financial risks and their commercial impact'
    }
  ])
};

export const FUN_P04: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p04',
  title: 'Strategy Formulation and Supply Market Engagement',
  description:
    'The formulation of category and sourcing strategy through the generation and evaluation of options. Uses supply market and category analysis togaether with a deep understanding of needs and requirements to create ambitious and wide ranging strategies. The engagement of suppliers & supply markets in a manner which is consistent with market sector strategies. Recognises the need to engage with suppliers and supply markets positively and considers requirement for effective communication, conditioning, high quality documentation and feedback. Recognises that effective engagement and feedback regardless of outcome is an opportunity to motivate suppliers and supply markets positively towards the company.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: 'bd02fde6-8187-5c87-929b-36a129d39891',
      weight: 3,
      value:
        'Leads supplier event days and other communications forums aimed at developing and engaging with the supply base to improve performance'
    },
    {
      id: '240348c7-294b-54ad-b61b-468047aae5ca',
      weight: 3,
      value: 'Establishes targets and controls for the overall management of the supply base'
    },
    {
      id: 'b59dddbf-158d-526e-9a7b-1637207bfa32',
      weight: 3,
      value: 'Ensures application of industry best practice and provides assurance to the process'
    },
    {
      id: 'aa68c1b6-88f5-5e89-a29e-b58377243565',
      weight: 3,
      value: 'Demonstrates expert knowledge of supply base analysis, supplier evaluation and selection techniques'
    },
    {
      id: '6b499964-b4d3-5b2b-ac93-aa445bfb8b00',
      weight: 3,
      value:
        'Sets overall strategy and framework for how different go-to-market techniques are to be established and used throughout the business'
    },
    // 2b
    {
      id: '27cda2bf-9c9e-5d0f-a884-99f3090d369d',
      weight: 2,
      value:
        'Coaches members of own team on use of RFx, e-auction and other go-to-market processes and is competent in their use'
    },
    {
      id: '6b6c12a2-10ae-5524-a58f-1c9e5871c6bc',
      weight: 2,
      value:
        'Able to present and communicate effectively with suppliers and stakeholders; offers high quality, comprehensive feedback to unsuccessful suppliers'
    },
    {
      id: 'b782329e-3a08-5b40-bf71-df4e46ee263f',
      weight: 2,
      value:
        'Considers short, medium and long term actions within the context of developing an overarching category strategy'
    },
    {
      id: 'ca02d803-86c3-51d8-94ab-e3627fbacb81',
      weight: 2,
      value:
        'Sets the framework for differentiated contract strategies and evaluation framework, specific to individual market requirements'
    },
    {
      id: '020fc58c-4c8a-5d2a-a726-6fde1a71ac25',
      weight: 2,
      value:
        'Identifies and considers specific business unit/divisional requirements that have impact on the sourcing strategy'
    },
    // 1b
    {
      id: '726876e5-031b-5c99-b156-aa7c5460bb98',
      weight: 1,
      value: 'Recognises the need for strategy to consider activities beyond a simple go-to-market approach'
    },
    {
      id: '97ca071a-f2fa-5973-981e-dcd6b428616b',
      weight: 1,
      value: 'Communicates effectively with the supply base and offers high quality comprehensive feedback'
    },
    {
      id: '14325206-8558-5cfe-8efc-26c1214e1ab8',
      weight: 1,
      value:
        'Develops and leads standard market analysis processes for medium sized contracts and obtains qualification and award criteria'
    },
    {
      id: '96dd7063-f6c2-5a9d-8666-6b20600b9ba0',
      weight: 1,
      value:
        'Demonstrates ability to conduct supply base analysis activities, evaluate risk and determine if mitigation plans are required'
    },
    {
      id: 'f5771e10-f030-5a34-af6a-121e93fe76f0',
      weight: 1,
      value: 'Consults expert practitioners regarding more complex supplier base issues'
    }
  ])
};

export const FUN_P05: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p05',
  title: 'Supplier Appraisal, Evaluation and Audit',
  description:
    'Able to objectively appraise a supplier’s capability and competence within the context of identified business needs and requirements and also against knowledge of best practice within a given supply market. Able to use appraisal techniques for the purpose of pre-qualification, tender evaluation and supplier selection or as thre basis of continuous improvement, supplier development or supplier performance management. Able to identify a range of criteria according to the need and requirement. Recognises and uses a variety of evaluation techniques such as Kepner Tregoe and similar methods requiring the use of scoring, weighting, qualitative and quantitative assessments. Uses assessment to allow effective and objective comparisons to be made across a range of stakeholders. Understands what differentiate the key and/or most preferred suppliers in a sector to those of its competitor set. Able to work with accountable line management and others (including legal, financial, technical integrity and HSE specialists) to establish a comprehensive assessment of the suppliers under consideration, including compliance with company policies,culture/ethics, financial analysis, HSE, performance and service, quality, innovation, account management, capacity and capability, references and experience etc. Also able to conduct appraisals and audits with existing suppliers to in conjunction with Quality, Finance and other parties to confirm performance to contract or conduct checks/audits required by the contract.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '272033db-9873-407b-89b8-815e98f101b6',
      weight: 3,
      value:
        'Responsible for leading the establishment of evaluation and appraisal frameworks to ensure objectivity and reflecting the values contained within moral and ethical company codes'
    },
    {
      id: 'a83a62cf-43fd-4d40-a75e-9784d5de78ad',
      weight: 3,
      value: 'Demonstrates expert knowledge of supplier evaluation and selection techniques'
    },
    {
      id: 'b0ede537-6aec-41ed-a6ee-b10d063ee863',
      weight: 3,
      value:
        'Brings an external perspective to the company based on a deep understanding of practices from other industry sectors'
    },
    {
      id: 'e2737ac0-8d66-4bea-ae45-e1d66caa0ddb',
      weight: 3,
      value: 'Viewed as an expert within the company and is consulted on process and methods'
    },
    {
      id: 'd1d2a855-56b8-4dbb-990c-3eeae2d3ab82',
      weight: 3,
      value:
        'Shares knowledge of tools and techniques/templates proactively and encourages development of best practice evaluation techniques'
    },
    //  2b
    {
      id: 'e485e49b-0081-4948-b6d1-600c65eba863',
      weight: 2,
      value:
        'Leads and coordinates a full commercial, technical and contractual evaluation of a specific market, including supply chain cost structure evaluation'
    },
    {
      id: '554a06f7-c826-45c7-b463-235b3e17f28e',
      weight: 2,
      value:
        'Develops and implements innovations to supplier evaluation and selection processes across the organization'
    },
    {
      id: '6b415995-8be7-4a8c-9b01-6d48ba5f878e',
      weight: 2,
      value: 'Briefs and debriefs third parties professionally for complex, or cross-functional awards'
    },
    {
      id: '757eeb8b-4e7f-4574-a8fe-c73359b35268',
      weight: 2,
      value: 'Manages large value, complex markets, conducting analysis, pre-qualification and selection processes'
    },
    {
      id: '1ba7ab48-0963-4949-872b-e47c65c96c53',
      weight: 2,
      value: 'Leads supplier visits as part of broader cross-functional team'
    },
    //  1b
    {
      id: '681da560-5b14-46c9-8a8a-22499ed99db6',
      weight: 1,
      value: 'Leads evaluation and appraisal for straightforward areas of spend or supports more complex evaluations'
    },
    {
      id: '3f1633a8-f6ec-4865-b238-6acc3e1c08d0',
      weight: 1,
      value: 'Is aware of different evaluation methods and advantages/disadvantages'
    },
    {
      id: '2f4b5857-895d-4aa1-97d9-5cf42fc3bc74',
      weight: 1,
      value:
        'Recognises the issues and options created by the evaluation and provides information and advice for the negotiating team'
    },
    {
      id: 'f49f7b29-f680-44d6-9b45-d755f6f7ff57',
      weight: 1,
      value: 'Assists in developing qualification and assessment criteria for internal teams and their specific markets'
    },
    {
      id: '3827b062-d672-47ea-a2f0-af1b4f68b969',
      weight: 1,
      value:
        'Conducts meetings and visits to suppliers to undertake appraisal and audit into a specific area under direction'
    }
  ])
};

export const FUN_P06: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p06',
  title: 'Contracting and Legal Aspects',
  description:
    'Drafting, negotiating, and agreeing clear, concise, and complete contractual documentation, including “call off order contracts” which identifies roles and responsibilities and makes provision for all aspects of the agreed strategy with reference to process, steps and templates. An understanding of contract law, the business application and its critical success factors, negotiating skills, and the ability to access and apply specialist legal and technical advice, are all required, in order to protect Company’s commercial position in such areas as liabilities, indemnities, insurances and warranties. Building flexibility into contracts such that business change and associated requirements can be reflected over the life of the contract. Elements of Contract Execution: Clearing & Shipping, Supplier Performance Metrics/ Contract Amendment/Risk Assessment/ Risk Mitigation/ Exit Strategies/ Terms & Conditions/Incentives & Sanctions, Performance bonds etc.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '974b6b7e-5f31-4771-aff7-0e9530263902',
      weight: 3,
      value: 'Recognized as providing expert guidance on the contractual process and documentation'
    },
    {
      id: 'c8541485-9447-4520-a8b1-78a6b44bc4b8',
      weight: 3,
      value:
        'Provides expertise on contracting approaches and clauses for complex, high-risk contracts, major project procurement programmes or complex outsourcing arrangements.'
    },
    {
      id: 'c41f18b5-8910-4933-8749-e928a92fc567',
      weight: 3,
      value:
        'Works with legal resources both internally and externally to develop boilerplate contracts for key categories/areas of expenditure'
    },
    {
      id: 'fb8636e8-81cb-4ffa-b0b9-e5eb86221682',
      weight: 3,
      value: 'Advises on key supplier targets and principles for risk management and exit strategies'
    },
    {
      id: '4817ba2a-f796-4a20-938c-14b9e4823a2a',
      weight: 3,
      value:
        'Determines and allocates required resources for management of key contracts and management frameworks at functional level'
    },
    //  2b
    {
      id: '523a23b0-81db-4578-aa3c-94bf1d9eb096',
      weight: 2,
      value:
        'Understands and can modify key contract clauses covering remuneration, insurance, indemnity, termination, liabilities, IPR, confidentiality etc. without changing the construct of the agreement. Seeks second opinion from Legal resources where changes to wording are significant'
    },
    {
      id: '5b50ebe7-c8a0-4f17-b606-e0ec1c8b3b84',
      weight: 2,
      value:
        'Possesses an effective understanding of contract law in their jurisdiction of operation and jurisdictions under which they are contracting'
    },
    {
      id: 'e0a9e0fb-220d-4614-8e2d-5ae5e0ffb304',
      weight: 2,
      value: 'Determines and allocates required resources for management of key contracts within own team'
    },
    {
      id: 'a6d3e0e6-ec04-4957-84c3-990051bda36e',
      weight: 2,
      value: 'Contributes to the development of boilerplate contracts'
    },
    {
      id: '4fc420a5-5613-4d1f-a26a-4d6c62dd6b62',
      weight: 2,
      value: 'Closes out significant contracts and/or handles changes to agreements whilst in operation'
    },
    //  1b
    {
      id: '9cdab081-1b17-48d4-b6c0-d0a7c135a70a',
      weight: 1,
      value:
        'Possesses a working knowledge of contract law in their jurisdiction of operation but often seeks guidance on specific issues or a second opinion'
    },
    {
      id: '09cd6c77-671c-4180-8971-a8165d6c7a61',
      weight: 1,
      value:
        'Utilizes standard contractual templates and documentation for routine, low risk contracts. Ensures user needs are fully incorporated'
    },
    {
      id: '7a687dba-e49b-425a-9f45-663fcab1b74f',
      weight: 1,
      value:
        'Able to conduct basic risk assessments and take action to reduce / minimize company’s legal and commercial exposure or vulnerability for familiar areas of spend'
    },
    {
      id: 'f492d63b-8315-484c-86c1-ff01c77d7ae8',
      weight: 1,
      value: 'Supports users in the assessment and resolution of minor changes or claims. Prepares contract amendments'
    },
    {
      id: 'dab6f282-c4d8-4a1b-8a3d-37decbcb011a',
      weight: 1,
      value: 'Able to close out simple contract successfully making use of checklists'
    }
  ])
};

export const FUN_P07: RoleCompetency<FunctionalRoleCompetencyID> = {
  title: 'Pricing, Cost and Financial Analysis',
  description:
    'Able to undertake a meaningful financial appraisal and assessment of risk within a market and utilising this information in planning, control and decision making. An ability to understand costing methods and cost make-up and analyse financial information. Understands the difference between price and cost and is able to identify pricing strategies used by suppliers or deployed within supply markets. Understands how to break down products and services into their constituent components and build accurate cost models, identifying cost drivers and levers and recognising the impact of changes to scope, specification or service levels on cost. Understands and calculates total cost of acquisition and ownership where appropriate and uses information in decision making. Able to calculate and interpret financial ratios within financial statements and assess accounts to make meaningful conclusions. Able to use economic, financial and cost analysis information to provide insight into sourcing decisions and use in negotiations. Monitors and ensures compliance with company financial policies and statutory regulations. Monitors suppliers’ financial trading situation to manage, mitigate and avoid risk.',
  id: 'p07',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '10e1af49-dfda-5160-ac55-af30c3cd2e74',
      weight: 3,
      value:
        'Provides valuable insight and recommendations to the Executive and Senior stakeholders based on the interpretation of financial information'
    },
    {
      id: '6d242865-031d-5294-b3a6-b0528d489c0e',
      weight: 3,
      value:
        'Leads the development, agreement and implementation of savings measures and benefits recording in conjunction with Finance and others'
    },
    {
      id: 'c2aed307-0ad5-5eda-947d-b832e77daf54',
      weight: 3,
      value: 'Anticipates financial loopholes in company practice and implements preventative measures'
    },
    {
      id: '3fbb2a7d-2445-57e9-87ef-4a5614a8c475',
      weight: 3,
      value:
        'Sets up and maintains sound accounting policies and practices including payment terms, supply chain financing etc.'
    },
    {
      id: '23b06f67-cc9c-58d8-9dcd-8253f0a06ee8',
      weight: 3,
      value: 'Demonstrates an ability to define and use financial information in planning, control and decision making'
    },
    // 2b
    {
      id: 'fd6facb0-3d42-5206-b26f-29959bedcc58',
      weight: 2,
      value:
        'Demonstrates an ability to undertake a meaningful financial appraisal of a supply base/supplier and assessment of risk'
    },
    {
      id: '4109d880-1bc4-5639-a822-033a9dbfd947',
      weight: 2,
      value:
        'Understands the cost drivers/levers which exist within a category/product and can make adjustments to optimise specifications and service levels'
    },
    {
      id: '5d060a4c-373d-5c8e-b65c-568efd3626e7',
      weight: 2,
      value: 'Anticipates market price movements and is able to take action in advance of them'
    },
    {
      id: '14fe30b5-e27e-537e-af8a-81fef1ca242c',
      weight: 2,
      value: 'Negotiates price movements from the basis of a deep understanding of cost and movements in costs'
    },
    {
      id: '43e5f4cf-b59f-5b4c-9245-f5d4f844d827',
      weight: 2,
      value:
        'Demonstrates an ability to assess and analyse financial information in support of developing a sourcing/ category strategy'
    },
    // 1b
    {
      id: 'f49ddbc9-db29-5bd9-a273-2390406d0d8f',
      weight: 1,
      value:
        'Demonstrates an ability to understand the difference between price and cost and how cost can be influenced within a supply chain'
    },
    {
      id: '9d842faf-2b14-58f5-9f76-4f5d470b93b7',
      weight: 1,
      value: 'Consults expert practitioners regarding more complex financial matters'
    },
    {
      id: '05777404-3b65-55bd-aba3-d2f4c5a484d5',
      weight: 1,
      value:
        'Understands the basic principles of accounting and can perform an assessment of accounts using simple ratios'
    },
    {
      id: '50a00b59-fed4-5f53-8801-3b044f0acbd6',
      weight: 1,
      value: 'Checks that supplier payment terms are observed'
    },
    {
      id: '2cd4a974-0200-5fe2-9ecf-2f6f118e923f',
      weight: 1,
      value: 'Monitors spend by category/supplier and provides alerts if outside established limits or anomalies exist'
    }
  ])
};

export const FUN_P08: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p08',
  title: 'Negotiation',
  description:
    'Identifies needs, objectives and areas where improvement is required to agreements/proposals and is able to move others and change their position towards the individual through the use of persuasion, influencing and conditioning. Prepares and plans the negotiating strategy based on sound knowledge, research and structure. Identifies and uses sources of power creatively and is able to assess and agree a division of value and risk through a structured assessment of the variables. Develops a range of options and uses a range of tactics/ploys to achieve success. Uses a wide range of techniques to persuade and influence others depending upon the type of negotiation and with third parties or stakeholders.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '88673b20-cd29-47e7-b78a-2006132039e7',
      weight: 3,
      value: 'Viewed as a lead and experienced negotiator within the business and is consulted on process and tactics'
    },
    {
      id: '1e6dbbbf-c031-4e9a-852a-20b0c1ac6fe3',
      weight: 3,
      value:
        'Leads negotiations for large, complex, global deals at the corporate level (e.g. Outsourcing arrangements, acquisitions, JVs)'
    },
    {
      id: 'cf582697-018b-4e93-9ae1-096b8ae6aaf1',
      weight: 3,
      value:
        'Shares knowledge of negotiating proactively and encourages development of best practice in negotiating and influencing techniques'
    },
    {
      id: 'cbde4f6d-cd2e-446f-b797-8ed645d947db',
      weight: 3,
      value:
        'Influences effectively amongst the Executive and other very senior stakeholders to achieve Procurement’s vision and strategy'
    },
    {
      id: '20edd1e6-95fe-4541-8953-c08807d7e3ca',
      weight: 3,
      value: 'Is able to clearly, confidently and effectively present to large groups in high pressure environments'
    },
    //  2b
    {
      id: '57a562a0-6847-43bb-ad37-1e49ec07b86a',
      weight: 2,
      value:
        'Leads multi-discipline negotiating teams in complex negotiations consulting and engaging Legal, Finance and other parties as required.'
    },
    {
      id: 'e81bbc5c-2341-4c14-b405-dee5113b4cae',
      weight: 2,
      value:
        'Demonstrates a full understanding of negotiation dynamics and consistently utilizes successful negotiating and influencing behaviors'
    },
    {
      id: 'c45e5eca-bd99-497c-a4fd-b49efa2d70e9',
      weight: 2,
      value: 'Develops negotiating briefs and sets stretching targets for the negotiation'
    },
    {
      id: 'c96b0c91-7737-48a5-9696-6cd031ddb728',
      weight: 2,
      value:
        'Thoroughly researches the key risks, people, market, supplier and timing issues present for each negotiation'
    },
    {
      id: '7817ed42-ba88-4838-a41e-ef2e6833e229',
      weight: 2,
      value: 'Can present confidently and effectively to peers, suppliers and senior stakeholders'
    },
    //  1b
    {
      id: '476dce98-9a18-42df-840e-1f726b000e1a',
      weight: 1,
      value: 'Plans and leads a limited issue local business negotiation with a third party'
    },
    {
      id: '0531d669-22e1-4185-bb8e-f1f9e9db6635',
      weight: 1,
      value: 'Uses a range of persuasion methods and simple tactics to achieve a successful outcome'
    },
    {
      id: '1b312512-6819-4a25-a100-8325f9e0d372',
      weight: 1,
      value: 'Adapts own negotiating approach throughout the process to keep it moving forward'
    },
    {
      id: '5efa4e3f-af0c-4926-812c-e86cf02ba57c',
      weight: 1,
      value:
        'Recognizes the issues and options, makes an assessment of the arguments, and uses to provide information and advice for the negotiating team'
    },
    {
      id: '38d5ce09-e38c-4915-9ce3-cc9c9c3d356e',
      weight: 1,
      value:
        'Plays a supporting role in larger negotiations and can identify negotiating and influencing behaviours and tactics'
    }
  ])
};

export const FUN_P09: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p09',
  title: 'Supplier Performance, Relationship and Contract Management',
  description:
    'Establishing robust contract management systems together with effective supplier performance and relationship management processes, Manages contracts and suppliers to ensure goods, services and projects are delivered in line with agreements (on-time, on-cost, quality etc.). Establishes and moniotrs effective frameworks and structures to manage suppliers across the range of operational, performance and executive responsibilities. Manages supplier relationships and where appropriate measures and develops relationships to bring about continuous improvement. Works more broadly in supply markets to generate and maintain competition. Works internally with stakeholders to ensure agreements are fully utilised and can be accessed effectively by all who need to. Successful supplier management will add value by providing review and feedback, managing risk, assessing contractual compliance by both parties, establishing appropriate and effective key performance indicators, managing any disputes in a timely,, effective manner and identifying continuous improvement opportunities.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '9dcacaf6-ad9c-51ea-8243-3689ea85c6b4',
      weight: 3,
      value:
        'Establishes mechanisms for ensuring supplier relationships and contracts are effectively governed both within and outside of function with clear responsibilities'
    },
    {
      id: 'd273eb9c-e028-5038-b008-91bc51ae2ef9',
      weight: 3,
      value: 'Leads or directs the development and implementation of contract and supplier management systems'
    },
    {
      id: 'd9f7e958-eb6d-55b4-ac84-9b949bfb9821',
      weight: 3,
      value:
        'Consulted from across the business to support the development of strategic or high-risk contracts & relationships'
    },
    {
      id: '58f4cb97-3279-5ad7-bf71-1010553b3df0',
      weight: 3,
      value:
        'Leads the development and implementation of innovative performance and measurement metrics and incentive schemes'
    },
    {
      id: '03e8866b-d3cc-57a4-a290-3b816b79bbfa',
      weight: 3,
      value:
        'Manages the resolution of major contractual disputes requiring the involvement of the Courts or other external bodies (arbitration etc.)'
    },
    // 2b
    {
      id: '2ed730e4-0f3e-57fa-9740-e5c9bc45d1e3',
      weight: 2,
      value: 'Understands business unit strategies/drivers and proposes supplier development programmes'
    },
    {
      id: 'fa9b204e-7704-5c20-b989-8a386346299c',
      weight: 2,
      value: 'Manages the resolution of contractual disputes and uses legal support where required or mediation'
    },
    {
      id: 'd94502aa-c6b7-51b3-a388-90aa3085efe0',
      weight: 2,
      value: 'Provides guidance within team on the level of time, attention and focus applied to supplier relationships'
    },
    {
      id: 'f5141354-eaa5-5537-a9a8-3c5bae6fd3b3',
      weight: 2,
      value: 'Coaches others and contributes to the development of processes, systems and tools within function'
    },
    {
      id: 'b29d73cd-ff42-5dcb-88d6-9473b890af2c',
      weight: 2,
      value:
        'Leads the close out of major contracts ensuring representation and involvement by relevant parties and management of plans'
    },
    // 1b
    {
      id: '09276c52-cf7a-5e56-8365-c73bee1dbfa2',
      weight: 1,
      value: 'Develops supplier relationships for lower value or lower risk goods and services to meet business needs'
    },
    {
      id: '84414f82-ee25-5d16-9669-0d894eae7e44',
      weight: 1,
      value: 'Contributes to resolving operational supplier management issues raised by the business'
    },
    {
      id: 'fdb27bb4-b10d-5a5b-b461-b54de6f0e459',
      weight: 1,
      value:
        'Contributes to the establishment of relationship objectives, with input from users, suppliers and guidance from their team leader/manager'
    },
    {
      id: 'cd952405-7d84-5a49-9d44-2b6bcfc1ff11',
      weight: 1,
      value: 'Resolves minor disputes and performance problems with suppliers'
    },
    {
      id: '4e087635-b882-5055-a29d-21455af0e67c',
      weight: 1,
      value: 'Meets with suppliers to monitor and manage routine performance matters'
    }
  ])
};

export const FUN_P10: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p10',
  title: 'Transactional Procurement',
  description:
    'Able to design, implement and operate transactional procurement processes. Performing all or part of the activities in the purchasing cycle starting after contract signature - receiving purchase requisitions, converting them into purchase orders, ordering, expediting, quality assurance, clearing & shipping, goods, receipt, delivery to customer, invoice matching, clarification of invoice issues with supplier etc. An understanding of the full requisition-to-pay cycle and its impact upon how the business operates.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '9ef1d4f1-1dd5-4a29-bdb2-b30d32260205',
      weight: 3,
      value:
        'Provides expertise on purchase requisition handling for complex, high risk purchases or major procurement programmes'
    },
    {
      id: '654b6dbf-8eff-4da4-94d8-bbd3f6083250',
      weight: 3,
      value:
        'Leads or establishes frameworks for the performance analysis of transactional procurement; establishes metrics and reporting mechanisms'
    },
    {
      id: '6284576a-1569-46a6-aad7-544b2a14838b',
      weight: 3,
      value:
        'Advises on principles of risk management, governance, segregation and cost aspects in the transactional procurement process domain'
    },
    {
      id: 'a1d787ba-ac28-4fc8-8447-4be701df198c',
      weight: 3,
      value: 'Designs and sets transactional procurement processes in conjunction with other key stakeholders'
    },
    {
      id: '6fcd6bc6-75f4-497d-993e-84a5379cf086',
      weight: 3,
      value: 'Trains others in their understanding of processes, procedures and systems'
    },
    //  2b
    {
      id: '56f0b33c-8103-49f3-985e-901cdb3c04c3',
      weight: 2,
      value: 'Understands and can address issues with suppliers to meet customer needs'
    },
    {
      id: '210d5b17-b920-4725-b0e4-40f58ca5dc98',
      weight: 2,
      value:
        'Possesses an effective understanding of the purchasing cycle in order to ensure efficient execution and address issues which impact others'
    },
    {
      id: 'cd1eef82-7748-46d9-b43e-eaaa67653f6c',
      weight: 2,
      value: 'Seeks specialist legal and technical sources for advice'
    },
    {
      id: 'fedec646-b7bc-47a6-8ea3-f71fd83b9f83',
      weight: 2,
      value:
        'Conducts transactional analysis and provides information and recommendations to procurement/category teams to incorporate into plans'
    },
    {
      id: '54269fbe-416e-47c6-9744-9d9ced318742',
      weight: 2,
      value: 'Identifies areas for improvement and drives continuous improvement processes in transactional procurement'
    },
    //  1b
    {
      id: '2292065a-0bdc-44e7-bab6-00458b8659bd',
      weight: 1,
      value: 'Executes purchase requisitions and orders for straightforward purchases'
    },
    {
      id: '12372047-086a-47dd-8ff3-728c9d5e9ac9',
      weight: 1,
      value:
        'Possesses a working knowledge of purchase order execution covering ordering, expediting, quality and risk management, goods receipt, invoice matching and clarification of any issues'
    },
    {
      id: 'bb7ce402-d4bc-45eb-ae9c-3cf378896c16',
      weight: 1,
      value:
        'Able to put in place methods of control and performance measurement under some guidance from team leader & manager'
    },
    {
      id: '3a678066-476f-477b-b010-decb534d2b2e',
      weight: 1,
      value: 'Able to follow transactional procedures competently and efficiently under guidance'
    },
    {
      id: '331dd68e-fd9f-481f-b220-34ef4f68f5ca',
      weight: 1,
      value: 'Able to liaise with Finance, Users, Budget holders and Warehouse/Receipting function to resolve queries'
    }
  ])
};

export const FUN_P11: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p11',
  title: 'E-Procurement and Technology',
  description:
    'Identfiying, understanding, adopting and managing electronic means to undertake and support procurement activity including transactional processes, contract management, tender processes and auctions i.e. electronic catalogues, purchasing cards, EDI, WebEDI, system integration, e-auctions, e-procurement etc. Using electronic means and systems for data analysis, knowledge management and business intelligence to support Procurement planning and execution.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: 'b79b75fa-6f5f-4f60-a83a-3d65ecb3562d',
      weight: 3,
      value:
        'Sets functional measures of performance around the use of e-procurement, technology, knowledge management and benefits tracking; contributes to the development of industry wide model forms of templates and best practices'
    },
    {
      id: '1132f1b6-7944-44c4-aaa2-96166e026d1e',
      weight: 3,
      value:
        'Sets the direction for the appropriate application and use of electronic auctions and tenders within the organization'
    },
    {
      id: 'fe830bb7-d5d3-48a0-84f2-c85c858ca4ac',
      weight: 3,
      value:
        'Possesses an understanding of ERP/e-procurement systems to enable their effective design and implementation to enable efficiencies in the Procure-to-Pay process and to reduce the administrative burden and associated costs'
    },
    {
      id: 'a49bc524-3525-47bf-968e-63897b20f094',
      weight: 3,
      value: 'Advises on methods of control and reporting around technology'
    },
    {
      id: '238a8ed9-d28b-4e86-a7af-ce7f98b951e3',
      weight: 3,
      value:
        'Recognized as providing expert guidance on the implementation, management and improvement of all kinds of e-procurement and ERP systems'
    },
    //  2b
    {
      id: '2889cd9c-b3dd-4bb6-8426-6e5a2390acd5',
      weight: 2,
      value:
        'Implements and manages internal and external electronic catalogues for all kinds of items covering all process steps from requirement to payment'
    },
    {
      id: 'ba672245-7c44-4eb7-9a9c-8d3fc2f00a1d',
      weight: 2,
      value: 'Optimizes application of electronic means for simplification of the transactional purchasing processes'
    },
    {
      id: 'fe616e5d-2cfb-4075-9b33-1e311bd7bba0',
      weight: 2,
      value: 'Leads highly complex deals such as electronic tenders and auctions in i.e. logistics or services'
    },
    {
      id: '9cc7a93e-cb0b-4512-8bd0-9e906af03155',
      weight: 2,
      value: 'Possesses an effective understanding of data warehousing, business intelligence to support negotiation'
    },
    {
      id: 'ca86fe81-8318-4511-870b-e09a9175a2da',
      weight: 2,
      value:
        'Possesses an effective understanding of electronic auctions and tenders; their advantages, options and limitations; ethical, legal and commercial constraints; the importance of handling specifications as well as communication with suppliers and stakeholders'
    },
    //  1b
    {
      id: 'bd8e7e3d-26d0-438a-b6b3-fb532a115de4',
      weight: 1,
      value:
        'Implements and manages web based and internal electronic catalogues for low risk, low-cost items in order to simplify the ordering processes'
    },
    {
      id: '75bb478a-130f-448c-be36-a73e19adad78',
      weight: 1,
      value: 'Able to support users, to communicate and to perform or to organize trainings on usage of such catalogues'
    },
    {
      id: '2a30c622-44c3-4632-b157-8e51ab5bff40',
      weight: 1,
      value:
        'Able to put in place methods of control under some guidance from team leader, able to run standard reports and to derive appropriate actions'
    },
    {
      id: 'ce02204d-8a96-4c8a-9ef7-78e3cb0797c9',
      weight: 1,
      value: 'Able to use and administer knowledge management and contract management systems'
    },
    {
      id: 'c2aabe29-a63b-47bc-b0a1-86dce72a9ea2',
      weight: 1,
      value:
        'Able to extract simple reports to assess compliance against contracts, purchase price variance etc. from business analysis tools'
    }
  ])
};

export const FUN_P12: RoleCompetency<FunctionalRoleCompetencyID> = {
  id: 'p12',
  title: 'Project Management and Change',
  description:
    'Project management is the discipline of planning, organising, securing, and managing resources to achieve specific goals. It has a defined beginning and end (usually timeconstrained, and often constrained by funding or deliverables), undertaken to meet unique goals and objectives, typically to bring about beneficial change or added value. The primary challenge of project management is to achieve all of the project goals and objectives while honouring defined constraints such as scope, time and budget. The secondary challenge is to optimise and integrate the inputs necessary to meet pre-defined objectives.',
  maxSelectedQuotes: 7,
  quotes: arrayShuffle([
    // 3b
    {
      id: '02ce4d5b-f5d2-405e-8e7a-2e9f403fefce',
      weight: 3,
      value: 'Champions the application of advanced and innovative processes, techniques and strategies'
    },
    {
      id: '822276eb-6bc8-4197-8141-9b854360a788',
      weight: 3,
      value:
        'Frequently sought after for definitive advice on project management across the function and organisation at senior levels'
    },
    {
      id: '80bea8f4-2b4d-47c9-a5c7-9e55d1e21d0b',
      weight: 3,
      value: 'Demonstrates an ability to identify, analyse and solve complex problems'
    },
    {
      id: 'a74a3c57-f0e1-4556-8a1f-71f9c67a104a',
      weight: 3,
      value: 'Develops and optimises the entire Project Management approach to align with corporate vision and goals'
    },
    {
      id: 'e26cb7b3-2bb9-41f9-ada1-548dca98eced',
      weight: 3,
      value:
        'Represents the function and company with regards to key organisational, business change and transformation projects'
    },
    //  2b
    {
      id: 'e8651fb5-44cf-4a89-8885-a36e3a25b450',
      weight: 2,
      value: 'Effective knowledge of project management processes and systems such as PRINCE II or equivalent'
    },
    {
      id: '881ba887-92d2-4e82-8c61-dde85d05f9fe',
      weight: 2,
      value: 'Supports the development of key governance processes to provide assurance of procurement processes'
    },
    {
      id: 'd096a076-a8d1-43c9-9c09-1abe08438300',
      weight: 2,
      value: 'Coaches staff within the function, users and suppliers so that maximum value is derived by all processes'
    },
    {
      id: 'f8d6c819-4a03-43b3-9fda-57999217cf35',
      weight: 2,
      value: 'Proactively identifies and recommends improvements to PM processes and standards'
    },
    {
      id: '19531efb-c21e-4c56-a93c-9f048f6103e3',
      weight: 2,
      value: 'Aligns project management approach within procurement to support business needs and targets'
    },
    //  1b
    {
      id: '9f7746ca-8693-4db2-a7ee-928aa033df42',
      weight: 1,
      value: 'Applies processes and standards consistently'
    },
    {
      id: 'e17ca04b-e6b0-4d88-bdc1-5e1ee9a134fc',
      weight: 1,
      value: 'Advises users on policy and process at a transactional level'
    },
    {
      id: '62229b90-5550-4997-a950-72cd27ce3233',
      weight: 1,
      value: 'Project reporting'
    },
    {
      id: 'f3886504-87b0-4af7-835b-d9f79ee05628',
      weight: 1,
      value: 'Deals confidently with queries from users, stakeholders on key issues and resources, etc.'
    },
    {
      id: '4f7d1e78-54dc-4732-818b-8037c59d9991',
      weight: 1,
      value:
        'Contributes to the development of the overall plan and method of approach as part of a multi-disciplinary team'
    }
  ])
};

export const BEH_SELF_MANAGEMENT: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'self-management',
  title: 'Self Management',
  description:
    'Able to work as a self starter, work through job content issues/communication issues themselves, able to work/communicate within an international team and maintain a good level of self motivation, priority setting, continuously learn and manage within a forcefield of local interest and functional responsibility. A disciplined and enthusiastic approach to achieving consistent high standards of work. Initiates actions to influence events, using networks where appropriate, will persevere to overcome barriers to achieve goals, putting in extra effort when necessary to pursue issues to conclusion.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: 'fc63f1d6-ba65-47c3-830f-e20a482977dd',
      weight: 3,
      value: 'Innovative approaches to gain medium to long term (3-5 years +) business advantage'
    },
    {
      id: 'bc2c78b9-0111-4d3a-8679-d10c03071247',
      weight: 3,
      value:
        'Will generate or select leading edge ideas, frequently developing a very complex strategy to meet cross company/country needs with a firm link to industry/economic factors and trends'
    },
    {
      id: '9c8147df-036b-4558-838f-01f00a9ed43c',
      weight: 3,
      value: 'Sustains a high degree of effectiveness in varying environments with different responsibilities'
    },
    //  2b
    {
      id: 'f7588ed6-396e-4d27-837e-16c29507dc23',
      weight: 2,
      value: 'Ensures delivery, through the leadership and direction of others'
    },
    {
      id: 'f91ef34e-ffe1-4530-9b2f-fddf318dc216',
      weight: 2,
      value:
        'Understands and interprets the overall business direction/objectives and develops plans for a defined area to enable their achievement'
    },
    {
      id: 'f79e85ed-c112-4754-8aef-510ec2ddee4e',
      weight: 2,
      value:
        'Delivers solutions to complex issues, will generally lead large multi-functional/cross location teams and will work to appropriate quality standards ensuring team members are motivated to meet the targets'
    },
    //  1b
    {
      id: '2728eb9b-ec6a-4b38-9c28-22ae37300a5d',
      weight: 1,
      value:
        'Able to set and work to self-imposed deadlines under external pressure. Maintains focus and clarity of objective to get things finished'
    },
    {
      id: 'c65c854d-3cf7-4abb-b62a-b339e461d91a',
      weight: 1,
      value: 'Takes responsibility for own actions to achieve agreed objectives by working with others'
    },
    {
      id: '869898c6-c2c3-4221-8794-f746f0cec08d',
      weight: 1,
      value: 'Works to agreed time periods, objectives and cost parameters established by others'
    }
  ])
};

export const BEH_DELIVERING_RESULTS: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'delivering-results',
  title: 'Delivering Results',
  description:
    'Able to run a project, properly organise and manage stakeholders and motivate the team they are a part of to drive the result. Is able to bring a compelling case and vision, continuously reflecting on goals within Procurement and evaluates the environment internally/externally taking corrective steps to reach the target. Ensures individuals understand and agree their targets and objectives without abdicating own responsibilities. Establishes and takes account of individuals’ strengths and other relevant factors when delegating and when reviewing performance. Ensures standards have been agreed and resources are available, checks progress and takes remedial action when necessary.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: '8b81f3c4-64c2-46bf-b70d-f9e4397b659d',
      weight: 3,
      value:
        'Influences and participates in setting strategic direction and vision for long-term development of the business, taking into consideration business and regional requirements, customer and supplier expectations'
    },
    {
      id: '652cabe8-5071-4c1f-9126-e66bf31ef21e',
      weight: 3,
      value:
        'Identifies and seizes new business opportunities, setting and evaluating key parameters typically with peers in executive teams across the business and with third parties'
    },
    {
      id: '0fd31164-b919-4e57-b369-0b1a2bed59d8',
      weight: 3,
      value:
        'Identifies what needs to be done in order to deliver strategic objectives within and across major business areas, and processes'
    },
    //  2b
    {
      id: 'b2bacfdb-01c8-40f3-8daa-d17987c50ee8',
      weight: 2,
      value:
        'Will establish with appropriate team members the direction and targets for own area of the business (e.g. major divisional heads), monitoring and managing performance aligned to the overall business objectives'
    },
    {
      id: 'a5ee5416-d392-4f3d-9f30-90253c0ab83d',
      weight: 2,
      value:
        'May influence or participate in setting strategic direction and vision for long-term development of the business, taking into consideration business requirements, customer and supplier expectations'
    },
    {
      id: '6fb1813d-cfd4-4989-84e4-bbed80023cdc',
      weight: 2,
      value:
        'Interprets the strategy and establishes in-country or cross-category procurement objectives, recognising and implementing new opportunities to build the business or improve profitability. Establishes key parameters and priorities and monitors progress on an on-going basis'
    },
    //  1b
    {
      id: 'd69d6fa7-da5c-48ee-b10e-2fb9349f7b6a',
      weight: 1,
      value:
        'Typically works locally within their business unit but may be involved in projects across function and in other countries'
    },
    {
      id: '5e872678-cac9-4908-b499-b84a8bd7ea22',
      weight: 1,
      value: 'Monitors own performance against targets established by others in order to improve local effectiveness'
    },
    {
      id: '2f30883f-4540-42fb-8dee-dfc16e0c8908',
      weight: 1,
      value: 'Supports a key task or work area under guidance and works to an agreed plan to deliver targets'
    }
  ])
};

export const BEH_BUILDING_RELATIONSHIPS: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'building-relationships',
  title: 'Building Relationships',
  description:
    'Builds meaningful and constructive relationships through a disciplined and structured approach to stakeholder management within function, business unit and wider business through mutual respect and trust, taking all stakeholder needs into account to ensure a positive delivery. Builds effective supplier relationships appropriate to the nature of the category. Awareness of the importance of working in cross-functional teams as an aid for decision-making. Awareness of how the function can raise its own profile through a strategy of internal procurement marketing, and improved and effective communications. Represents the business to external/project clients, explaining technical and commercial proposals and solutions simply and clearly.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: '0adc15f0-b019-59e0-bcee-3cf78594807b',
      weight: 3,
      value: 'Interacts effectively with heads of functions and other senior stakeholders'
    },
    {
      id: '5b51dd76-de72-52be-b9d5-41a3dd0c402f',
      weight: 3,
      value: 'Able to say “No” to customers, but still strengthen relationships'
    },
    {
      id: '0575c706-5fd1-5c9f-905c-fe46070dc2e8',
      weight: 3,
      value: 'Works to the highest levels of integrity – always balancing the needs of customers and suppliers'
    },
    // 2b
    {
      id: '1ccad238-17c1-5912-acaa-6fbc6f92dc9c',
      weight: 2,
      value:
        'Passionate about the need to maintain effective and professional working relationships that deliver value to the business'
    },
    {
      id: '0e838203-7b0e-5881-bc3f-1ad4fec4685a',
      weight: 2,
      value: 'Takes ownership of problems; turns them into opportunities to strengthen relationships'
    },
    {
      id: '2346cbb4-f541-5317-a580-c3ffaa7a8a2d',
      weight: 2,
      value:
        'Knows and understands the customer’s business and objectives to build and promote long-term relationships of benefit to all'
    },
    // 1b
    {
      id: '3b80588a-bd99-5d07-bc07-3358ed144482',
      weight: 1,
      value:
        'Gets close to customers: credible on an interpersonal as well as technical level; recognised by customers as adding value'
    },
    {
      id: '30d3413a-9b95-52b7-9383-4a5ad6a1f08c',
      weight: 1,
      value:
        'Able to deal effectively with customers to safeguard project schedules and the company’s technical/commercial interests'
    },
    {
      id: '60560b10-9e58-5b96-ae79-5e8acf81d4bf',
      weight: 1,
      value:
        'Able to relate project benefits to clients’ commercial advantage, to explain technical ideas clearly and simply to customers'
    }
  ])
};

export const BEH_PERSONAL_IMPACT_AND_PRESENTATION: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'personal-impact-and-presentation',
  title: 'Personal Impact and Presentation',
  description:
    'The ability to achieve a vision through persuading and influencing stakeholders, remaining constructive in difficult situations, handling conflict in a professional constructive way. Able to bring a result to the promised deliverables. Able to prepare and adapt your approach to the audience. Successfully builds support for ideas without the use of hierarchical power. Is professional, courteous and assertive when influencing.  Checks to ensure arguments are understood and modifies argument if appropriate.  Arguments are supported by evidence and are presented withdue account taken of the audience. Likely objections are anticipated and appropriate responses prepared for overcoming them.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: 'e300e5f6-bb43-49d2-8ba5-fd757df657c1',
      weight: 3,
      value:
        'Uses rationale and persuasion in a way that encourages movement towards the business vision and achievement of business objectives to influence internal and external decision makers'
    },
    {
      id: '5df8eaef-8ea0-4a51-b985-e2ffedbb8fce',
      weight: 3,
      value:
        'Proactively positions the Company to external parties, thereby enhancing the Company’s profile and its ability to influence the business and industry direction. Will be prepared to be influenced'
    },
    {
      id: 'dfa4fc95-db48-43ca-90a2-b5e69addfe47',
      weight: 3,
      value:
        'Makes use of different approaches and networks across functional, process, site and company boundaries to influence decisions which have strategic significance to the business'
    },
    //  2b
    {
      id: 'c311fcb1-3bf4-4bb2-8747-be84d7716c78',
      weight: 2,
      value:
        'Presents arguments based on sound business and technical reasoning and commercial awareness, able to anticipate alternative positions and prepare accordingly'
    },
    {
      id: '440bcab7-b364-4994-82f9-f7074883817b',
      weight: 2,
      value: 'Able to adapt own style to reflect the needs of the situation, audience and decision-makers'
    },
    {
      id: 'a5f4d73a-5f5d-475e-8f87-68e40cf4cd4a',
      weight: 2,
      value:
        'Is able to present confidently to a senior audience and within function to positively influence and create impact'
    },
    //  1b
    {
      id: 'ee9d11c1-e4cf-4ed8-876a-5e3573d41a78',
      weight: 1,
      value:
        'Understands options, being prepared to change in light of new technical or business information; positions arguments based on detailed analysis of information and presents effectively to audience'
    },
    {
      id: 'db232bab-3e39-4c26-b56b-36307b6686a1',
      weight: 1,
      value:
        'From a basis of sound specialist and technical knowledge influences others in areas relating to own work and is seen and accepted as presenting valid arguments in respect of recommendations for improvement'
    },
    {
      id: '5b3b1b33-f8d5-45e8-9f37-f52388ba4c0b',
      weight: 1,
      value: 'Creatively challenges ideas relating to own work areas based on reasoned argument'
    }
  ])
};

export const BEH_ENTREPRENEURIAL_INITTATIVE: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'entrepreneurial-initiative',
  title: 'Entrepreneurial Initiative',
  description:
    'Being creative within the agreed boundaries, sees opportunities, possibilities to improve. A team spirit that allows and supports new ideas and thoughts for improvement. Able to understand and recognise changes within the supply market and able to act upon them for the company’s benefit. Seeks ideas for improving existing systems and processes while supporting others in identifying and implementing improvements. Provides constructive challenges to established ways and applies best practice to achieve improvements. Uses benchmarks and standards to monitor and evaluate the effectiveness of initiatives.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: 'b6f8d5c5-8086-5969-91b2-8f1c6fad1e2b',
      weight: 3,
      value:
        'Creates the overall business environment where entrepreneurial initiative and continuous improvement is constantly seen as a priority and systems and processes are put in place to measure improvements against internal and external benchmarks'
    },
    {
      id: '1d27bf23-0422-5112-9c28-362d99b44a07',
      weight: 3,
      value: 'Scope of activity is likely to include the overall business, or major areas of the business'
    },
    {
      id: '30a819f9-5a99-50ea-b6ab-2bfbc8ec4cf7',
      weight: 3,
      value:
        'Creates an environment within own area, which encourages others to be adventurous and ambitious when looking at improvements'
    },
    // 2b
    {
      id: 'bdb89017-9d4e-5eb1-b949-1495f1927a08',
      weight: 2,
      value:
        'Removes blocks to improvement initiatives in own area of the business and recognises and rewards improvement'
    },
    {
      id: '8b3ffcc8-6f0b-572c-ba3c-274b7ceab1aa',
      weight: 2,
      value:
        'Establishes and supports Business Improvement Teams for categories or projects within own business area and within function'
    },
    {
      id: 'e51e5997-e3b5-5281-a099-42df35951c66',
      weight: 2,
      value:
        'Sets targets and performance standards for improvement. Negotiates contracts that contain continuous improvement clauses. Can demonstrate measurable improvements in business performance'
    },
    // 1b
    {
      id: '7a5a28c1-7fe9-5d40-b4a2-ba056555ad28',
      weight: 1,
      value:
        'Delivers improvement initiatives in own work area, encouraging and supporting peers to adapt to new working methods'
    },
    {
      id: '77691ec5-c6b6-5e8c-b5b6-50dc8b72b342',
      weight: 1,
      value:
        'Seeks opportunities and recognises need for improvements in own work area. Draws the need for improvements to the attention of the appropriate person'
    },
    {
      id: 'ecbcce14-a547-5a60-9445-fabc137ec478',
      weight: 1,
      value:
        'Works to achieve specified improvement targets and measures progress. Participates in improvement initiatives, communicating ideas and results. Using benchmarking and best practice to compare processes'
    }
  ])
};

export const BEH_LEADERSHIP: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'leadership',
  title: 'Leadership',
  description:
    'The ability to establish and communicate a Procurement vision, to manage an international team by providing direction, feedback, accountability, with an awareness of cultural difference, managing and demonstrating by example, create an environment where people are able to grow through taking ownership, learning from mistakes, celebrating success and focusing the team to work towords this vision. Even when under pressure in and/or outside. Establishes a clear purpose and direction for others to channel their energies  towards a common purpose. Enlists and energises others to pursue excellence, make significant contributions and grow in the business.  Inspires commitment to achieving business goals. Is available to listen and take account of what others say, shares information and provides constructive feedback. Leads by example, demonstrating commitment to the organisation’s goals, whilst recognising the achievements of others and encouraging individuals to realise their potential.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: '09258a11-9042-40be-b274-799927aafef9',
      weight: 3,
      value:
        'Establishes cultural direction and values and demonstrably operates within them normally as part of a senior team by being a role model'
    },
    {
      id: '106c6326-1b6a-4222-b321-bbf877c21077',
      weight: 3,
      value:
        'Will inspire and motivate others to buy into the business vision on how people are led, managed and participate in the business'
    },
    {
      id: 'ec4ed158-d430-4006-8319-3f46bf66fcab',
      weight: 3,
      value:
        'Assesses the change requirements of the business and puts in place programme to effect long term shifts in the organisational culture and ways of operating.'
    },
    //  2b
    {
      id: '70ec9c43-11f7-4ef7-a707-b7be70b52020',
      weight: 2,
      value:
        'Establishes and communicates a vision of what the group can accomplish. Selects group members based on their abilities and experience to do the work'
    },
    {
      id: '1f787220-ded3-41a6-be09-ff4b1e8016e7',
      weight: 2,
      value:
        'Will be a role model for values and behaviours in both day to day activities and in the way in which teams and projects are managed and led'
    },
    {
      id: '4271fed8-28f8-4df8-9ea0-c3dc796fd0d5',
      weight: 2,
      value:
        'Shows sincere appreciation for individual and group efforts; rewards accomplishments and exemplary behaviour'
    },
    //  1b
    {
      id: '92fcd4c0-9cd2-4117-b9a1-111c7b967c32',
      weight: 1,
      value:
        'Adopts a supporting role within the team, prepared to accept responsibility for own work and that of others acting positively to anticipate and manage events. Works in partnership with members to create shared objectives and standards of performance'
    },
    {
      id: '800ad2f6-5201-4c38-973e-a0c09ecb0367',
      weight: 1,
      value: 'Understands cultural direction and values and operates within them'
    },
    {
      id: 'e9ae7b57-1e59-4c63-888d-769aba820e6f',
      weight: 1,
      value: 'Inspires and motivates others in their area of influence to buy into the vision, strategy and objectives.'
    }
  ])
};

export const BEH_DEVELOPING_OTHERS: RoleCompetency<BehavioralRoleCompetencyID> = {
  id: 'developing-others',
  title: 'Developing others',
  description:
    'Out of function, manages and develops a cross-functional international team consisting of skills and talent both in and outside procurement to strenghten relationships and procurement outcomes operating at the appropiate skill/responsibility/execution level. Taking ownership of the development of the group, using constructive feedback, targetsetting/accountabilities. Promotes the learning and development of self and others through reviews of positive and negative experience and through feedback. Facilitates the development of others through identified needs, clear objectives and planned activities.Develops others’ talents and capacity for independent responsibility. Provides on-going, constructive feedback and developmental opportunities to others. Engages others in meaningful work.',
  maxSelectedQuotes: 5,
  quotes: arrayShuffle([
    // 3b
    {
      id: '5f0bc36e-149a-4961-9e56-b1e22eea145f',
      weight: 3,
      value:
        'Creates and sustains environment for learning, recognises overall organisational development needs and priorities and initiates appropriate action'
    },
    {
      id: '5524c0b1-665f-4104-9075-30fae2367214',
      weight: 3,
      value: 'Mentors individuals outside of own business area'
    },
    {
      id: '6a60cea4-be75-4d04-a09a-1d4216b6c777',
      weight: 3,
      value: 'Empowers colleagues within and outside of function and commits to their success'
    },
    //  2b
    {
      id: '2595ea5c-5a53-43f8-8d3a-5b5cf6941633',
      weight: 2,
      value:
        'Provides honest, candid feedback to others about skills and abilities within own area and immediate interfaces'
    },
    {
      id: '8993470c-199e-4ca7-93aa-371ae77d9da4',
      weight: 2,
      value: 'Seeks out and listens to honest, candid feedback from others; is open and accessible'
    },
    {
      id: 'd66a21ad-d554-4b0e-b634-a7d891518aeb',
      weight: 2,
      value:
        'Recognising opportunities for colleagues/peers and actively seeks to engage them to increase their breadth and depth of knowledge and experience and takes actions necessary to facilitate this happening'
    },
    //  1b
    {
      id: 'f25cbfaa-93ff-4674-b937-b96d35721310',
      weight: 1,
      value: 'Relates learning outcomes to the team objectives; supports the development activities of colleagues'
    },
    {
      id: '248de0a1-e27c-464e-9209-b3ad7f6015f1',
      weight: 1,
      value: 'Uses systems and processes designed to support development and improve performance for self and others'
    },
    {
      id: '2d0e8e67-7fc9-48be-aea6-3ffcf22bf44d',
      weight: 1,
      value: 'Takes future functional and organisational needs into account in planning own development'
    }
  ])
};

export const FUNCTIONAL_COMPETENCIES_LIST = [
  FUN_P01,
  FUN_P02,
  FUN_P03,
  FUN_P04,
  FUN_P05,
  FUN_P06,
  FUN_P07,
  FUN_P08,
  FUN_P09,
  FUN_P10,
  FUN_P11,
  FUN_P12
];

export const BEHAVIORAL_COMPETENCIES_LIST = [
  BEH_SELF_MANAGEMENT,
  BEH_DELIVERING_RESULTS,
  BEH_BUILDING_RELATIONSHIPS,
  BEH_PERSONAL_IMPACT_AND_PRESENTATION,
  BEH_ENTREPRENEURIAL_INITTATIVE,
  BEH_LEADERSHIP,
  BEH_DEVELOPING_OTHERS
];
