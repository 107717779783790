import React from 'react';
import './SectionHeader.scss';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<{}> {
  annotationTitle?: string;
  title: string;
  subtitle?: string;
  backButtonTitle?: string;
  backButtonURL?: string;
}

export const SectionHeader: React.FunctionComponent<Props> = (props) => (
  <div className="section-header">
    {props.backButtonTitle && props.backButtonURL && (
      <div className="section__backButton">
        <Link className="section__backLink" to={props.backButtonURL!}>
          <img
            className="section__backArrow"
            src={require('./assets/arrowLeft.png').default}
            style={{ width: 7.4, height: 12 }}
            alt="RBI"
          />
          {props.backButtonTitle!}
        </Link>
      </div>
    )}

    {annotationTitleDiv(props.annotationTitle)}
    <div className="section__title">{props.title}</div>
    {props.subtitle && <p className="section__subtitle">{props.subtitle}</p>}
  </div>
);

const annotationTitleDiv = (annotationTitle?: string) => {
  return annotationTitle ? <div className="section__annotationTitle">{annotationTitle}</div> : <></>;
};
