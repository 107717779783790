import React from 'react';
import './FormControl.scss';

interface Props {
  label?: React.ReactElement;
  input?: React.ReactElement;
}

function useId() {
  const [id] = React.useState(Math.random().toString());
  return id;
}

export const FormControl: React.FunctionComponent<Props> = (props) => {
  const id = useId();
  return (
    <div className="form-control">
      {props.label && <div className="mb-2">{React.cloneElement(props.label, { htmlFor: id })}</div>}
      {props.input && <div className="block">{React.cloneElement(props.input, { id: id })}</div>}
    </div>
  );
};
