import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { InAppHeader } from '../../components/InAppHeader';
import { Card } from '../../components/Card';
import { FullPageBackgroundLayout } from '../../layouts/FullPageBackgroundLayout';
import { getWelcomeMenuList } from '../../const/welcomeRows';
import './WelcomePage.scss';
import { ManagerStore } from '../../stores/ManagerStore';
import { inject, observer } from 'mobx-react';
import { VideoOverlay } from '../../components/VideoOverlay';

interface Props extends RouteComponentProps<{}> {
  managerStore?: ManagerStore;
}

@inject('managerStore')
@observer
export class WelcomePage extends React.Component<Props> {
  componentDidMount() {
    this.props.managerStore!.fetchResults();
  }

  navigateFromElement = (props: Props, url: string, external: Boolean) => () => {
    if (external) {
      window.open(url, '_blank');
    } else {
      props.history.push(url);
    }
  };

  welcomeMenuList() {
    const isManager = this.props.managerStore!.isManager.get();
    const WELCOME_MENU_LIST = getWelcomeMenuList(isManager);
    return WELCOME_MENU_LIST;
  }

  render() {
    return (
      <FullPageBackgroundLayout
        backgroundImage={require('../../assets-shared/desk-1140699-blurred-1600x960.jpg').default}
        className="welcome-page"
      >
        <VideoOverlay />

        <div className="layout-test__header">
          <InAppHeader progressBarType={'none'} onButtonTapped={() => {}} />
        </div>

        <Card className="welcome-card">
          <div className="welcome">
            <div className="welcome__title">Welcome in RBI Procurement Staff Development Guide 2.0</div>
            <div className="welcome__desc">
              Welcome to a guide to foster your professional growth. Your personalized training package will help you to
              build and bring the most out of your Procurement career in RBI.
            </div>
            <div className="welcome__divider" />
            {this.welcomeMenuList().map((row, index, arr) => (
              <div
                className="welcome__item"
                key={index}
                onClick={this.navigateFromElement(this.props, row.redirect, row.external || false)}
              >
                <div className="welcome__inline">
                  <div className={'welcome__menuRow'}>
                    <div className="welcome__menuRowTitle">{row.title}</div>
                    <p className="welcome__menuRowDesc">{row.desc || ''}</p>
                  </div>
                  <img
                    className="welcome__disclosure"
                    src={require('./assets/disclosure.png').default}
                    style={{ width: 7.4, height: 12 }}
                    alt="RBI"
                  />
                </div>
                <div className="welcome__menuRowDivider" />
              </div>
            ))}
          </div>
        </Card>
      </FullPageBackgroundLayout>
    );
  }
}
