import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { AppStore } from '../../stores/AppStore';

interface Props {
  appStore?: AppStore;
}

class AppGatewayRenderer extends React.Component<Props> {
  componentDidMount() {
    this.props.appStore && this.props.appStore.checkVersion();
  }

  render() {
    return <React.Fragment>{this.props.appStore!.ready ? this.props.children : null}</React.Fragment>;
  }
}

export const AppGateway = inject('appStore')(observer(AppGatewayRenderer));
